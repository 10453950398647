import React from 'react'
import Placeholder from '../components/home/placeholder';
import SideNav from '../components/home/sideNav';
import Footer from '../components/home/footer';
import TransmissionList from '../components/transmissionType/transmissionList';

export const TransmissionPage = () => (
    <div class="wrapper">
        <Placeholder />
        <SideNav />
        <TransmissionList />
        <Footer />
    </div>
);