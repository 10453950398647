import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import CrudService from '../../util/crudService'
import { ToastContainer, toast } from 'react-toastify';

const FilterDiv = (props) => {
    const [refNo_, setRefNo_] = useState('');
    const [avail_, setAvail_] = useState('');
    const [vin_, setVin_] = useState('');
    const [title_, setTitle_] = useState('');
    const [keyword_, setKeyword_] = useState('');
    const [make_, setMake_] = useState('');
    const [category_, setCategory_] = useState('');
    const [status_, setStatus_] = useState('');

    const [makeList, setMakeList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);

    let navigate = props.navigation;
    let locationHook = props.locationHook;

    const handleFilter = () => {
        console.log('refNo_ :' + refNo_)
        let data = { keyword: keyword_, refNo: refNo_, availability: avail_, vin: vin_, title: title_, make: make_, category: category_, status: status_ }
        props.searchQuery(data);
    }

    const handleExcel = async (e) => {

        try {
            const response = await CrudService.GetFileData(navigate, locationHook, 'report/stock', 
            {
                //refNo: refNo_,
                avail: avail_,
                //vin: vin_,
                //title: title_,
                keyword: keyword_,
                make: make_,
                category: category_,
                status: status_,
                export_: true
            });

            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'stock_report.xlsx');
            document.body.appendChild(link);
            link.click();

        } catch (error) {
            console.error('Error downloading the Excel file:', error);
        }
    }

    useEffect(() => {
        //Makes List
        CrudService.GetData(navigate, locationHook, 'make')
        .then((res) => {
            setMakeList(res.data);
        })

        //Category List
        CrudService.GetData(navigate, locationHook, 'category')
        .then((res) => {
            setCategoryList(res.data);
        })
    }, [])


    return (
        <section class="content">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className='col-md-2'>
                            <div className="form-group">
                                <select class="form-control form-control-sm" id="modelCode" value={make_} onChange={(e) => { setMake_(e.target.value);}}>
                                    <option value=''>-- Make --</option>
                                    { makeList?.map((item, key) => {
                                        return ( <option key={key} value={item.id}>{item.name}</option> )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <select class="form-control form-control-sm" id="modelCode" value={category_} onChange={(e) => { setCategory_(e.target.value);}}>
                                    <option value=''>-- Category --</option>
                                    { categoryList?.map((item, key) => {
                                        return ( <option key={key} value={item.id}>{item.name}</option> )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <select class="form-control form-control-sm" id="modelCode" value={status_} onChange={(e) => { setStatus_(e.target.value);}}>
                                    <option value=''>-- Status --</option>
                                    <option value='1'>Published</option>
                                    <option value='2'>Un-published</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <input type="text" className="form-control form-control-sm" id="keyword" placeholder="Ref No / VIN / Title" value={keyword_} onChange={(e) => setKeyword_(e.target.value)}/>
                            </div>
                        </div>
                        {/* <div className='col-md-2'>
                            <div className="form-group">
                                <input type="text" className="form-control form-control-sm" id="refNo" placeholder="Ref No" value={refNo_} onChange={(e) => setRefNo_(e.target.value)}/>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <input type="text" className="form-control form-control-sm" id="vin" placeholder="VIN" value={vin_} onChange={(e) => setVin_(e.target.value)}/>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <input type="text" className="form-control form-control-sm" id="title" placeholder="Title" value={title_} onChange={(e) => setTitle_(e.target.value)}/>
                            </div>
                        </div> */}
                        <div className='col-md-2'>
                            <div className="form-group">
                                <button className='btn btn-info btn-sm btn-block' onClick={handleFilter}>Filter</button>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <button className='btn btn-info btn-sm btn-block' onClick={handleExcel}>Export</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )      
}

function StockReport() {
    const navigate = useNavigate();
    const location = useLocation();
    const [listingList, setListingList] = useState([]);
    const [refresh, setRefresh] = useState(false);

    const [type_, setType_] = useState('');
    const [month_, setMonth_] = useState('');
    const [year_, setYear_] = useState('');
    const [quarter_, setQuarter_] = useState('');

    const [currPage, setCurrPage] = useState(1);
    const [page_, setPage_] = useState(1);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);

    const [showFilter, setShowFilter] = useState(false)
    const handleFilter = () => setShowFilter(!showFilter)

    const [searchQuery, setSearchQuery] = useState([]);

    const goToTop = () => {
        window.scrollTo({
            top: 0, //700
            behavior: 'smooth',
        });
    };

    //Next Page
    const nextPage = () => {
        if (hasNext)
            setPage_(currPage + parseInt('1'));
    }

    //Previous Page
    const prevPage = () => {
        if (hasPrev)
            setPage_(currPage - parseInt('1'));
    }

    const gotoPage = (page_) => {
        if (hasNext)
            setPage_(parseInt(page_));
    }

    const handleSearch = () => {

        setListingList([]); // Clear previous filtered data

        CrudService.GetDataWithParam(navigate, location, 'report/stock', 
        { 
            page: page_, 
            //ref_no: searchQuery.refNo,
            //vin: searchQuery.vin,
            //title: searchQuery.title,
            keyword: searchQuery.keyword,
            make: searchQuery.make,
            category: searchQuery.category,
            status: searchQuery.status
        })
        .then((res) => {
            console.log(res.data);
            setListingList(res.data.data);
            setCurrPage(parseInt(res.data.curr_page));
            setHasNext(res.data.hasNext);
            setHasPrev(res.data.hasPrev);
        })
    }
    
    useEffect(() => {
        //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/listing`)
        CrudService.GetDataWithParam(navigate, location, 'report/stock', { availability: 1 })
        .then((res) => {
            console.log(res.data);
            setListingList(res.data.data);
            setCurrPage(parseInt(res.data.curr_page));
            setHasNext(res.data.hasNext);
            setHasPrev(res.data.hasPrev);
        })
    }, []);

    const handleEdit = (e, id) => {
        
        e.preventDefault();
        console.log('ID : '+ id);
        // <Link to={`${process.env.PUBLIC_URL}/addList/${id}`}>T</Link>
        navigate(`${process.env.PUBLIC_URL}/add-list/${id}`);

    }

    const handleView = (e, id) => {  
        e.preventDefault();
        console.log('ID : '+ id);
        navigate(`${process.env.PUBLIC_URL}/view-list/${id}`);

    }

    const handleDelete = (e, id) => {
        
        e.preventDefault();

        if (window.confirm('Are you sure want to DELETE ?')){
            CrudService.DeleteData(navigate, location, 'listing', id)
            .then((res) => {
                //window.alert(res.data);
                toast.success("Stock Delete " + res.data);
                console.log('Deleted :' + res);
                handleSearch();
                //setRefresh(!refresh);
            })
            .catch((error) => {
                console.error(error);
            })
        }

    }

    useEffect(() => {
        handleSearch();
    }, [page_]);

    useEffect(() => {
        handleSearch();
        setPage_(parseInt(1));
    }, [searchQuery]);

    const handleExcel = async (e) => {

        try {
            let response;
            if (type_ == 1){
                 response = await CrudService.GetFileData(navigate, location, 'report/profit/month', 
                {
                    type: type_,
                    year: year_,
                    quarter: quarter_,
                    month: month_,
                    export_: true
                });

            }else if (type_ == 2){
                 response = await CrudService.GetFileData(navigate, location, 'report/profit/quarter', 
                {
                    type: type_,
                    year: year_,
                    quarter: quarter_,
                    month: month_,
                    export_: true
                });

            }else if(type_ == 3) {
                 response = await CrudService.GetFileData(navigate, location, 'report/profit/year', 
                {
                    type: type_,
                    year: year_,
                    quarter: quarter_,
                    month: month_,
                    export_: true
                });

            }


            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'profit_report.xlsx');
            document.body.appendChild(link);
            link.click();

        } catch (error) {
            console.error('Error downloading the Excel file:', error);
        }
    }

    /*const [pageSize, setPageSize] = useState(10);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = useCallback(async ({ pageIndex }) => {
        setLoading(true);
        try {
            const response = await axios.get(
                `https://api.example.com/data?page=${pageIndex}&pageSize=${pageSize}`
            );
            setData(response.data);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }, [pageSize]);

    const columns = useMemo(
        () => [{ Header: 'Name', accessor: 'name', }, { Header: 'Email', accessor: 'email', }, { Header: 'Phone', accessor: 'phone', },],
        []
    );*/

    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0"><NavLink to="/" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; Stock Profit Report</h1>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <NavLink exact to="/" activeClassName="selected" >Dashboard</NavLink></li>
                                <li className="breadcrumb-item active">Stock Report</li>
                            </ol>
                        </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>{/* /.container-fluid */}
            </div>

            <FilterDiv navigate={navigate} locationHook={location} searchQuery={setSearchQuery} />

            <section class="content">
                <div className="card">
                    <div className="card-body table-responsive p-0">
                        <table className="table table-hover text-nowrap table-bordered">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Ref #</th>
                                    <th>Title</th>
                                    <th>VIN</th>
                                    <th>Price</th>
                                    <th>Purchase Price</th>
                                    <th>Total Cost</th>
                                    <th>Est. Profit</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                { listingList?.map((value) => {
                                    return(
                                    <tr>
                                        <td width={'5%'}>
                                            <div class="btn-group">
                                                {/* <button className='btn btn-warning btn-xs' onClick={() => {handleEdit(value.id)}}><i class="far fa-edit"></i></button>  */}
                                                <button className='btn btn-outline-primary btn-xs' onClick={(e) => { handleView(e, value.id) }}>View</button>
                                                <div class="btn-group">
                                                    <button type="button" class="btn btn-info btn-xs dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
                                                    <div class="dropdown-menu">
                                                        <a class="dropdown-item" href="" onClick={(e) => {handleEdit(e, value.id)}}>Edit</a>
                                                        <a class="dropdown-item" href="" onClick={(e) => handleDelete(e, value.id)}>Delete</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{value.ref_no}</td>
                                        <td>{value.title}</td>
                                        <td>{value.vin}</td>
                                        <td align='right'>{value.price}</td>
                                        <td align='right'>{value.purchase_price}</td>
                                        <td align='right'>{value.total_cost}</td>
                                        <td align='right'>{value.profit}</td>
                                        <td align='center'>{(value.profit > 0) ? <span class="badge bg-success">Profit</span>  : <span class="badge bg-danger">Loss</span>}</td>
                                        
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/* /.card-body */}
                    <div className='card-footer clearfix'>
                        <ul class="pagination pagination-sm">
                            { (hasPrev == 'true') &&
                                <li class="page-item"><a class="page-link" href="javascript:void(0);" tabindex="-1" onClick={ () => prevPage()}>«</a></li>
                            }
                            <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(1)}>1</a></li>
                            <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(2)}>2</a></li>
                            <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(3)}>3</a></li>
                            { (hasNext == 'true') &&
                                <li class="page-item"><a class="page-link" href="javascript:void(0);" onClick={ () => nextPage()}>»</a></li>
                            }
                        </ul>
                    </div>
                </div>
            </section>
            <ToastContainer 
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" 
            />
        </div>
    )
}

export default StockReport