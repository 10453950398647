import React from 'react'
import Placeholder from '../components/home/placeholder';
import SideNav from '../components/home/sideNav';
import Footer from '../components/home/footer';
import SalesOrderEdit from '../components/salesOrder/salesOrderEdit';
//import SalesOrderAmend from '../components/salesOrder/salesOrderAmend';

export const EditSalesOrderPage = () => (
    <div class="wrapper">
        <Placeholder />
        <SideNav />
        <SalesOrderEdit />
        <Footer />
    </div>
);