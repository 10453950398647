import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import UploadImage from './uploadImage2';
import CrudService from '../../util/crudService'
import { ToastContainer, toast } from 'react-toastify';
import { DocumentModal } from './documentModal';
import Document from './document';
import { Cost } from './cost';

import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
//import useCustomHeaders from '../../hooks/useCustomHeaders';
//import axiosInstance from '../../util/axiosInstance';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useTranslation } from 'react-i18next';

import DatePicker from "react-datepicker";

const options = [
    { value: 'one', label: 'Option One' },
    { value: 'two', label: 'Option Two' },
];

function DualBox({featureList, active, setFeaturesArr}) {
    //const [selected, setSelected] = useState([]);

    //setSelected(active);

    let options_ = [];
    featureList.map((item, index) => {
        options_.push({
            value: item.id,
            label: item.name
        })
    })

    const handleChanges = (value) => {
        if (value != null)
            setFeaturesArr(value)
    }


    return (
        <DualListBox
            canFilter
            options={options_}
            selected={active}
            onChange={(value) => handleChanges(value)}  //handleFeatureCheck //setSelected(value)
        />
    );
}

function AddListing() {
    const locationHook = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [id, setId] = useState(0);
    const [title, setTitle] = useState('');
    const [titleJp, setTitleJp] = useState('');
    const [slug, setSlug] = useState('');
    const [location, setLocation] = useState(null);
    const [vin, setVin] = useState('');
    const [refNo, setRefNo] = useState('');
    const [modelCode, setModelCode] = useState('');
    const [makeId, setMakeId] = useState(null);
    const [modelId, setModelId] = useState(null);
    const [rYear, setRYear] = useState('');
    const [rMonth, setRMonth] = useState('');
    const [mYear, setMYear] = useState('');
    const [mMonth, setMMonth] = useState('');
    const [vcondition, setVCondition] = useState(2);
    const [category, setCategory] = useState(null);
    const [subCategory, setSubCategory] = useState(null); 
    const [mileage, setMileage] = useState('');
    const [price, setPrice] = useState('');
    const [priceSale, setPriceSale] = useState('');
    const [doors, setDoors] = useState('');
    const [fuel, setFuel] = useState(null);
    const [steering, setSteering] = useState('');
    const [transmission, setTransmission] = useState(null);
    const [driveType, setDriveType] = useState('2W');
    const [height, setHeight] = useState('0');
    const [width, setWidth] = useState('0');
    const [length, setLength] = useState('0');
    const [eColor, setEColor] = useState('');
    const [iColor, setIColor] = useState('');
    const [displacement, setDisplacement] = useState('');
    const [seats, setSeats] = useState(0);
    const [fromCSV, setFromCSV] = useState(0);
    const [body, setBody] = useState('');
    const [bodyJp, setBodyJp] = useState('');
    const [chngOdometer, setChngOdometer] = useState(false);

    const [status, setStatus] = useState(2);
    const [gallery, setGallery] = useState('');
    const [tcv, setTcv] = useState('');
    const [thumbnail, setThumbnail] = useState('');
    const [features, setFeatures] = useState('');
    const [featuresArr, setFeaturesArr] = useState([]);

    const [initPrice, setInitPrice] = useState('');
    const [purchaseDate, setPurchaseDate] = useState(new Date()); //useState('');
    const [supplier, setSupplier] = useState('');
    const [externalLink1, setExternalLink1] = useState('');
    const [externalLink2, setExternalLink2] = useState(''); 
    const [tag, setTag] = useState(''); 

    const [url_, setUrl_] = useState([]);
    const [makeList, setMakeList] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [featureList, setFeatureList] = useState([]);
    const [transmList, setTransmList] = useState([]);
    const [activeFeatureList, setActiveFeatureList] = useState([]);
    const [fuelTypeList, setFuelTypeList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]); 
    const [supplierList, setSupplierList] = useState([]);

    const [imgArr, setImgArr] = useState([]);

    const [docModalShow, setDocModalShow] = useState(false);
    const [rev, setRev] = useState(0);


    //   title: 'Demo Vehicle 1',
    //   slug: 'demo-vehicle-1',
    //   location: 'Tokyo',
    //   vin: '11111111111101',
    //   model_code: 'DEMO-001',
    //   make_id: '10',
    //   r_year: '2023',
    //   r_month: '1',
    //   m_year: '2022',
    //   vcondition: '2',
    //   category: '1',
    //   mileage: '1000',
    //   price: '25000.00',
    //   price_sale: '24000.00',
    //   doors: '4',
    //   fuel: '1',
    //   steering: 'Right',
    //   transmission: '1',
    //   status: '1',

    const params = useParams();
    //const myRefs = useRef([]);

    //useCustomHeaders();

    useEffect(() => {
        //Makes List
        //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/make?page=${0}&pageSize=${20}`)
        CrudService.GetData(navigate, locationHook, 'make/basic')
        .then((res) => {
            setMakeList(res.data);
        })

        //Makes List
        //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/model?page=${0}&pageSize=${20}`)
        CrudService.GetData(navigate, locationHook, 'model/basic')
        .then((res) => {
            setModelList(res.data);
        })

        //Feature List
        //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/feature?page=${0}&pageSize=${20}`)
        CrudService.GetData(navigate, locationHook, 'feature/basic')
        .then((res) => {
            setFeatureList(res.data);
        })

        //Transmission List
        //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/transmission-type?page=${0}&pageSize=${20}`)
        CrudService.GetData(navigate, locationHook, 'transmission-type/basic')
        .then((res) => {
            setTransmList(res.data);
        })

        //Fuel List
        //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/fuel-type?page=${0}&pageSize=${20}`)
        CrudService.GetData(navigate, locationHook, 'fuel-type/basic')
        .then((res) => {
            setFuelTypeList(res.data);
        })

        //Location List
        //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/location?page=${0}&pageSize=${20}`)
        CrudService.GetData(navigate, locationHook, 'location/basic')
        .then((res) => {
            setLocationList(res.data);
        })

        //Category List
        //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/category?page=${0}&pageSize=${20}`)
        CrudService.GetData(navigate, locationHook, 'category/basic')
        .then((res) => {
            setCategoryList(res.data);
        })

        //Vendor List
        CrudService.GetData(navigate, location, 'vendor/basic')
        .then((res) => {
            setSupplierList(res.data);
        })


        if (params.id) {
            setId(params.id);
            //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/listing/${params.id}`)
            CrudService.GetDataById(navigate, location, 'listing', params.id)
                .then((res) => {
                    console.log(res.data);
                    setTitle(res.data.title);
                    setTitleJp(res.data.title_jp)
                    setSlug(res.data.slug);
                    setLocation(res.data.location_id);
                    setVin(res.data.vin);
                    setRefNo(res.data.ref_no);
                    setModelCode(res.data.model_code);
                    setMakeId(res.data.make_id);
                    setModelId(res.data.model_id);
                    setRYear(res.data.r_year);
                    setRMonth(res.data.r_month);
                    setMYear(res.data.m_year);
                    setMMonth(res.data.m_month);
                    setVCondition(res.data.vcondition);
                    setCategory(res.data.category_id);
                    setSubCategory(res.data.sub_category_id);
                    setMileage(res.data.mileage);
                    setPrice(Number(res.data.price));
                    setPriceSale(res.data.price_sale);
                    setDoors(res.data.doors);
                    setFuel(res.data.fuel_type_id);
                    setSteering(res.data.steering);
                    setTransmission(res.data.transmission_type_id);
                    setStatus(res.data.status);
                    setGallery(res.data.gallery);
                    setTcv(res.data.tcv_images);
                    setThumbnail(res.data.tumbnail);
                    setExternalLink1(res.data.external_link_1);
                    setExternalLink2(res.data.external_link_2);
                    setBody(res.data.body);
                    setBodyJp(res.data.body_jp);
                    setChngOdometer(res.data.chng_odometer);
                    console.log('setChngOdometer : ' + res.data.chng_odometer);

                    setHeight(res.data.height);
                    setWidth(res.data.width);
                    setLength(res.data.length);
                    setEColor(res.data.color_e);
                    setIColor(res.data.color_i);
                    setDisplacement(res.data.displacement);
                    setSeats(res.data.no_of_passengers);
                    setInitPrice(res.data.purchase_price);
                    setPurchaseDate(new Date(res.data.purchase_date));

                    setFromCSV(res.data.is_from_csv);
                    setFeatures(res.data.features);

                    setTag(res.data.tag)

                    if (res.data.features)
                        setFeaturesArr(JSON.parse(res.data.features));

                    setImgArr([]);
                    //console.log('Gallery All:' + JSON.parse(res.data.gallery));

                    if (res.data.gallery){
                        let gi_ = 0;
                        for (let i = 0; i < JSON.parse(res.data.gallery).length; i++) {
                            console.log('Gallery:' + JSON.parse(res.data.gallery)[i]);
                            setImgArr(imgArr => [...imgArr, JSON.parse(res.data.gallery)[i]]); //process.env.PUBLIC_URL +  //process.env.REACT_APP_API_END_POINT + '/' +
                            gi_ = i;
                        }
                        
                        if (res.data.tcv_images){
                            gi_ += 1;
                            for (let i = gi_; i < JSON.parse(res.data.tcv_images).length; i++) {
                                //console.log('TVC_Image:' + JSON.parse(res.data.tcv_images)[i]);
                                setImgArr(imgArr => [...imgArr, JSON.parse(res.data.tcv_images)[i]]);
                            }
                        }

                    }
                    else if (res.data.tcv_images) {
                        for (let i = 0; i < JSON.parse(res.data.tcv_images).length; i++) {
                            //console.log('TVC_Image:' + JSON.parse(res.data.tcv_images)[i]);
                            setImgArr(imgArr => [...imgArr, JSON.parse(res.data.tcv_images)[i]]);
                        }
                    }

                    // JSON.parse(res.data.features).map((item) => {
                    //     checkFeatureBox(item.id);
                    // })
                })
                .catch((error) => toast.error("Error Occured : " + error) )
        }

        
    }, [params.id])

    //Fetch Model Codes
    const fetchModels = () => {
        if (makeId || makeId != 0) {
            //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/model/make/${makeId}`)
            CrudService.GetDataById(navigate, locationHook, 'model/make', makeId)
            .then((res) => {
                console.log('Models' + res.data);
                setModelList(res.data);
            })
            .catch((error) => toast.error("Error Occured : " + error))
        }
    }
    useEffect(() => {
        fetchModels();
    }, [makeId])

    //------------------

    //Fetch Sub Cat Codes
    const fetchSubCategory = () => {
        if (category || category != '') {
            CrudService.GetDataById(navigate, locationHook, 'sub-category/category', category)
            .then((res) => {
                console.log('Sub Category' + res.data);
                setSubCategoryList(res.data);
            })
            .catch((error) => toast.error("Error Occured : " + error))
        }
    }

    useEffect(() => {
        fetchSubCategory();
    }, [category])
    //------------------

    const validateData = () => {
        //return (makeId == 0 || modelCode == 0) ? false : true;
        console.log('Val refNo: '+ refNo);
        console.log('Val price: '+ price);
        return (refNo == '' || price == '') ? false : true;
    }

    const handleSubmit = (event) => {
        let tcv_ = (tcv) ? JSON.parse(tcv) : [];
        url_.map((data) => {
            console.log('data s: '+ data);
            tcv_.push(data);
            setImgArr(imgArr => [...imgArr, data]);
        })
        console.log('Gallery 2: '+ gallery);
        setGallery(JSON.stringify(url_));
        setTcv(JSON.stringify(imgArr));
        let tcv_json = JSON.stringify(tcv_);
        event.preventDefault();

        if (!validateData()) {
            console.error('Please fill the required field!');
            alert('Please fill the required field!');
            return;
        }

        console.log('Body Jp: ' + bodyJp);
        console.log('id :'+id);
        if (id == null || id == 0){
            let features_ = JSON.stringify(featuresArr);
            //axios.post(`${process.env.REACT_APP_API_END_POINT}/api/admin/listing`, {
            CrudService.PostData(navigate, location, 'listing', {
                title, titleJp, slug, location, vin, refNo, modelCode, makeId, modelId, rYear, rMonth, mYear, mMonth,
                vcondition, category, subCategory, mileage, price, priceSale, doors, fuel, steering, transmission, status, gallery, tcv_json, thumbnail, externalLink1, externalLink2, features_,
                length, width, height, iColor, eColor, supplier, purchaseDate, seats, tag, initPrice, body, bodyJp, displacement, chngOdometer
            }).then((response) => {
                console.log(response.data);
                navigate(`${process.env.PUBLIC_URL}/listing`);
            })
            .catch((error) => {
                console.error(error?.name);
                toast.error("Error Occured : " + error?.name);
            });

        } else {
            let features_ = JSON.stringify(featuresArr);
            CrudService.PutData(navigate, location, 'listing', id,
            //axios.put(`${process.env.REACT_APP_API_END_POINT}/api/admin/listing/${id}`,
            {
                id, titleJp, title, slug, location, vin, refNo, modelCode, makeId, modelId, rYear, rMonth, mYear, mMonth,
                vcondition, category, subCategory, mileage, price, priceSale, doors, fuel, steering, transmission, status, gallery, tcv_json, thumbnail, externalLink1, externalLink2, features_,
                length, width, height, iColor, eColor, supplier, purchaseDate, seats, tag, initPrice, body, bodyJp, displacement, chngOdometer
            })
            .then((response) => {
                console.log(response.data);
                navigate(`${process.env.PUBLIC_URL}/listing`);
            })
            .catch((error) => {
                console.error(error?.name);
                toast.error("Error Occured : " + error?.name);
            });
        }

    };

    const handleFeatureCheck = (checked, id) => {
        console.log('feature id:' + id);
        if (checked) {
            setActiveFeatureList(activeFeatureList => [...activeFeatureList, id]);
        }else{
            setActiveFeatureList((activeFeatureList) =>
                activeFeatureList.filter((item) => item !== id)
            );
        }
        //setActiveFeatureList([...activeFeatureList, id]);
    }

    useEffect(() => {
        activeFeatureList?.map((item) => {
            console.log('Feature items: ' + item);
        })
        
        if (activeFeatureList.length > 1)
            setFeatures(JSON.stringify(activeFeatureList));
    }, [activeFeatureList]);

    const handleImgUploads = (url) => {
        console.log('URL:' + url);

        setUrl_(url_ => [...url_, url] );
    }

    useEffect(() => {
        console.log('url_ : '+ JSON.stringify(url_));
        //console.log('Gallery : '+ gallery);
        setGallery(JSON.stringify(url_));
        url_.map((data) => {
            console.log('url_ data : '+ data);
        })
    }, [url_]);

    //Thumbnail Upload
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [thumbName, setThumbName] = useState('');

    const handleThumbnailChange = (event) => {
        setSelectedFiles(event.target.files);
        setThumbName('');
        // for (let index = 0; index < event.target.files.length; index++) { 
        //     setFileName(fileName => [...fileName, ' ' + event.target.files[index].name]);
        // }
        setThumbName(event.target.files[0].name);
        console.log('fileName '+ thumbName);

        //uploadThumbnail(event);
        
    };

    const uploadThumbnail = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        //for (let i = 0; i < selectedFiles.length; i++) {
            formData.append("images", selectedFiles[0]);
        //}

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/api/upload`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });

            console.log(response.data);
            // handle success response here

            setThumbnail(response.data.files[0].url);

            //toast.success("Upload Completed.");
        } catch (error) {
            console.error(error);
        }
    };
    //End Thumbnail Upload

    // Doc Upload
    const [csvFile, setCsvFile] = useState();
    const [data, setData] = useState([]);
    const [fileName, setFileName] = useState('');

    const handleUpload = (event) => {
        setCsvFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
    };

    const handleDocSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("doc", csvFile);

        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_END_POINT}/api/admin/docman/upload`,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        })
            .then((res) => {
                if (res.status === 200) {
                    setData(res.data);
                    toast.success("Upload Completed. Data processing in Progress.");
                }
            })
            .catch((err) => {
                alert('Error Occured');
                console.error(err);
            });
    };

    const handleDeleteImg = (index_) => {
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure You want to delete ?',
            //showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            //denyButtonText: `Cancell`,
            confirmButtonColor: 'red',
            cancelButtonText: 'No'
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                
                const temp = JSON.parse(tcv);
                let images = [...temp];

                //let images_ = images.slice(index_, 1);
                const images_ = images.filter((item, index) => index !== index_);
                
                let images2 = (images_ != null)  ? JSON.stringify(images_) : tcv;

                CrudService.PutData(navigate, location, 'listing/image', id,
                {
                    id: id, gallery: images2
                })
                .then((response) => {
                    console.log(response.data);
                    toast.success("Image was Deleted")
                    window.location.reload(); 
                })
                .catch((error) => {
                    console.log(error);
                    toast.error("Error Occured : " + error)
                });

            } 
        })
    }

    // function checkFeatureBox(i) {
    //     let ref = 'ref_' + i;
    //     //this.refs[ref].checked = true;
    //     //this.refs[ref].checked = !this.refs[ref].checked;
    //     //myRefs[ref].checked = true;

    //     //console.log(myRefs[ref]);
    // }



    return (
        <div className="content-wrapper" style={{ minHeight: '1345.6px' }}>
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1><NavLink to="/listing" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; {t('stock-page.add-stock')} - { (status != null) && (status == 1) ? <span class="badge bg-success">Published</span> : <span class="badge bg-warning">UnPublished</span> } </h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><NavLink exact to="/listing" activeClassName="selected" >Listing</NavLink></li>
                                <li className="breadcrumb-item active">{t('stock-page.add-stock')}</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    {/* Tab row */}
                    <div className="row">
                        {/* Tabs */}
                        
                            <div class="col-12">
                                <div class="card card-primary card-outline card-outline-tabs">
                                    <div class="card-header p-0 border-bottom-0">
                                        <ul class="nav nav-tabs" id="custom-tabs-four-tab-1" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link active" id="custom-tabs-four-1-home-tab" data-toggle="pill" href="#custom-tabs-four-1-home" role="tab" aria-controls="custom-tabs-four-1-home" aria-selected="true">{t('stock-page.basic-info')}</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="custom-tabs-four-1-profile-tab" data-toggle="pill" href="#custom-tabs-four-1-profile" role="tab" aria-controls="custom-tabs-four-1-profile" aria-selected="false">{t('stock-page.features')}</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="custom-tabs-four-1-messages-tab" data-toggle="pill" href="#custom-tabs-four-1-messages" role="tab" aria-controls="custom-tabs-four-1-messages" aria-selected="false">{t('stock-page.specs')}</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="custom-tabs-four-1-images-tab" data-toggle="pill" href="#custom-tabs-four-1-images" role="tab" aria-controls="custom-tabs-four-1-images" aria-selected="false">{t('stock-page.media')}</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="custom-tabs-four-1-misc-tab" data-toggle="pill" href="#custom-tabs-four-1-misc" role="tab" aria-controls="custom-tabs-four-1-misc" aria-selected="false">{t('stock-page.misc-info')}</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="card-body">
                                        <div class="tab-content" id="custom-tabs-four-tab-1Content">
                                            <div class="tab-pane fade show active" id="custom-tabs-four-1-home" role="tabpanel" aria-labelledby="custom-tabs-four-1-home-tab">
                                                
                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="refNo">{t('stock-page.ref')} # *</label>
                                                            <input type="text" className="form-control" id="refNo" placeholder="" value={refNo} onChange={(e) => setRefNo(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3 col-lg-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">{t('stock-page.title') + '-EN'}</label>
                                                            <input type="text" className="form-control" id="title" placeholder="" value={title} onChange={(e) => setTitle(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3 col-lg-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">{t('stock-page.title') + '-JP'}</label>
                                                            <input type="text" className="form-control" id="title" placeholder="" value={titleJp} onChange={(e) => setTitleJp(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="vin">{t('stock-page.vin')}</label>
                                                            <input type="text" className="form-control" id="vin" placeholder="" value={vin} onChange={(e) => setVin(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ----------- */}
                                                

                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="category">{t('stock-page.category')}</label>
                                                            <select class="form-control" id="category" value={category} onChange={(e) =>  setCategory(e.target.value)}>
                                                                <option value=''>-- Select a {t('stock-page.category')} --</option>
                                                                { categoryList?.map((item, key) => {
                                                                    return ( <option key={key} value={item.id}>{item.name}</option> )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="category">{t('stock-page.sub-category')}</label>
                                                            <select class="form-control" id="category" value={subCategory} onChange={(e) =>  setSubCategory(e.target.value)}>
                                                                <option value=''>-- Select a {t('stock-page.sub-category')} --</option>
                                                                { subCategoryList?.map((item, key) => {
                                                                    return ( <option key={key} value={item.id}>{item.name}</option> )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div class="form-group">
                                                            <label htmlFor="makeId">{t('stock-page.make')}</label>
                                                            <select class="form-control" id="makeId" value={makeId} onChange={(e) => {setMakeId(e.target.value);}}>
                                                                <option value=''>-- Select a {t('stock-page.make')} --</option>
                                                                { makeList?.map((item) => {
                                                                    return ( <option value={item.id}>{item.name}</option> )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div class="form-group">
                                                            <label htmlFor="modelId">{t('stock-page.model')}</label>
                                                            <select class="form-control" id="modelId" value={modelId} onChange={(e) => {setModelId(e.target.value);}}>
                                                                <option value=''>-- Select a {t('stock-page.model')} --</option>
                                                                { modelList?.map((item) => {
                                                                    return ( <option value={item.id}>{item.name}</option> )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="rYear">{t('stock-page.registration-year')}</label>
                                                            <input type="number" className="form-control" id="rYear" placeholder="" value={rYear} onChange={(e) => setRYear(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="rMonth">{t('stock-page.registration-month')}</label>
                                                            <input type="number" className="form-control" id="rMonth" placeholder="" value={rMonth} onChange={(e) => setRMonth(e.target.value)}/>
                                                        </div>                                               
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="mYear">{t('stock-page.manufacture-year')}</label>
                                                            <input type="number" className="form-control" id="mYear" placeholder="" value={mYear} onChange={(e) => setMYear(e.target.value)}/>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="mMonth">{t('stock-page.manufacture-month')}</label>
                                                            <input type="number" className="form-control" id="mMonth" placeholder="" value={mMonth} onChange={(e) => setMMonth(e.target.value)}/>
                                                        </div>
                                                        
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="grade">{t('stock-page.grade')}</label>
                                                            <input type="text" className="form-control" id="grade" placeholder="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="mileage">{t('stock-page.mileage')}</label>
                                                            <input type="text" className="form-control" id="mileage" placeholder="" value={mileage} onChange={(e) => setMileage(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="modelCode">{t('stock-page.model-code')}</label>
                                                            <input type="text" className="form-control" id="modelCode" placeholder="" value={modelCode} onChange={(e) => setModelCode(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="modelCode">{t('stock-page.displacement')} (cc)</label>
                                                            <input type="number" className="form-control" id="displacement" placeholder="" value={displacement} onChange={(e) => setDisplacement(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="price">{t('stock-page.price')} *</label>
                                                            <input type="number" className="form-control" id="price" placeholder="" value={price} onChange={(e) => setPrice(e.target.value)} />
                                                        </div>

                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="priceSale">{t('stock-page.price-sale')}</label>
                                                            <input type="number" className="form-control" id="priceSale" placeholder="" value={priceSale} onChange={(e) => setPriceSale(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3 col-lg-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="slug">{t('stock-page.slug')}</label>
                                                            <input type="text" className="form-control" id="slug" placeholder="" value={slug} onChange={(e) => setSlug(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="location">{t('stock-page.location')}</label>
                                                            <select class="form-control" id="location" value={location} onChange={(e) =>  setLocation(e.target.value)}>
                                                                {/* <option value=''>-- Select a {t('stock-page.location')} --</option> */}
                                                                <option value=''>-- N/A --</option>
                                                                { locationList?.map((item, key) => {
                                                                    return ( <option key={key} value={item.id}>{item.name}</option> )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="vCondition">{t('stock-page.condition')}</label>
                                                            {/* <input type="text" className="form-control" id="vCondition" placeholder="" value={vcondition} onChange={(e) => setVCondition(e.target.value)} /> */}
                                                            <select class="form-control" id="vCondition" value={vcondition} onChange={(e) => setVCondition(e.target.value)} >
                                                                <option value='1'>New</option>
                                                                <option value='2'>Used</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">{t('stock-page.changed-odometer')}</label>
                                                            <select class="form-control" id="chngOdometer" value={chngOdometer} onChange={(e) => { setChngOdometer(e.target.value); console.log(e.target.value);}}>
                                                                <option value='true'>Yes</option>
                                                                <option value='false'>No</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="slug">{t('stock-page.tag')}</label>
                                                            <select class="form-control" id="tag" placeholder="" value={tag} onChange={(e) => setTag(e.target.value)}>
                                                                <option value=''>-- Select {t('stock-page.tag')} --</option>
                                                                <option value='offer'>Offer</option>
                                                                <option value='clearance'>Clearance</option>
                                                                <option value='featured'>Featured</option>
                                                                <option value='sold'>Sold</option>
                                                                <option value='reserved'>Reserved</option>
                                                                <option value='underNego'>Under Negotiation</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div class="form-group">
                                                            <label htmlFor="status">{t('stock-page.status')}</label>
                                                            <select className="form-control" id="status" value={status} onChange={(e) => { console.log(e.target.value); setStatus(e.target.value);}}>
                                                                <option value="1">Published</option>
                                                                <option value="2">UnPublished</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label htmlFor="comment">{t('stock-page.comment')}</label>
                                                            <textarea className="form-control" id="body" placeholder="Text {BR} Text" value={body} onChange={(e) => setBody(e.target.value)} ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label htmlFor="comment">{t('stock-page.comment-jp')}</label>
                                                            <textarea className="form-control" id="bodyJp" placeholder="Text {BR} Text" value={bodyJp} onChange={(e) => setBodyJp(e.target.value)} ></textarea>
                                                        </div>
                                                    </div>
                                                </div> 

                                            </div>
                                            {/* Features */}
                                            <div class="tab-pane fade" id="custom-tabs-four-1-profile" role="tabpanel" aria-labelledby="custom-tabs-four-1-profile-tab">
                                                <div class="row">
                                                    
                                                    <div class="col-sm-6">
                                                            
                                                    </div>
                                                </div>
                                                <div>
                                                    <DualBox featureList={featureList} active={featuresArr} setFeaturesArr={setFeaturesArr} />
                                                </div>
                                            </div>
                                            {/* Specs */}
                                            <div class="tab-pane fade" id="custom-tabs-four-1-messages" role="tabpanel" aria-labelledby="custom-tabs-four-1-messages-tab">
                                                <div className='row'>
                                                    <div className='col-md-2'>
                                                        <div className="form-group">
                                                            <label htmlFor="doors">{t('stock-page.doors')} *</label>
                                                            <input type="number" className="form-control" id="doors" placeholder="0" value={doors} onChange={(e) => setDoors(e.target.value)} /> 
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <div className="form-group">
                                                            <label htmlFor="fuel">{t('stock-page.fuel')} *</label>
                                                            <select class="form-control" id="fuel" placeholder="" value={fuel} onChange={(e) => setFuel(e.target.value)}>
                                                                <option value=''>-- Select {t('stock-page.fuel')} Type --</option>
                                                                { fuelTypeList?.map((item) => {
                                                                    return ( <option value={item.id}>{item.name}</option> )
                                                                })}
                                                            </select>
                                                        </div>

                                                    </div>
                                                    <div className='col-md-2'>
                                                        <div className="form-group">
                                                            <label htmlFor="steering">{t('stock-page.steering')} *</label>
                                                            <select class="form-control" id="steering" placeholder="" value={steering} onChange={(e) => setSteering(e.target.value)}>
                                                                <option value='Left'>Left</option>
                                                                <option value='Right'>Right</option>
                                                                <option value='Center'>Center</option>
                                                                <option value='Unspecified'>Unspecified</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label htmlFor="transmission">{t('stock-page.transmission')} *</label>
                                                            <select class="form-control" id="transmission" placeholder="" value={transmission} onChange={(e) => setTransmission(e.target.value)}>
                                                                <option value=''>-- Select {t('stock-page.transmission')} Type --</option>
                                                                { transmList?.map((item) => {
                                                                    return ( <option value={item.id}>{item.name}</option> )
                                                                })}
                                                            </select>
                                                        </div>

                                                    </div>
                                                    <div className='col-md-2'>
                                                        <div className="form-group">
                                                            <label htmlFor="driveType">{t('stock-page.drive-type')}</label>
                                                            <select class="form-control" id="driveType" value={driveType} onChange={(e) => { setDriveType(e.target.value);}}>
                                                                <option value='2W'>2W</option>
                                                                <option value='4W'>4W</option>
                                                                <option value='ALL'>ALL</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div className='row'>
                                                    <div className='col-md-2'>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">{t('stock-page.lenght')}</label>
                                                            <input type="text" className="form-control" id="length" placeholder="" value={length} onChange={(e) => { setLength(e.target.value);}}/>
                                                        </div>

                                                    </div>
                                                    <div className='col-md-2'>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">{t('stock-page.width')}</label>
                                                            <input type="text" className="form-control" id="width" placeholder="" value={width} onChange={(e) => { setWidth(e.target.value);}}/>
                                                        </div>

                                                    </div>
                                                    <div className='col-md-2'>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">{t('stock-page.height')}</label>
                                                            <input type="text" className="form-control" id="height" placeholder="" value={height} onChange={(e) => { setHeight(e.target.value);}}/>
                                                        </div>

                                                    </div>
                                                    <div className='col-md-2'>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">{t('stock-page.exterior-color')}</label>
                                                            <input type="text" className="form-control" id="eColor" placeholder="" value={eColor} onChange={e => setEColor(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">{t('stock-page.interior-color')}</label>
                                                            <input type="text" className="form-control" id="iColor" placeholder="" value={iColor} onChange={e => setIColor(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">{t('stock-page.no-of-passengers')}</label>
                                                            <input type="number" className="form-control" id="seats" placeholder="" value={seats} onChange={e => setSeats(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            {/* Media */}
                                            <div class="tab-pane fade" id="custom-tabs-four-1-images" role="tabpanel" aria-labelledby="custom-tabs-four-1-images-tab">
                                                <div className="row">
                                                    <div className='col-md-6 col-lg-4'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">{t('stock-page.thumbnail')}</label>
                                                            <div className="input-group">
                                                                <div className="custom-file">
                                                                    <input type="file" className="custom-file-input" id="thumbnailupload" name="thumbnail" onChange={handleThumbnailChange}/>
                                                                    <label className="custom-file-label" htmlFor="exampleInputFile">{thumbName ? thumbName : 'Choose File'}</label>
                                                                </div>
                                                                <div class="input-group-append">
                                                                    <button class="input-group-text" onClick={e => uploadThumbnail(e)}>{t('stock-page.upload')}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3 col-lg-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">{t('stock-page.youtube-link')}</label>
                                                            <input type="text" className="form-control" id="title" placeholder="youtu.be/xYzm" value={externalLink1} onChange={ e => setExternalLink1(e.target.value) }/>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3 col-lg-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">{t('stock-page.facebook-link')}</label>
                                                            <input type="text" className="form-control" id="title" placeholder="facebook.com/lnk?p=we23" value={externalLink2} onChange={ e => setExternalLink2(e.target.value) }/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        { (thumbnail) &&
                                                        <img
                                                            src={thumbnail} //filename  //{`${process.env.REACT_APP_API_END_POINT}/${thumbnail}`}
                                                            alt={thumbnail}
                                                            width={"150px"}
                                                            className="img-thumbnail"
                                                        />
                                                        }
                                                        <span class="badge bg-secondary">{ (fromCSV) ? 'CSV' : 'Manual' }</span>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                {/* <div className="row"> */}
                                                    {/* <div className='col-md-12'> */}
                                                        <UploadImage handleImgUploads={handleImgUploads} images_={url_}/>
                                                        <hr></hr>
                                                    {/* </div> */}
                                                {/* </div> */}
                                                
                                            </div>
                                            {/* MiscInfo */}
                                            <div class="tab-pane fade" id="custom-tabs-four-1-misc" role="tabpanel" aria-labelledby="custom-tabs-four-1-misc-tab">
                                                <div className='row'>
                                                    <div className='col-md-4 col-lg-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">{t('stock-page.purchased-price')}</label>
                                                            <input type="text" className="form-control" id="title" placeholder="" value={initPrice} onChange={(e) => setInitPrice(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 col-lg-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="purchase-date">{t('stock-page.purchased-date')}</label>
                                                            {/* <input type="date" className="form-control" id="purchase-date" placeholder="" value={purchaseDate} onChange={(e) => setPurchaseDate(e.target.value)} /> */}
                                                            <DatePicker className="form-control" id="purchase-date" selected={purchaseDate} onChange={(date) => setPurchaseDate(date)} dateFormat="dd/MM/yyyy" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 col-lg-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="slug">{t('stock-page.vendor')}</label>
                                                            {/* <input type="text" className="form-control" id="slug" placeholder="" value={supplier} onChange={(e) => setSupplier(e.target.value)} /> */}
                                                            <select class="form-control" id="supplier" value={supplier} onChange={(e) => { setSupplier(e.target.value);}}>
                                                                <option value=''>-- Select {t('stock-page.vendor')} --</option>
                                                                { supplierList.map((item, index) => {
                                                                    return(
                                                                        <option value={item.id} key={index}>{item.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <hr />
                                                <div className='row'>
                                                    <div className='col-md-4 col-lg-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="slug">Tag</label>
                                                            <select class="form-control" id="tag" placeholder="" value={tag} onChange={(e) => setTag(e.target.value)}>
                                                                <option value=''>-- Select Tag --</option>
                                                                <option value='offer'>Offer</option>
                                                                <option value='clearance'>Clearance</option>
                                                                <option value='featured'>Featured</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                                    </div>                                
                                </div>
                            </div>
                        
                        {/* Tab Ends */}
                    </div>
                    {/* Tab Row Ends */}

                    {/* /.row */}
                    {/* Not in Use */}

                    {/* Tab row */}
                    <div className="row">
                        {/* Tabs */}
                        { id &&
                            <div class="col-12">
                                <div class="card card-primary card-outline card-outline-tabs">
                                    <div class="card-header p-0 border-bottom-0">
                                        <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill" href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home" aria-selected="true">Cost</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="custom-tabs-four-profile-tab" data-toggle="pill" href="#custom-tabs-four-profile" role="tab" aria-controls="custom-tabs-four-profile" aria-selected="false">Documents</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="custom-tabs-four-messages-tab" data-toggle="pill" href="#custom-tabs-four-messages" role="tab" aria-controls="custom-tabs-four-messages" aria-selected="false">Documents - After Sale</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="custom-tabs-four-images-tab" data-toggle="pill" href="#custom-tabs-four-images" role="tab" aria-controls="custom-tabs-four-images" aria-selected="false">View/Edit Images</a>
                                            </li>
                                            {/* <li class="nav-item">
                                                <a class="nav-link" id="custom-tabs-four-miscinfo-tab" data-toggle="pill" href="#custom-tabs-four-miscinfo" role="tab" aria-controls="custom-tabs-four-miscinfo" aria-selected="false">Misc Info</a>
                                            </li> */}
                                        </ul>
                                    </div>
                                    <div class="card-body">
                                        <div class="tab-content" id="custom-tabs-four-tabContent">
                                            <div class="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
                                                <Cost refNo={refNo}/>
                                                
                                            </div>
                                            {/* Documents */}
                                            <div class="tab-pane fade" id="custom-tabs-four-profile" role="tabpanel" aria-labelledby="custom-tabs-four-profile-tab">
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className='form-group'>
                                                            <button className='btn btn-info btn-sm' data-toggle="modal" data-target="#modal-default" onClick={() => {setRev(1); setDocModalShow(true);}}>Add New Document</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='clearfix'></div>
                                                <div className='row'>
                                                    <div className="col-md-12">
                                                        <Document refresh={docModalShow} rev={1} ref_no={refNo} />
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div class="tab-pane fade" id="custom-tabs-four-messages" role="tabpanel" aria-labelledby="custom-tabs-four-messages-tab">
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <button className='btn btn-info btn-sm' data-toggle="modal" data-target="#modal-default" onClick={() => {setRev(2); setDocModalShow(true)}}>Add New Document</button>
                                                    </div>
                                                </div>
                                                <Document refresh={docModalShow} rev={2} ref_no={refNo} />
                                            </div>
                                            <div class="tab-pane fade" id="custom-tabs-four-images" role="tabpanel" aria-labelledby="custom-tabs-four-images-tab">
                                                <div className='row'>
                                                    {/* <div className='col-md-12'> */}
                                                        { imgArr.map((item, index) => {
                                                            return(
                                                                <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3' style={{ paddingBottom: 10}}>
                                                                    <button className='btn btn-sm btn-danger' key={index} onClick={() => handleDeleteImg(index) }>x</button>
                                                                    <img src={item} width={300} className='img-fluid'></img>
                                                                </div>
                                                            )
                                                        })}
                                                    {/* </div> */}
                                                </div>
                                                
                                            </div>
                                            
                                        </div>
                                    </div>

                                </div>
                            </div>
                        }
                        {/* Tab Ends */}
                    </div>

                    <DocumentModal show={docModalShow} refNo={refNo} refresh={setDocModalShow} rev={rev}/>
                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
            <ToastContainer 
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" 
            />
        </div>

    )
}

export default AddListing