import React, { useState, useEffect } from 'react'
//import axios from 'axios';
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import CrudService from '../../util/crudService'

const SubCategoryList = ({ navigate, location }) => {
    const [subCatList, setSubCatList] = useState([]);

    const [currPage, setCurrPage] = useState(1);
    const [page_, setPage_] = useState(1);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    
    //Next Page
    const nextPage = () => {
        if (hasNext)
            setPage_(currPage + parseInt('1'));
    }

    //Previous Page
    const prevPage = () => {
        if (hasPrev)
            setPage_(currPage - parseInt('1'));
    }

    const gotoPage = (page_) => {
        if (hasNext)
            setPage_(parseInt(page_));
    }

    const fetchData = () => {
        CrudService.GetDataWithParam(navigate, location, 'sub-category/extend', {
            page: page_,
        })
        .then((res) => {
            console.log(res.data);
            setSubCatList(res.data.data);
            setCurrPage(parseInt(res.data.curr_page));
            setHasNext(res.data.hasNext);
            setHasPrev(res.data.hasPrev);
            setRowCount(res.data.count);
        }).catch((error) => {
            console.error(error);
        })
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [page_]);

    const handleEdit = (id) => {
        console.log('ID : '+ id);
        navigate(`${process.env.PUBLIC_URL}/add-sub-category/${id}`);
    }

    const handleDelete = (id) => {

        if (window.confirm('Are you sure want to DELETE ?')){
            CrudService.DeleteData(navigate, location, 'sub-category', id)
            .then((res) => {
                window.alert(res.data);
                //toast.success("Stock Delete " + res.data);
                console.log('Deleted :' + res);
                fetchData();
            })
            .catch((error) => {
                console.error(error);
            })
        }
    }

    const pages = [];

    for (let i = 1; i <= (rowCount/10) + 1; i++) {
		pages.push(
			<li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(i)}>{i}</a></li>
		);
	}

    return (
        <section class="content">
            <div className="card col-lg-10">
                <div className="card-header">
                    {/* <h3 className="card-title">Category Table</h3> */}
                    <div className="card-tools">
                        {/* <div className="input-group input-group-sm" style={{ width: 150 }}>
                            <input type="text" name="table_search" className="form-control float-right" placeholder="Search" />
                            <div className="input-group-append">
                                <button type="submit" className="btn btn-default">
                                    <i className="fas fa-search" />
                                </button>
                            </div>
                        </div> */}
                        <div className='btn btn-tool'>
                            <NavLink exact to="/add-sub-category" activeClassName="selected">
                                <button type="button" class="btn btn-block btn-outline-success btn-sm"><i class="fas fa-plus fa-sm"></i>&nbsp;Add Category</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
                {/* /.card-header */}
                <div className="card-body table-responsive p-0">
                    <table className="table table-hover text-nowrap">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Parent Category</th>
                                <th>Name</th>
                                <th>Slug</th>
                                <th>Image</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            { subCatList?.map((value) => {
                                    return (
                                <tr>
                                    <td>
                                        <button className='btn btn-warning btn-xs' onClick={() => {handleEdit(value.id)}}><i class="fas fa-pencil-alt fa-sm"></i></button> &nbsp;
                                        <button className='btn btn-danger btn-xs' onClick={() => handleDelete(value.id)}> <i class="far fa-trash-alt fa-sm"></i></button>
                                    </td>
                                    <td>{ value.Category?.name }</td>
                                    <td>{ value.name }</td>
                                    <td>{ value.slug }</td>
                                    <td>{ value.image }</td>
                                    <td>{ value.status == 1 ? <span class="badge bg-success">Enable</span>  : <span class="badge bg-danger">Disable</span> }</td>
                                </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                {/* /.card-body */}
                {/* /.card-body */}
                <div className='card-footer clearfix'>
                    <ul class="pagination pagination-sm">
                        { (hasPrev == 'true') &&
                            <li class="page-item"><a class="page-link" href="javascript:void(0);" tabindex="-1" onClick={ () => prevPage()}>«</a></li>
                        }
                        {pages}
                        {/* <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(1)}>1</a></li>
                        <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(2)}>2</a></li>
                        <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(3)}>3</a></li> */}
                        { (hasNext == 'true') &&
                            <li class="page-item"><a class="page-link" href="javascript:void(0);" onClick={ () => nextPage()}>»</a></li>
                        }
                    </ul>
                </div>
            </div>
        </section>
    )
}

function CategoryList() {
    const navigate = useNavigate();
    const location = useLocation();
    const [makeList, setMakeList] = useState([]);

    const [currPage, setCurrPage] = useState(1);
    const [page_, setPage_] = useState(1);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    
    //Next Page
    const nextPage = () => {
        if (hasNext)
            setPage_(currPage + parseInt('1'));
    }

    //Previous Page
    const prevPage = () => {
        if (hasPrev)
            setPage_(currPage - parseInt('1'));
    }

    const gotoPage = (page_) => {
        if (hasNext)
            setPage_(parseInt(page_));
    }

    const fetchData = () => {
        CrudService.GetDataWithParam(navigate, location, 'category/extend', {
            page: page_,
        })
        .then((res) => {
            console.log(res.data);
            setMakeList(res.data.data);
            setCurrPage(parseInt(res.data.curr_page));
            setHasNext(res.data.hasNext);
            setHasPrev(res.data.hasPrev);
            setRowCount(res.data.count);
        }).catch((error) => {
            console.error(error);
        })
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [page_]);

    const handleEdit = (id) => {
        console.log('ID : '+ id);
        navigate(`${process.env.PUBLIC_URL}/add-category/${id}`);
    }

    const handleDelete = (id) => {

        if (window.confirm('Are you sure want to DELETE ?')){
            CrudService.DeleteData(navigate, location, 'category', id)
            .then((res) => {
                window.alert(res.data);
                //toast.success("Stock Delete " + res.data);
                console.log('Deleted :' + res);
                fetchData();
            })
            .catch((error) => {
                console.error(error);
            })
        }
    }

    const pages = [];

    for (let i = 1; i <= (rowCount/10) + 1; i++) {
		pages.push(
			<li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(i)}>{i}</a></li>
		);
	}

    return (

        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0"><NavLink to="/menu" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; Car Category</h1>
                            {/* <div>
                                <NavLink exact to="/addCategory" activeClassName="selected">
                                    <button type="button" class="btn btn-block btn-primary">Add Category</button>
                                </NavLink>
                            </div> */}
                        </div>
                        {/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <NavLink exact to={`${process.env.PUBLIC_URL}/menu`} activeClassName="selected" >Configurations</NavLink>
                                </li>
                                <li className="breadcrumb-item active">Category List</li>
                            </ol>
                        </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>{/* /.container-fluid */}
            </div>
            <section class="content">
                <div className="card col-lg-10">
                    <div className="card-header">
                        {/* <h3 className="card-title">Category Table</h3> */}
                        <div className="card-tools">
                            {/* <div className="input-group input-group-sm" style={{ width: 150 }}>
                                <input type="text" name="table_search" className="form-control float-right" placeholder="Search" />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-default">
                                        <i className="fas fa-search" />
                                    </button>
                                </div>
                            </div> */}
                            <div className='btn btn-tool'>
                                <NavLink exact to="/add-category" activeClassName="selected">
                                    <button type="button" class="btn btn-block btn-outline-success btn-sm"><i class="fas fa-plus fa-sm"></i>&nbsp;Add Category</button>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body table-responsive p-0">
                        <table className="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Slug</th>
                                    <th>Image</th>
                                    <th>Status</th>
                                    {/* <th>Created By</th>
                                    <th>Updated By</th>
                                    <th>Created At</th>
                                    <th>Updated At</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                { makeList?.map((value) => {
                                        return (
                                    <tr>
                                        <td>
                                            <button className='btn btn-warning btn-xs' onClick={() => {handleEdit(value.id)}}><i class="fas fa-pencil-alt fa-sm"></i></button> &nbsp;
                                            <button className='btn btn-danger btn-xs' onClick={() => handleDelete(value.id)}> <i class="far fa-trash-alt fa-sm"></i></button>
                                        </td>
                                        <td>{ value.name }</td>
                                        <td>{ value.slug }</td>
                                        <td>{ value.image }</td>
                                        <td>{ value.status == 1 ? <span class="badge bg-success">Enable</span>  : <span class="badge bg-danger">Disable</span> }</td>
                                        {/* <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td> */}
                                    </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/* /.card-body */}
                    {/* /.card-body */}
                    <div className='card-footer clearfix'>
                        <ul class="pagination pagination-sm">
                            { (hasPrev == 'true') &&
                                <li class="page-item"><a class="page-link" href="javascript:void(0);" tabindex="-1" onClick={ () => prevPage()}>«</a></li>
                            }
                            {pages}
                            {/* <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(1)}>1</a></li>
                            <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(2)}>2</a></li>
                            <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(3)}>3</a></li> */}
                            { (hasNext == 'true') &&
                                <li class="page-item"><a class="page-link" href="javascript:void(0);" onClick={ () => nextPage()}>»</a></li>
                            }
                        </ul>
                    </div>
                </div>
            </section>
            <SubCategoryList navigate={navigate} location={location} />
        </div>

    )
}

export default CategoryList