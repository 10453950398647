import React from 'react'
import Placeholder from '../components/home/placeholder';
import SideNav from '../components/home/sideNav';
import Footer from '../components/home/footer';
import CurrencyList from '../components/currency/currency';

export const CurrencyPage = () => (
    <div class="wrapper">
        <Placeholder />
        <SideNav />
        <CurrencyList />
        <Footer />
    </div>
);