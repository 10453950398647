import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import CrudService from '../../util/crudService';

export default function Document(props) {

    const locationHook = useLocation();
    const navigate = useNavigate();

    const [documentList, setDocumentList] = useState([]);
    const [refresh, setRefresh] = useState(false);

    const [ric, setRic] = useState('');
    const [al, setAl] = useState('');
    const [rt, setRt] = useState('');
    const [cali, setCali] = useState('');
    const [sc, setSc] = useState('');

    useEffect(() => {
        CrudService.GetDataWithParam(navigate, locationHook, 'docman/rev', { rev: props.rev, ref_no: props.ref_no})
        .then((res) => {
            if (res != null)
                setDocumentList(res.data);
        })
    }, [props.refresh, props.ref_no]);

    useEffect(() => {
        documentList.map((item) => {
            if (item.type == 1)
                setRic(item.name)
            else if (item.type == 2) {
                setAl(item.name)
            }
            else if (item.type == 3) {
                setRt(item.name)
            }
            else if (item.type == 4) {
                setCali(item.name)
            }
            else if (item.type == 5) {
                setSc(item.name)
            }
        })
    }, [documentList, refresh]);
    
    const handleDelete = (e, id) => {
        e.preventDefault();

        CrudService.DeleteData(navigate, locationHook, 'docman', id)
        .then((res) => {
            alert('Success :' + res.data);
            setRefresh(!refresh)
        })
        .catch((err) => {
            alert('Error occured :' + err);
        })
    }

    if (!props.ref_no)
        return null;

    return (
        // <div className='row'>
        //     <div className='col-md-6'>
        //         <div className="form-group">
        //             <label htmlFor="exampleInputFile">Road Inspection Certificate</label>
        //             <div>
        //                 <a href={ric} target='_blank'>{ric}</a> - <button className='btn btn-xs btn-outline-danger'><i class="far fa-trash-alt"></i></button> 
        //             </div>
        //         </div>
        //     </div>
        //     <div className='col-md-6'>
        //         <div className="form-group">
        //             <label htmlFor="exampleInputFile">Authorization Letter</label>
        //             <div>
        //                 <button className='btn btn-xs btn-outline-danger'><span aria-hidden="true">&times;</span></button> <a href={al}>{al}</a>
        //             </div>
        //         </div>
        //     </div>
        //     <div className='col-md-6'>
        //         <div className="form-group">
        //             <label htmlFor="exampleInputFile">Recycle Ticket</label>
        //             <div>
        //                 <a href={rt}>{rt}</a>
        //             </div>
        //         </div>
        //     </div>
        //     <div className='col-md-6'>
        //         <div className="form-group">
        //             <label htmlFor="exampleInputFile">Company Automobile liability Insurance</label>
        //             <div>
        //                 <a href={cali}>{cali}</a>
        //             </div>
        //         </div>
        //     </div>
        //     <div className='col-md-6'>
        //         <div className="form-group">
        //             <label htmlFor="exampleInputFile">Seal Certificate</label>
        //             <div>
        //                 <a href={sc}>{sc}</a>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <div>
            <ul class="mailbox-attachments d-flex align-items-stretch clearfix">
                { documentList.map((item, index) => {
                    let name = 'Road Inspection Certif...';
                    if (item.type == 1)
                        name = 'Road Inspection Certif...';
                    else if (item.type == 2) {
                        name = 'Authorization Letter';
                    }
                    else if (item.type == 3) {
                        name = 'Recycle Ticket';
                    }
                    else if (item.type == 4) {
                        name = 'Company Automobile liability Insurance';
                    }
                    else if (item.type == 5) {
                        name = 'Seal Certificate';
                    }

                    return(
                        <li>
                            <span class="mailbox-attachment-icon"><i class="far fa-file-pdf"></i></span>

                            <div class="mailbox-attachment-info">
                                <a href={ric} class="mailbox-attachment-name "> {name}</a>
                                <span class="mailbox-attachment-size clearfix mt-1">
                                    <span>1,245 KB</span>
                                    <a href="" class="btn btn-default btn-sm float-right" onClick={ e => handleDelete(e, item.id) }><i class="far fa-trash-alt"></i></a>
                                </span>
                            </div>
                        </li>
                    )
                })}
                
            </ul>
        </div>
    )
}
