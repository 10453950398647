import React, { useEffect, useState } from 'react'
import CrudService from '../../util/crudService';
import { useLocation, useNavigate } from 'react-router-dom';
import { CostModal } from './costModal';

export const Cost = (props) => {
    const locationHook = useLocation();
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [value, setValue] = useState('');
    const [costList, setCostList] = useState([]);

    const [refresh, setRefresh] = useState(false);

    const viewOnly = (props.viewOnly) ? props.viewOnly : false;
    // const handleAdd = (e) => {
    //     e.preventDefault();

    //     CrudService.PostData(navigate, locationHook, 'cost', 
    //     { 
    //         ref_no: props.refNo,
    //         name: name, 
    //         value: value
    //     })
    //     .then((res) => {
    //         fetchData();
    //     })
    //     .catch((err) => {
    //         console.error(err);
    //     })
    // }

    useEffect(() => {
        fetchData();
        setRefresh(false);
    }, [refresh])

    const fetchData = () => {
        CrudService.GetDataWithParam(navigate, locationHook, 'cost/all', {ref_no: props.refNo})
        .then((res) => {
            setCostList(res.data);
        })
        .catch((err) => {
            console.error(err);
        })
    } 

    const handleDelete = (id) => {

        if (window.confirm('Are you sure want to delete this Cost ?')){
            CrudService.DeleteData(navigate, locationHook, 'cost', id)
            .then((res) => {
                setRefresh(!refresh);
            })
            .catch((err) => {
                console.error(err);
            })
        }
    }
    return (
        <>
            <div className='row' hidden={viewOnly}>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <button className='btn btn-info btn-sm' data-toggle="modal" data-target="#modal-cost">Add New Cost</button>
                    </div>
                </div>
            </div>
            <CostModal refNo={props.refNo} refresh={setRefresh}/>

            <div className='row'>
                <div className="col-md-6">
                    <table className='table table-hover text-nowrap'>
                        <tr>
                            <th>Cost</th>
                            <th>Amount</th>
                            <th></th>
                        </tr>
                        <tbody>
                            { costList.map((item) => {
                                return(
                                    <tr>
                                        <td>{item.cost}</td>
                                        <td>{item.amount}</td>
                                        <td><button className='btn btn-xs btn-outline-danger' onClick={() => handleDelete(item.id)}><i class="far fa-trash-alt"></i></button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

