import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import CrudService from '../../util/crudService'
import { Cost } from './cost/cost';
import { Payment } from './payment/paymentModal'
//import SelectSearch from 'react-select-search';
//import 'react-select-search/style.css'

//import Select2 from 'react-select2';
//import 'react-select2/css/select2.css';

import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';

function Container() {
    const { t } = useTranslation();
    const [id, setId] = useState(0);
    const [price, setPrice] = useState(0);
    const [priceSale, setPriceSale] = useState(0);
    const [total, setTotal] = useState(0);
    //const [subTotal, setSubTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    //const [amountPaid, setAmountPaid] = useState(0);
    const [remainingAmount, setRemainingAmount] = useState(0);
    const [remark, setRemark] = useState('');

    const [listingData, setListingData] = useState([]);
    //const [customerData, setCustomerData] = useState([]);
    const [vendorId, setVendorId] = useState(0);
    //const [currency, setCurrency] = useState(0);
    const [type, setType] = useState(0);
    const [desc, setDesc] = useState('');
    const [itemType, setItemType] = useState(0);
    const [listingId, setListingId] = useState(0);
    const [qty, setQty] = useState(0);

    //Container
    const [salesOrderId, setSalesOrderId] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [containerTotal, setContainerTotal] = useState(0);
    const [currency, setCurrency] = useState(0);
    const [amountPaid, setAmountPaid] = useState(0);
    const [paidDate, setPaidDate] = useState(new Date());

    const [containerRef, setContainerRef] = useState('');
    const [containerNo, setContainerNo] = useState('');
    const [invoiceNo, setinvoiceNo] = useState('');
    const [descGoods, setdescGoods] = useState('');
    const [country, setCountry] = useState('');
    const [shipCompany, setShipCompany] = useState('');
    const [shipPort, setShipPort] = useState('');
    const [dischargePort, setDischargePort] = useState('');
    const [vesselName, setVesselName] = useState('');
    const [incoterm, setIncoterm] = useState('');
    const [orderDate, setOrderDate] = useState(new Date()); //new Date()
    const [toKagaDate, setToKagaDate] = useState(new Date());
    const [pickupDate, setPickupDate] = useState(new Date());
    const [shippingEtd, setShippingEtd] = useState(new Date());
    const [shippingEta, setShippingEta] = useState(new Date());
    const [status, setStatus] = useState('');

    const [vendorList, setVendorList] = useState([]);
    const [costList, setCostList] = useState([]);
    const [detailList, setDetailList] = useState([]);
    const [exrate, setExrate] = useState(() => FetchExchangeRate());

    const [lockCurrency, setLockCurrency] = useState(false);

    const [totalCost_, setTotalCost_] = useState(0);

    const [item, setItem] = useState([]);
    
    const [refresh, setRefresh] = useState(0);

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
 

    function FetchExchangeRate() {
        axios.get(`https://openexchangerates.org/api/latest.json?app_id=955708c18bae414d9f818c87d3364969&base=JPY&symbols=USD`)
        .then((res) => {
            console.log(res.data.rates.USD);
            setExrate(res.data.rates.USD);
            return res.data.rates.USD;
        })
        .catch((err) => console.error(err))
    }

    //When mount
    useEffect(() => {

        //Sales Order List
        CrudService.GetData(navigate, location, 'sale/basic') //, 0, 500
        .then((res) => {
            setListingData(res.data.data);
        })
        .catch((err) => console.error(err))

    }, [])

    //If Ref Id exists
    useEffect(() => {

        if (params.id) {
            setId(params.id);
            //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/listing/${params.id}`)
            CrudService.GetDataById(navigate, location, 'container/detail', params.id)
            .then((res) => {
                console.log(res.data);
                setDetailList(res.data.detail);
                
                setContainerRef(res.data.order.container_ref);
                setContainerNo(res.data.order.container_no);
                setinvoiceNo(res.data.order.invoice_no);
                setStatus(res.data.order.status);
                setRemark(res.data.order.remark);
                setdescGoods(res.data.order.desc_goods);
                setCountry(res.data.order.country);
                setShipCompany(res.data.order.ship_company);
                setShipPort(res.data.order.ship_port);
                setDischargePort(res.data.order.discharge_port);
                setVesselName(res.data.order.vessel_name);
                setIncoterm(res.data.order.incoterm);
                setOrderDate(new Date(res.data.order.order_date));
                setToKagaDate(new Date(res.data.order.to_kaga_date));
                setPickupDate(new Date(res.data.order.pickup_date));
                setShippingEtd(new Date(res.data.order.shipping_etd));
                setShippingEta(new Date(res.data.order.shipping_eta));
                setDiscount(res.data.order.discount);
                setAmountPaid(res.data.order.amount_paid);
                setPaidDate(new Date(res.data.order.paid_date));

                //setItemType(1);
                setListingId(res.data.id);
                //setQty(1);

                // if (listingId != 0){
                //     handleDetailAdd();
                // }

                let temp = res.data.detail;
                setContainerTotal(0);
                temp.map((item) => setContainerTotal((value) => (parseFloat(value) || 0) + parseFloat(item.SalesOrder.sub_total)));

            })
            .catch((err) => {
                alert(err)
            })
        }

        
    }, [params.id])

    //When Sales Order changes
    useEffect(() => {

        if (salesOrderId != 0) {
            CrudService.GetDataById(navigate, location, 'sale', salesOrderId)
            .then((res) => {
                
                console.log('Currency :' + res.data.currency);
                console.log('Sub Total :' + res.data.sub_total);
                
                // if (res.data.currency == 2) {
                //     console.log('exrate:' + exrate);
                //     //setSubTotal((parseFloat(res.data.sub_total) * parseFloat(exrate)).toFixed(2));
                // } else {
                //     null;
                // }

                if (currency != 0 && currency != res.data.currency) {
                    alert('Currency of the selected Sales Order does not match.')
                    return;
                } else {
                    setCurrency(res.data.currency);
                }

                setSubTotal(res.data.sub_total);
            })
            .catch((err) => {
                console.error(err)
                alert(err)
            })
        }
        
    }, [salesOrderId])


    const handleDetailAdd = () => {
        
        const data = {
            id: 0,
            sales_order_id: salesOrderId,
            sub_total: subTotal
        }
        
        if (salesOrderId == 0) //if (!validateDetailData(data))
        {
            alert('Invalid Data');
            return;
        }

        if (detailList.some(e => e.sales_order_id === data.sales_order_id)) {
            alert('Sales Order is alredy exists!');
            return;
        }

        let data_ = [...detailList, data];
        setDetailList(data_);

        setContainerTotal(parseFloat(containerTotal) + parseFloat(data.sub_total));
        resetFields('ALL');
        //setLockCurrency(true);
    }

    //Calculate Sub Total when cost list change
    useEffect(() => {
        let totoalCost = 0;
        let costCount = 0;
        
        costList.map((item, index) => {
            totoalCost += parseFloat(item.value);
            costCount = index;
            console.log('Total Cost : ' + totoalCost);

        })

        //---
        setTotalCost_(totoalCost);
    }, [costList])

    //Delete Detail line
    const handleDetailDelete = (index_) => {

        console.log('Line Tot :' + detailList[index_]?.sub_total);
        const line_total = parseFloat(detailList[index_]?.sub_total) || parseFloat(detailList[index_]?.SalesOrder?.sub_total);

        setDetailList((detailList) =>
            detailList.filter((item, index) => index !== index_)
        );

        console.log('Length:' + detailList.length)
        if (detailList.length <= 1){
            setLockCurrency(false);
        }

        console.log('containerTotal :' + parseFloat(containerTotal));
        console.log('index :' + index_);
        console.log('detailList C :' + line_total);

        //setContainerTotal(parseFloat(containerTotal) - (parseFloat(detailList[index_]?.sub_total) || 0));
        setContainerTotal(parseFloat(containerTotal) - line_total);
        //setDiscount(0);
    }

    //ViewSO
    const viewSalesOrder = (id_) => {
        navigate(`${process.env.PUBLIC_URL}/sales-order-view/147`);
    }

    function validateDetailData(data) {
        if (data.type == 0)
            return false;
        else if (data.type == 1 && data.ref == 0)
            return false;
        else if (data.price == 0 )
            return false;
        
        return true;
    }

    const resetFields = (option_) => {
        if (option_ == 'ALL')
            setItemType(0);
        
        setDesc('');
        setListingId(0);
        setQty(0);
        setPrice(0);
    }

    const validateData = () => {
        return (detailList.length == 0) ? false : true;
    }

    //Submit Order
    const handleSubmit = (event) => {
        event.preventDefault();

        if (!validateData()) {
            alert('Please fill the required field!');
            return;
        }

        if (id == null || id == 0){
            CrudService.PostData(navigate, location, 'container', 
            { 
                containerRef, containerNo, invoiceNo, descGoods, country, shipCompany, shipPort, dischargePort, 
                vesselName, incoterm, orderDate, toKagaDate, pickupDate, shippingEtd, shippingEta, status, remark,
                detailList, costList, discount, totalCost_, discount, amountPaid, paidDate
            })
            .then((response) => {
                console.log(response.data);
                navigate(`${process.env.PUBLIC_URL}/container-list`);
            })
            .catch((error) => {
                console.log(error);
            });
        }else{
            CrudService.PutData(navigate, location, 'container', id,
            { 
                containerRef, containerNo, invoiceNo, descGoods, country, shipCompany, shipPort, dischargePort, 
                vesselName, incoterm, orderDate, toKagaDate, pickupDate, shippingEtd, shippingEta, status, remark,
                detailList, costList, discount, totalCost_, discount, amountPaid, paidDate
            })
            .then((response) => {
                console.log(response.data);
                navigate(`${process.env.PUBLIC_URL}/container-list`);
            })
            .catch((error) => {
                console.log(error);
            });
        }

    };

    const calDiscount = (value_) => {
        if (total > 0)
            setSubTotal((parseInt(subTotal)) - parseFloat(value_));
        else
            setSubTotal(0);
    }

    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1><NavLink to="/container-list" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; Container</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
                                <li className="breadcrumb-item active">Container</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        {/* --------- */}
                        <div class="col-12">
                            <div class="card card-primary card-outline card-outline-tabs">
                                <div class="card-header p-0 border-bottom-0">
                                    <ul class="nav nav-tabs" id="custom-tabs-four-tab-1" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link active" id="custom-tabs-four-1-home-tab" data-toggle="pill"
                                                href="#custom-tabs-four-1-home" role="tab" aria-controls="custom-tabs-four-1-home"
                                                aria-selected="true">{t('container-page.basic-info')}</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="custom-tabs-four-1-misc-tab" data-toggle="pill"
                                                href="#custom-tabs-four-1-misc" role="tab" aria-controls="custom-tabs-four-1-misc"
                                                aria-selected="false">{t('container-page.other-info')}</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="card-body">
                                    <div class="tab-content" id="custom-tabs-four-tab-1Content">
                                        <div class="tab-pane fade show active" id="custom-tabs-four-1-home" role="tabpanel"
                                            aria-labelledby="custom-tabs-four-1-home-tab">
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">{t('container-page.container-ref')}</label>
                                                            <input type="text" className="form-control" id="title" placeholder="" value={containerRef} onChange={(e) => { setContainerRef(e.target.value);} } />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">{t('container-page.container-no')}</label>
                                                            <input type="text" className="form-control" id="title" placeholder="" value={containerNo} onChange={(e) => { setContainerNo(e.target.value);} } />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">{t('container-page.invoice-no')}</label>
                                                            <input type="text" className="form-control" id="title" placeholder="" value={invoiceNo} onChange={(e) => { setinvoiceNo(e.target.value);} } />
                                                        </div>
                                                    </div>

                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">Status</label>
                                                            <select class="form-control" id="modelCode" value={status} onChange={(e) => { setStatus(e.target.value); }}>
                                                                <option value='0'>-- Select Status --</option>
                                                                <option value='1'>Preparing</option>
                                                                <option value='2'>In-Transit</option>
                                                                <option value='3'>Completed</option>
                                                                
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            {/* ?.toDateString() */}
                                                            <label htmlFor="title">{t('container-page.order-date')}</label> <br></br>
                                                            {/* <input type="date" className="form-control" id="orderDate" placeholder={orderDate} value={orderDate || ''} onChange={(e) => { setOrderDate(e.target.value); } } /> */}
                                                            <DatePicker className="form-control" selected={orderDate} onChange={(date) => setOrderDate(date)} dateFormat="dd/MM/yyyy" />
                                                        </div>
                                                    </div>

                                                    <div className='col-md-12'>
                                                        <div className="form-group">
                                                            <label htmlFor="slug">{t('container-page.note')}</label>
                                                            <textarea type="text" className="form-control" id="desc" placeholder="" value={remark} onChange={(e) => setRemark(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>

                                        <div class="tab-pane fade" id="custom-tabs-four-1-misc" role="tabpanel"
                                            aria-labelledby="custom-tabs-four-1-misc-tab">
                                                <div className='row'>
                                                    
                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">{t('container-page.goods-description')}</label>
                                                            <input type="text" className="form-control" id="title" placeholder="" value={descGoods} onChange={(e) => { setdescGoods(e.target.value);} } />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">{t('container-page.country')}</label>
                                                            <input type="text" className="form-control" id="title" placeholder="" value={country} onChange={(e) => { setCountry(e.target.value);} } />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">{t('container-page.ship-company')}</label>
                                                            <input type="text" className="form-control" id="title" placeholder="" value={shipCompany} onChange={(e) => { setShipCompany(e.target.value);} } />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">{t('container-page.ship-port')}</label>
                                                            <input type="text" className="form-control" id="title" placeholder="" value={shipPort} onChange={(e) => { setShipPort(e.target.value);} } />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">{t('container-page.discharge-port')}</label>
                                                            <input type="text" className="form-control" id="title" placeholder="" value={dischargePort} onChange={(e) => { setDischargePort(e.target.value);} } />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">{t('container-page.vessel-name')}</label>
                                                            <input type="text" className="form-control" id="title" placeholder="" value={vesselName} onChange={(e) => { setVesselName(e.target.value);} } />
                                                        </div>
                                                    </div>
                                                    {/* <div className='col-md-4'>
                                                        <div className="form-group">
                                                        <label htmlFor="title">sub_total</label>
                                                        <input type="text" className="form-control" id="title" placeholder="" value={item.desc} onChange={(e) => { item.desc = e.target.value;} } />
                                                        </div>
                                                    </div> */}
                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">{t('container-page.incoterm')}</label>
                                                            <select class="form-control" id="modelCode" value={incoterm} onChange={(e) => { setIncoterm(e.target.value); }}>
                                                                <option value='0'>-- Select {t('container-page.incoterm')} --</option>
                                                                <option value='1'>FOB</option>
                                                                <option value='2'>C&F</option>
                                                                <option value='2'>CIF</option>
                                                                
                                                            </select>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                    <hr />
                                                    {/* <div className='col-md-2'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">Order Date - {orderDate.toDateString()}</label>
                                                            <input type="date" className="form-control" id="title" placeholder="" value={orderDate.toDateString()} onChange={(e) => { setOrderDate(e.target.value); } } />
                                                        </div>
                                                    </div> */}
                                                    <div className='col-md-2'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">{t('container-page.to-kaga')}</label> <br></br>
                                                            {/* <input type="date" className="form-control" id="title" placeholder="" value={toKagaDate} onChange={(e) => { setToKagaDate(e.target.value); } } /> */}
                                                            <DatePicker className="form-control" selected={toKagaDate} onChange={(date) => setToKagaDate(date)} dateFormat="dd/MM/yyyy" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">{t('container-page.pickup-date')}</label>
                                                            {/* <input type="date" className="form-control" id="title" placeholder="" value={pickupDate} onChange={(e) => {  setPickupDate(e.target.value);} } /> */}
                                                            <DatePicker className="form-control" selected={pickupDate} onChange={(date) => setPickupDate(date)} dateFormat="dd/MM/yyyy" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">{t('container-page.shipping-etd')}</label>
                                                            {/* <input type="date" className="form-control" id="title" placeholder="" value={shippingEtd} onChange={(e) => { setShippingEtd(e.target.value); } } /> */}
                                                            <DatePicker className="form-control" selected={shippingEtd} onChange={(date) => setShippingEtd(date)} dateFormat="dd/MM/yyyy" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">{t('container-page.shipping-eta')}</label>
                                                            {/* <input type="date" className="form-control" id="title" placeholder={shippingEta} value={shippingEta} defaultValue={shippingEta} onChange={(e) => { setShippingEta(e.target.value); } } /> */}
                                                            <DatePicker className="form-control" selected={shippingEta} onChange={(date) => setShippingEta(date)} dateFormat="dd/MM/yyyy" />
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* --------- */}
                        <div className='col-md-12' hidden>
                            <div className="card card-primary">
                                <div className="card-body">
                                    {/* <div className="row">
                                        <div className='col-md-12'>
                                            <h5>Container</h5>
                                        </div>
                                    </div> */}
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">Container Ref</label>
                                                <input type="text" className="form-control" id="title" placeholder="" value={containerRef} onChange={(e) => { setContainerRef(e.target.value);} } />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">Container No</label>
                                                <input type="text" className="form-control" id="title" placeholder="" value={containerNo} onChange={(e) => { setContainerNo(e.target.value);} } />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">Invoice No</label>
                                                <input type="text" className="form-control" id="title" placeholder="" value={invoiceNo} onChange={(e) => { setinvoiceNo(e.target.value);} } />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">Goods Description</label>
                                                <input type="text" className="form-control" id="title" placeholder="" value={descGoods} onChange={(e) => { setdescGoods(e.target.value);} } />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">Country</label>
                                                <input type="text" className="form-control" id="title" placeholder="" value={country} onChange={(e) => { setCountry(e.target.value);} } />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">Ship Company</label>
                                                <input type="text" className="form-control" id="title" placeholder="" value={shipCompany} onChange={(e) => { setShipCompany(e.target.value);} } />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">Ship Port</label>
                                                <input type="text" className="form-control" id="title" placeholder="" value={shipPort} onChange={(e) => { setShipPort(e.target.value);} } />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">Discharge Port</label>
                                                <input type="text" className="form-control" id="title" placeholder="" value={dischargePort} onChange={(e) => { setDischargePort(e.target.value);} } />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">Vessel Name</label>
                                                <input type="text" className="form-control" id="title" placeholder="" value={vesselName} onChange={(e) => { setVesselName(e.target.value);} } />
                                            </div>
                                        </div>
                                        {/* <div className='col-md-4'>
                                            <div className="form-group">
                                            <label htmlFor="title">sub_total</label>
                                            <input type="text" className="form-control" id="title" placeholder="" value={item.desc} onChange={(e) => { item.desc = e.target.value;} } />
                                            </div>
                                        </div> */}
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">Incoterm</label>
                                                <select class="form-control" id="modelCode" value={incoterm} onChange={(e) => { setIncoterm(e.target.value); }}>
                                                    <option value='0'>-- Select Type --</option>
                                                    <option value='1'>FOB</option>
                                                    <option value='2'>C&F</option>
                                                    <option value='2'>CIF</option>
                                                    
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">Status</label>
                                                <select class="form-control" id="modelCode" value={status} onChange={(e) => { setStatus(e.target.value); }}>
                                                    <option value='0'>-- Select Type --</option>
                                                    <option value='1'>Preparing</option>
                                                    <option value='2'>In-Transit</option>
                                                    <option value='2'>Completed</option>
                                                    
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='col-md-2'>
                                            <div className="form-group">
                                                <label htmlFor="title">Order Date</label>
                                                <input type="date" className="form-control" id="title" placeholder="" value={orderDate} onChange={(e) => { setOrderDate(e.target.value); } } />
                                            </div>
                                        </div>
                                        <div className='col-md-2'>
                                            <div className="form-group">
                                                <label htmlFor="title">To Kaga Date</label>
                                                <input type="date" className="form-control" id="title" placeholder="" value={toKagaDate} onChange={(e) => { setToKagaDate(e.target.value); } } />
                                            </div>
                                        </div>
                                        {/* <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">order_date</label>
                                                <input type="date" className="form-control" id="title" placeholder="" value={orderDate} onChange={(e) => { item.desc = e.target.value;} } />
                                            </div>
                                        </div> */}
                                        <div className='col-md-2'>
                                            <div className="form-group">
                                                <label htmlFor="title">Pickup Date</label>
                                                <input type="date" className="form-control" id="title" placeholder="" value={pickupDate} onChange={(e) => {  setPickupDate(e.target.value);} } />
                                            </div>
                                        </div>
                                        <div className='col-md-2'>
                                            <div className="form-group">
                                                <label htmlFor="title">Shipping ETD</label>
                                                <input type="date" className="form-control" id="title" placeholder="" value={shippingEtd} onChange={(e) => { setShippingEtd(e.target.value); } } />
                                            </div>
                                        </div>
                                        <div className='col-md-2'>
                                            <div className="form-group">
                                                <label htmlFor="title">Shipping ETA</label>
                                                <input type="date" className="form-control" id="title" placeholder="" value={shippingEta} onChange={(e) => { setShippingEta(e.target.value); } } />
                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <div className="form-group">
                                                <label htmlFor="slug">Note</label>
                                                <textarea type="text" className="form-control" id="desc" placeholder="" value={remark} onChange={(e) => setRemark(e.target.value)}/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-8'>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <div className='row'>
                                        <table className='col-12'>
                                            <tr>
                                                <th>{t('container-page.ref')}</th>
                                                <th></th>
                                            </tr>
                                            <tbody>
                                                <tr>
                                                    <td width={'70%'}>
                                                        <Select options={listingData.map((item, index) => ({ value: item.id, label: item.id + ' - ' + item.Vendor?.name + ' - ' + item.remark.substring(0, 30) + "..." }))} onChange={(e) => { console.log('e :' + e.value); setSalesOrderId(e.value) }} />
                                                    </td>
                                                    
                                                    <td>
                                                        <button className='btn btn-success' onClick={handleDetailAdd}> <i class="fas fa-plus"></i></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><hr></hr></td>
                                                    <td><hr></hr></td>
                                                    {/* <td><hr></hr></td> */}
                                                    {/* <td><hr></hr></td> */}
                                                    {/* <td><hr></hr></td> */}
                                                    
                                                </tr>
                                                {
                                                    detailList?.map((item, index) => {
                                                        return(
                                                            <tr>
                                                                <td>
                                                                    {/* <p>{item.sub_total}</p> */}
                                                                    <select class="form-control readonly" id="refNo" value={item.sales_order_id} readOnly> 
                                                                        {/* <option value='0'>-- Select Ref --</option> */}
                                                                        {
                                                                            listingData?.map((item_, index) => {
                                                                                if (item_.id == item.sales_order_id)
                                                                                    return (
                                                                                        <option value={item_.id} key={index}>{item_.id} | {item_.Vendor.name} | { (item_.currency == 1) ? '¥' + '' + item_.sub_total : '$' + ' ' + item_.sub_total } | {item_.remark}</option>
                                                                                    )
                                                                        })}
                                                                    </select>
                                                                </td>   
                                                                <td style={{ paddingLeft: '10px' }}>
                                                                    <div className='row'>
                                                                        <div className='col-md-2'>
                                                                            <NavLink className={'btn btn-outline-warning btn-sm'} exact to={`/sales-order-view/${item.sales_order_id}`} target='_blank'>
                                                                                <i class="fa fa-info-circle" aria-hidden="true"></i>
                                                                            </NavLink>
                                                                        </div>
                                                                        
                                                                        <div className='col-md-2'>
                                                                            <button className='btn btn-outline-danger btn-sm' onClick={() => handleDetailDelete(index)}><i class="fas fa-trash"></i></button>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        
                                    </div>
                                    {/* --- */}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <Cost costList={setCostList} data={costList} refresh={refresh} orderId={params.id} t={t} />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    {/* /.row */}
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <div className='row'>
                                        
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">{t('container-page.sub-total')}</label>
                                                <input type="text" className="form-control" id="title" placeholder="" value={(containerTotal + totalCost_).toFixed(2)} readOnly />
                                            </div>
                                        </div>

                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">{t('container-page.discount')}</label>
                                                <input type="text" className="form-control" id="title" placeholder="" onChange={(e) => setDiscount(e.target.value)} value={discount} />
                                            </div>
                                        </div>

                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">{t('container-page.final')}</label>
                                                <input type="text" className="form-control" id="title" placeholder="" value={((containerTotal + totalCost_) - discount).toFixed(2)} readOnly/>
                                            </div>
                                        </div>

                                        {/* <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="title">Paid</label>
                                                <input type="text" className="form-control" id="amountPaid" placeholder="" value={(amountPaid) ? (amountPaid) : 0} onChange={ e => setAmountPaid(e.target.value)} />
                                            </div>
                                        </div> */}
                                        
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">{t('container-page.paid')}</label>
                                                <input type="text" className="form-control" id="amountPaid" placeholder="" value={(amountPaid) ? (amountPaid) : 0} onChange={ e => setAmountPaid(e.target.value)} />
                                            </div>
                                        </div>

                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">{t('container-page.paid-date')}</label><br></br>
                                                {/* <input type="date" className="form-control" id="paidDate" placeholder="" value={paidDate} onChange={ e => setPaidDate(e.target.value)} /> */}
                                                <DatePicker className="form-control" selected={paidDate} onChange={(date) => setPaidDate(date)} dateFormat="dd/MM/yyyy" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                        <div className='col-md-6'>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <Payment orderNo={params.id} t={t} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <button type="submit" className="btn btn-success btn-block" onClick={handleSubmit}><i class="fa fa-check-circle" aria-hidden="true"></i>&nbsp;&nbsp;{t('order-pages.proceed')}</button>

                            </div>
                        </div>
                    </div>

                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>

    )
}

export default Container