import React from 'react'
import Placeholder from '../components/home/placeholder';
import SideNav from '../components/home/sideNav';
import Footer from '../components/home/footer';
import AddMake from '../components/makes/addMake';

export const AddMakes = () => (
    <div class="wrapper">
        <Placeholder />
        <SideNav />
        <AddMake />
        <Footer />
    </div>
);