import axios from 'axios';
//import { useLocalStorage } from "../hooks/useLocalStorage";

const userLocal_ = JSON.parse(window.localStorage.getItem('user'));

const CustomHeader_ = () => {
    const userLocal = JSON.parse(window.localStorage.getItem('user'));
    //const { auth } = useAuth();
    
    //console.error('Auth CusHeader:' + auth?.accessToken);
    //console.log('Auth CusHeader local:' + userLocal.accessToken)

    const customHeader =  { 
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "*",
        'x-access-token': ( (userLocal.accessToken) ? userLocal.accessToken : null ),
    };

    //auth?.accessToken ? auth?.accessToken : ( (userLocal.accessToken) ? userLocal.accessToken : auth?.accessToken ),
    return customHeader;
}

const CustomHeader2_ = () => {
    const userLocal = JSON.parse(window.localStorage.getItem('user'));

    const customHeader =  { 
        'Content-Type': "multipart/form-data",
        'Access-Control-Allow-Origin': "*",
        'x-access-token': ( (userLocal.accessToken) ? userLocal.accessToken : null ),
    };
    return customHeader;
}


const GetData = async (navigate, location, endpoint, page = 1, limit = 10) => {
    //console.log('userLocal.accessToken:' + userLocal.accessToken);
    //const [userLocal, setUserLocal] = useLocalStorage('user');
    
    //console.log('AT:' + userLocal.accessToken)

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/${endpoint}?page=${page}&pageSize=${limit}`,
        {
            headers: CustomHeader_()
        });

        return response;
        
    } catch (err) {
        if (!err?.response) {
            throw new Error('No Server Response');
        } else if (err.response?.status === 400) {
            //throw new Error('Error Occoured :' + err?.response);
            //setErrMsg('Missing Username or Password');
            navigate(`${process.env.PUBLIC_URL}/login`, { replace: true });
        } else if (err.response?.status === 401 || err.response?.status === 403) {
            console.error('Auth Error:' + err.response?.status)
            window.localStorage.removeItem('user');
            navigate(`${process.env.PUBLIC_URL}/login`, { state: { from: location }, replace: true });
        } else {
            throw new Error('Error Occoured :' + (err.response) ? err?.response : err);
        }
    }


}

const GetDataById = async (navigate, location, endpoint, id) => {

    //const [userLocal, setUserLocal] = useLocalStorage('user');

    console.log('In GetDataById');

    const userLocal = JSON.parse(window.localStorage.getItem('user'));
    console.log('AT : ' + userLocal.accessToken)

    console.log('customHeader : ' + CustomHeader_) 

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/${endpoint}/${id}`, 
        {
            headers: CustomHeader_()
        });

        return response;
        
    } catch (err) {
        if (!err?.response) {
            throw new Error('No Server Response');
        } else if (err.response?.status === 400) {
            navigate(`${process.env.PUBLIC_URL}/login`, { replace: true });
        } else if (err.response?.status === 401 || err.response?.status === 403) {
            navigate(`${process.env.PUBLIC_URL}/login`, { state: { from: location }, replace: true });
        } else {
            console.log(err);
            throw new Error('Error Occoured :' + err?.response.data);
        }
    }
}

const GetDataWithParam = async (navigate, location, endpoint, params) => {

    console.log('In GetDataWithParam');

    const userLocal = JSON.parse(window.localStorage.getItem('user'));

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/${endpoint}`, 
        {
            headers: CustomHeader_(),
            params: params
        });

        return response;
        
    } catch (err) {
        if (!err?.response) {
            throw new Error('No Server Response');
        } else if (err.response?.status === 400) {
            navigate(`${process.env.PUBLIC_URL}/login`, { replace: true });
        } else if (err.response?.status === 401 || err.response?.status === 403) {
            navigate(`${process.env.PUBLIC_URL}/login`, { state: { from: location }, replace: true });
        } else {
            console.log(err);
            throw new Error('Error Occoured :' + err?.response.data);
        }
    }


}

const GetFileData = async (navigate, location, endpoint, params) => {

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/${endpoint}`, {
            headers: CustomHeader_(),
            responseType: 'arraybuffer',
            params: params,
        });
    
        return response;
        
    } catch (err) {
        if (!err?.response) {
            throw new Error('No Server Response');
        } else if (err.response?.status === 400) {
            navigate(`${process.env.PUBLIC_URL}/login`, { replace: true });
        } else if (err.response?.status === 401 || err.response?.status === 403) {
            console.error('Auth Error:' + err.response?.status)
            window.localStorage.removeItem('user');
            navigate(`${process.env.PUBLIC_URL}/login`, { state: { from: location }, replace: true });
        } else {
            throw new Error('Error Occoured :' + (err.response) ? err?.response : err);
        }
    }
}

const PostData = async (navigate, location, endpoint, payload) => {

    try {

        if (userLocal_?.userId){
            payload.created_by = userLocal_.userId;
        }

        console.log('payload.created_by :' + payload.created_by);

        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/api/admin/${endpoint}`, payload,
        {
            headers: CustomHeader_()
        });

        return response;

    } catch (err) {
        if (!err?.response) {
            throw new Error('No Server Response');
        } else if (err.response?.status === 400) {
            throw new Error(err?.response);
            //navigate(`${process.env.PUBLIC_URL}/login`, { replace: true });
        } else if (err.response?.status === 401) {
            navigate(`${process.env.PUBLIC_URL}/login`, { state: { from: location }, replace: true });
        } else {
            throw new Error('Error Occoured :' + err?.response);
        }
    }
}

const PostFileData = async (navigate, location, endpoint, payload) => {

    try {
        // axios({
        //     method: "POST",
        //     url: `${process.env.REACT_APP_API_END_POINT}/api/admin/docman/upload`,
        //     headers: {
        //         "Content-Type": "multipart/form-data",
        //     },
        //     data: formData,
        // })

        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/api/admin/${endpoint}`, payload,
        {
            headers: CustomHeader2_()
        });

        return response;

    } catch (err) {
        if (!err?.response) {
            throw new Error('No Server Response');
        } else if (err.response?.status === 400) {
            throw new Error(err?.response);
            //navigate(`${process.env.PUBLIC_URL}/login`, { replace: true });
        } else if (err.response?.status === 401) {
            navigate(`${process.env.PUBLIC_URL}/login`, { state: { from: location }, replace: true });
        } else {
            throw new Error('Error Occoured :' + err?.response);
        }
    }
}

const PutData = async (navigate, location, endpoint, id, payload) => {
    
    try {
        if (userLocal_?.userId){
            payload.updated_by = userLocal_?.userId;
        }

        console.log('payload.updated_by :' + payload.updated_by);

        const response = await axios.put(`${process.env.REACT_APP_API_END_POINT}/api/admin/${endpoint}/${id}`, payload,
        {
            headers: CustomHeader_()
        });

        return response;
    } catch (err) {
        if (!err?.response) {
            throw new Error('No Server Response ' + err);
        } else if (err.response?.status === 400) {
            throw new Error(err.response.data);
            //navigate(`${process.env.PUBLIC_URL}/login`, { replace: true });
        } else if (err.response?.status === 401) {
            navigate(`${process.env.PUBLIC_URL}/login`, { state: { from: location }, replace: true });
        } else {
            throw new Error('Error Occoured :' + err?.response.data);
        } 
    }
}

const DeleteData = async (navigate, location, endpoint, id, param) => {
    
    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_END_POINT}/api/admin/${endpoint}/${id}`,
        {
            headers: CustomHeader_(),
            params: param
        });

        return response;
    } catch (err) {
        if (!err?.response) {
            throw new Error('No Server Response');
        } else if (err.response?.status === 400) {
            throw new Error(err.response.data);
        } else if (err.response?.status === 401) {
            navigate(`${process.env.PUBLIC_URL}/login`, { state: { from: location }, replace: true });
        } else {
            throw new Error('Error Occoured :' + err?.response.data);
        } 
    }
}

const CrudService = {
    GetData,
    GetDataById,
    GetDataWithParam,
    GetFileData,
    PostData,
    PostFileData,
    PutData,
    DeleteData
  };
  
  export default CrudService;