import React from 'react'
import Placeholder from '../components/home/placeholder';
import SideNav from '../components/home/sideNav';
import Footer from '../components/home/footer';
import ViewListing2 from '../components/listing/viewListing2';

export const ViewListingPage = () => (
    <div class="wrapper">
        <Placeholder />
        <SideNav />
        <ViewListing2 />
        <Footer />
    </div>
);