import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import CrudService from '../../util/crudService'
import { Cost } from './cost';
//import SelectSearch from 'react-select-search';
//import 'react-select-search/style.css'

//import Select2 from 'react-select2';
//import 'react-select2/css/select2.css';

import Select from 'react-select'


function SalesOrderAmend() {
    const [id, setId] = useState(0);
    const [price, setPrice] = useState(0);
    const [priceSale, setPriceSale] = useState(0);
    const [total, setTotal] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [amountPaid, setAmountPaid] = useState(0);
    const [remainingAmount, setRemainingAmount] = useState(0);
    const [remark, setRemark] = useState('');

    const [listingData, setListingData] = useState([]);
    //const [customerData, setCustomerData] = useState([]);
    const [vendorId, setVendorId] = useState(0);
    const [currency, setCurrency] = useState(0);
    const [type, setType] = useState(0);
    const [desc, setDesc] = useState('');
    const [itemType, setItemType] = useState(0);
    const [listingId, setListingId] = useState(0);
    const [qty, setQty] = useState(0);

    const [vendorList, setVendorList] = useState([]);
    const [costList, setCostList] = useState([]);
    const [detailList, setDetailList] = useState([]);
    const [exrate, setExrate] = useState(() => FetchExchangeRate());

    const [lockCurrency, setLockCurrency] = useState(false);
    const [totalCost_, setTotalCost_] = useState(0);

    const [orderData, setOrderData] = useState([]);
    const [orderDeailData, setOrderDetailData] = useState([]);
    const [costData, setCostData] = useState([]);
    const [vendorData, setVendorData] = useState([]);

    const [refresh, setRefresh] = useState(0);
    
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
 
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]

    function FetchExchangeRate() {
        //axios.get(`https://openexchangerates.org/api/latest.json?app_id=e49e070324e8444b855386876df2c682&symbols=JPY`)
        axios.get(`https://openexchangerates.org/api/latest.json?app_id=955708c18bae414d9f818c87d3364969&base=JPY&symbols=USD`)
        .then((res) => {
            console.log(res.data);
            console.log(res.data.rates);
            console.log(res.data.rates.USD);
            setExrate(res.data.rates.USD);
            return res.data.rates.USD;
        })
        .catch((err) => console.error(err))
    }

    //When mount
    useEffect(() => {
        //Vendor List
        //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/vendor?page=${0}&pageSize=${20}`)
        CrudService.GetData(navigate, location, 'vendor/basic')
        .then((res) => {
            setVendorList(res.data);


        })
        .catch((err) => console.error(err))

        //Listing List
        CrudService.GetData(navigate, location, 'listing/basic') //, 0, 500
        .then((res) => {
            setListingData(res.data.data);
        })
        .catch((err) => console.error(err))

    }, [])

    //If Ref Id exists
    useEffect(() => {

        if (params.id) {
            setId(params.id);

            try {
                CrudService.GetDataById(navigate, location, 'sale/invoice', params.id)
                .then((res) => {
                    console.log(res.data);
                    setOrderData(res.data.order);
                    setOrderDetailData(res.data.detail);
                    setCostData(res.data.cost);
                    setVendorData(res.data.vendor);
                });
                
            } catch (error) {
                console.error(error);
            }
        }
        
    }, [params.id])

    //When Ref changes
    useEffect(() => {

        if (listingId != 0) {
            CrudService.GetDataById(navigate, location, 'listing', listingId)
            .then((res) => {
                setDesc(res.data.title);

                if (orderData.currency == 2) {
                    //var exrate = '143.695'; // FetchExchangeRate();
                    console.log('exrate:' + exrate);
                    setPrice((parseFloat(res.data.price) * parseFloat(exrate)).toFixed(2));
                }else{
                    setPrice(res.data.price);
                }
                setPriceSale(res.data.price_sale);
            })
            .catch((err) => {
                alert(err)
            })
        }

        
    }, [listingId])


    const handleDetailAdd = () => {
        
        const data = {
            type: itemType,
            ref: listingId,
            desc: desc,
            qty: qty,
            price: price,
        }
        
        if (!validateDetailData(data))
        {
            alert('Invalid Data');
            return;
        }

        let data_ = [...detailList, data];
        setDetailList(data_);
        setTotal(parseFloat(total) + parseFloat(data.price));
        setSubTotal(parseFloat(subTotal) + parseFloat(data.price));
        resetFields('ALL');
        setLockCurrency(true);
    }

    //Calculate Sub Total when cost list change
    useEffect(() => {
        let totoalCost = 0;
        costList.map((item) => {
            totoalCost += parseFloat(item.value);
        })
        //setTotal(parseFloat(total) + parseFloat(totoalCost));
        //setSubTotal(parseFloat(subTotal) + parseFloat(totoalCost) )

        setTotalCost_(totoalCost);
        //calRemaining2(totoalCost);
    }, [costList])


    //Delete Detail line
    const handleDetailDelete = (index_) => {
        setDetailList((detailList) =>
            detailList.filter((item, index) => index !== index_)
        );

        console.log('Length:' + detailList.length)
        if (detailList.length <= 1){
            setLockCurrency(false);
        }
    }

    function validateDetailData(data) {
        if (data.type == 0)
            return false;
        else if (data.type == 1 && data.ref == 0)
            return false;
        else if (data.price == 0 )
            return false;
        
        return true;
    }

    const resetFields = (option_) => {
        if (option_ == 'ALL')
            setItemType(0);
        
        setDesc('');
        setListingId(0);
        setQty(0);
        setPrice(0);
    }

    const validateData = () => {
        return (id == 0) ? false : true;
    }

    //Submit Order
    const handleSubmit = (event) => {
        event.preventDefault();

        if (!validateData()) {
            alert('Please fill the required field!');
            return;
        }

        CrudService.PostData(navigate, location, 'sale/amend', 
        { 
            id, vendorId, subTotal, discount, amountPaid, remainingAmount, currency, type, remark, detailList, costList, exrate, totalCost_
        })
        .then((response) => {
            console.log(response.data);
            navigate(`${process.env.PUBLIC_URL}/sales-order-list`);
        })
        .catch((error) => {
            console.log(error);
        });

    };

    //Calculate Remaining Amount
    const calRemaining = (amountPaid_) => {
        let val = parseInt(subTotal) - parseInt(amountPaid_);
        setRemainingAmount(val);
    }

    const calDiscount = (value_) => {
        if (total > 0)
            setSubTotal(parseFloat(total) - parseFloat(value_));
        else
            setSubTotal(0);
    }

    const handleType = (value_) => {
        if (value_ == 1) {
            setCurrency(1);
            setLockCurrency(true);
        }else{
            setCurrency(0);
            setLockCurrency(false);
        }
    }

    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1><NavLink to="/sales-order-list" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; Amend Sales Order</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
                                <li className="breadcrumb-item active">Sales Order</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className='col-md-12'>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <div className="row">
                                        <div className='col-md-12'>
                                            <h5>Sales Order - {params.id}</h5>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">Vendor</label>
                                                <input type="text" className="form-control" id="vendor" placeholder="" value={vendorData.name} readOnly />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">Type</label>
                                                <input type="text" className="form-control" id="type" placeholder="" value={(orderData.type == 1) ? 'Domestic' : 'Export'} readOnly />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="slug">Currency</label>
                                                <input type="text" className="form-control" id="currency" placeholder="" value={(orderData.currency == 1) ? 'JPY' : 'USD'} readOnly />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="slug">Note</label>
                                                <textarea type="text" className="form-control" id="desc" placeholder="" value={orderData.remark} readOnly />
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-8'>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <div className='row'>
                                        <table className='col-12'>
                                            <tr>
                                                <th>Type</th>
                                                <th>Ref</th>
                                                <th>Description</th>
                                                <th>Qty</th>
                                                <th>Price</th>
                                                <th></th>
                                            </tr>
                                            <tbody>
                                                <tr>
                                                    <td width={'15%'}>
                                                        <select class="form-control" id="modelCode" value={itemType} onChange={(e) => { setItemType(e.target.value); (e.target.value == 1) ? setQty(1) : resetFields(''); }}>
                                                            <option value='0'>-- Select Type --</option>
                                                            <option value='1'>Inventory</option>
                                                            <option value='2'>Non-Inv</option>
                                                        </select>
                                                    </td>
                                                    <td width={'20%'}>
                                                        {/* { itemType != 2 && */}
                                                        {/* <select class="form-control" id="modelCode" value={listingId} onChange={(e) => { setListingId(e.target.value); (e.target.value != 0 && itemType == 0) ? setItemType(1) : void(0); (e.target.value != 0 && qty != 1) ? setQty(1) : void(0) }} disabled={(itemType == 2 ? true : false)}>
                                                            <option value='0'>-- Select Resource --</option>
                                                            {
                                                                listingData?.map((item, index) => {
                                                                    return(
                                                                        <option value={item.id} key={index}>{item.ref_no} | {item.title}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select> */}
                                                        {/* } */}
                                                        { itemType != 2 &&
                                                            <Select options={listingData.map((item, index) => ({ value: item.id, label: item.ref_no + ' | ' + item.title}))} onChange={(e) => { console.log('e :' + e.value); setListingId(e.value); (e.value != 0 && itemType == 0) ? setItemType(1) : void(0); (e.value != 0 && qty != 1) ? setQty(1) : void(0)}} disabled={(itemType == 2 ? true : false)} />
                                                        }
                                                    </td>
                                                    <td>
                                                        <input type="text" className="form-control" id="desc" placeholder="" value={desc} onChange={(e) => { setDesc(e.target.value) } } />
                                                    </td>
                                                    <td width={'10%'}>
                                                        <input type="number" className="form-control" id="qty" placeholder="" value={qty} onChange={(e) => { setQty(e.target.value) }} disabled={(itemType == 1 ? true : false)} />
                                                    </td>
                                                    <td>
                                                        <input type="text" className="form-control" id="price" placeholder="" value={price} onChange={(e) => { setPrice(e.target.value) } } />
                                                    </td>
                                                    <td>
                                                        <button className='btn btn-success' onClick={handleDetailAdd}> <i class="fas fa-plus"></i></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><hr></hr></td>
                                                    <td><hr></hr></td>
                                                    <td><hr></hr></td>
                                                    <td><hr></hr></td>
                                                    <td><hr></hr></td>
                                                    
                                                </tr>
                                                {
                                                    detailList?.map((item, index) => {
                                                        return(
                                                            <tr>
                                                                <td>
                                                                    <select class="form-control" id="modelCode" value={item.type} onChange={(e) => { item.type = e.target.value }} disabled={true}>
                                                                        <option value='0'>-- Select Type --</option>
                                                                        <option value='1'>Inventory</option>
                                                                        <option value='2'>Non-Inv</option>
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <select class="form-control" id="modelCode" value={item.ref} onChange={(e) => { item.ref = e.target.value }} disabled={(item.type == 2 ? true : false)}>
                                                                        <option value='0'>-- Select Ref --</option>
                                                                        {
                                                                            listingData?.map((item, index) => {
                                                                                return(
                                                                                    <option value={item.id} key={index}>{item.ref_no} | {item.title}</option>
                                                                                )
                                                                        })}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <input type="text" className="form-control" id="title" placeholder="" value={item.desc} onChange={(e) => { item.desc = e.target.value;} } />
                                                                </td>
                                                                <td>
                                                                    <input type="number" className="form-control" id="title" placeholder="" value={item.qty} onChange={(e) => { item.qty = e.target.value;} } disabled={(item.type == 1 ? true : false)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" className="form-control" id="title" placeholder="" value={item.price} onChange={(e) => { item.price = e.target.value } } />
                                                                </td>
                                                                <td>
                                                                    <button className='btn btn-danger' onClick={() => handleDetailDelete(index)}><i class="fas fa-trash"></i></button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        
                                    </div>
                                    {/* --- */}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <Cost costList={setCostList} data={costList} refresh={refresh}/>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    {/* /.row */}
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <div className='row'>
                                        {/* <div className='col-md-2'>
                                            <div className="form-group">
                                                <label htmlFor="title">Total</label>
                                                <input type="text" className="form-control" id="title" placeholder="" value={total} readOnly />
                                            </div>
                                        </div> */}
                                        {/* <div className='col-md-2' >
                                            <div className="form-group">
                                                <label htmlFor="slug">Discount</label>
                                                <input type="number" className="form-control" id="discount" placeholder="" value={discount} onChange={(e) => { setDiscount(e.target.value); calDiscount(e.target.value) }} />
                                            </div>
                                        </div> */}
                                        <div className='col-md-2'>
                                            <div className="form-group">
                                                <label htmlFor="title">Sub Total</label>
                                                <input type="text" className="form-control" id="title" placeholder="" value={subTotal + totalCost_} readOnly />
                                            </div>
                                        </div>
                                        {/* <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="title">Amount Paid</label>
                                                <input type="text" className="form-control" id="title" placeholder="" value={amountPaid} onChange={(e) => {setAmountPaid(e.target.value); calRemaining(e.target.value)}} />
                                            </div>
                                        </div> */}
                                        {/* <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="slug">Amount Remaining</label>
                                                <input type="text" className="form-control" id="slug" placeholder="" value={remainingAmount} readOnly />
                                            </div>
                                        </div> */}
                                    </div>
                                    
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <button type="submit" className="btn btn-success btn-block" onClick={handleSubmit}>Proceed</button>
                        </div>
                    </div>

                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>

    )
}

export default SalesOrderAmend;