import React from 'react'
import Placeholder from '../components/home/placeholder';
import SideNav from '../components/home/sideNav';
import Footer from '../components/home/footer';
import ProfitReport from '../components/report/profitReport';

export const ProfitReportPage = () => (
    <div class="wrapper">
        <Placeholder />
        <SideNav />
        <ProfitReport />
        <Footer />
    </div>
);