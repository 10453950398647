import React from 'react'
import { NavLink } from 'react-router-dom'

export const TileNav = () => {
    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Configurations</h1>
                        </div>
                        
                    </div>{/* /.row */}
                </div>{/* /.container-fluid */}
            </div>
            <section className="content">
                <div className="container-fluid">
                    {/* Tab row */}
                    <div className="row">
                        <div className='col-12'>
                            <div className='form-group'>

                            </div>
                        </div>
                        <div className='col-md-2'>
                            {/* <button className='btn btn-success'>categories</button> */}
                            <div className='form-group'>
                                <NavLink exact to={`${process.env.PUBLIC_URL}/category`} activeClassName="selected" className="nav-link btn btn-info">
                                    {/* <i class="far fa-circle nav-icon"></i>
                                    <p>Category</p> */}
                                    <img src='./dist/img/tile/categories.png' className='img-fluid' />
                                </NavLink>

                            </div>
                        </div>
                        <div className='col-md-2'>
                            {/* <button className='btn btn-warning'>Features</button> */}
                            <div className='form-group'>
                                <NavLink exact to={`${process.env.PUBLIC_URL}/features`} activeClassName="selected" className="nav-link btn btn-info">
                                    {/* <i class="far fa-circle nav-icon"></i>
                                    <p>Features List</p> */}
                                    <img src='./dist/img/tile/features.png' className='img-fluid' />
                                </NavLink>

                            </div>
                        </div>
                        <div className='col-md-2'>
                            {/* <button className='btn btn-primary'>Makes</button> */}
                            <div className='form-group'>
                                <NavLink exact to={`${process.env.PUBLIC_URL}/makes`} activeClassName="selected" className="nav-link btn btn-info">
                                    {/* <i class="far fa-circle nav-icon"></i>
                                    <p>Makes List</p> */}
                                    <img src='./dist/img/tile/makes.png' className='img-fluid' />
                                </NavLink>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            {/* <button className='btn btn-success'>Models</button> */}
                            <div className='form-group'>
                                <NavLink exact to={`${process.env.PUBLIC_URL}/model`} activeClassName="selected" className="nav-link btn btn-info">
                                    {/* <i class="far fa-circle nav-icon"></i>
                                    <p>Models List</p> */}
                                    <img src='./dist/img/tile/models.png' className='img-fluid' />
                                </NavLink>
                            </div>
                        </div>

                        <div className='col-md-2'>
                            <div className='form-group'>
                                <NavLink exact to={`${process.env.PUBLIC_URL}/transmission`} activeClassName="selected" className="nav-link btn btn-info">
                                    {/* <i class="far fa-circle nav-icon"></i>
                                    <p>Transmission-Types</p> */}
                                    <img src='./dist/img/tile/transmissions.png' className='img-fluid' />
                                </NavLink>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className='col-md-2'>
                            {/* <button className='btn btn-danger'>Fuel Types</button> */}
                            <div className='form-group'>
                                <NavLink exact to={`${process.env.PUBLIC_URL}/fueltype`} activeClassName="selected" className="nav-link btn btn-info">
                                    {/* <i class="far fa-circle nav-icon"></i>
                                    <p>Fuel-Types</p> */}
                                    <img src='./dist/img/tile/fuel.png' className='img-fluid' />
                                </NavLink>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            {/* <button className='btn btn-success'>Locations</button> */}
                            <div className='form-group'>
                                <NavLink exact to={`${process.env.PUBLIC_URL}/location`} activeClassName="selected" className="nav-link btn btn-info">
                                    {/* <i class="far fa-circle nav-icon"></i>
                                    <p>Locations</p> */}
                                    <img src='./dist/img/tile/location.png' className='img-fluid' />
                                </NavLink>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className='form-group'>
                                <NavLink exact to={`${process.env.PUBLIC_URL}/customer`} activeClassName="selected" className="nav-link btn btn-info">
                                    {/* <i class="far fa-circle nav-icon"></i>
                                    <p>Vendors</p> */}
                                    <img src='./dist/img/tile/vendors.png' className='img-fluid' />
                                </NavLink>
                            </div>
                            {/* <button className='btn btn-success'>Vendors</button> */}
                        </div>
                        <div className='col-md-2'>
                            {/* <button className='btn btn-success'>Users</button> */}
                            <div className='form-group'>
                                <NavLink exact to={`${process.env.PUBLIC_URL}/user`} activeClassName="selected" className="nav-link btn btn-info">
                                    {/* <i class="far fa-circle nav-icon"></i>
                                    <p>Users</p> */}
                                    <img src='./dist/img/tile/users.png' className='img-fluid' />
                                </NavLink>
                            </div>
                        </div>


                        <div className='col-md-2'>
                            <div className='form-group'>
                                <NavLink exact to={`${process.env.PUBLIC_URL}/currency`} activeClassName="selected" className="nav-link btn btn-info">
                                    {/* <i className="nav-icon fas fa-list" />
                                    <p>
                                        Exchange Rates
                                    </p> */}
                                    <img src='./dist/img/tile/exchange_rates.png' className='img-fluid' />
                                </NavLink>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>

        // <div>
        //     <div className="filter-container p-0 row">
        //         <div className="filtr-item col-sm-2" data-category="1" data-sort="white sample">
        //             <NavLink exact to={`${process.env.PUBLIC_URL}/category`} activeClassName="selected" className="nav-link">
        //             <a href="https://via.placeholder.com/1200/FFFFFF.png?text=1" data-toggle="lightbox" data-title="sample 1 - white">
        //                 <img src="https://via.placeholder.com/300/FFFFFF?text=1" className="img-fluid mb-2" alt="white sample" />
        //             </a>
        //             </NavLink>
        //         </div>
        //         <div className="filtr-item col-sm-2" data-category="2, 4" data-sort="black sample">
        //             <a href="https://via.placeholder.com/1200/000000.png?text=2" data-toggle="lightbox" data-title="sample 2 - black">
        //                 <img src="https://via.placeholder.com/300/000000?text=2" className="img-fluid mb-2" alt="black sample" />
        //             </a>
        //         </div>
        //         <div className="filtr-item col-sm-2" data-category="3, 4" data-sort="red sample">
        //             <a href="https://via.placeholder.com/1200/FF0000/FFFFFF.png?text=3" data-toggle="lightbox" data-title="sample 3 - red">
        //                 <img src="https://via.placeholder.com/300/FF0000/FFFFFF?text=3" className="img-fluid mb-2" alt="red sample" />
        //             </a>
        //         </div>
        //         <div className="filtr-item col-sm-2" data-category="3, 4" data-sort="red sample">
        //             <a href="https://via.placeholder.com/1200/FF0000/FFFFFF.png?text=4" data-toggle="lightbox" data-title="sample 4 - red">
        //                 <img src="https://via.placeholder.com/300/FF0000/FFFFFF?text=4" className="img-fluid mb-2" alt="red sample" />
        //             </a>
        //         </div>
        //         <div className="filtr-item col-sm-2" data-category="2, 4" data-sort="black sample">
        //             <a href="https://via.placeholder.com/1200/000000.png?text=5" data-toggle="lightbox" data-title="sample 5 - black">
        //                 <img src="https://via.placeholder.com/300/000000?text=5" className="img-fluid mb-2" alt="black sample" />
        //             </a>
        //         </div>
        //         <div className="filtr-item col-sm-2" data-category="1" data-sort="white sample">
        //             <a href="https://via.placeholder.com/1200/FFFFFF.png?text=6" data-toggle="lightbox" data-title="sample 6 - white">
        //                 <img src="https://via.placeholder.com/300/FFFFFF?text=6" className="img-fluid mb-2" alt="white sample" />
        //             </a>
        //         </div>
        //         <div className="filtr-item col-sm-2" data-category="1" data-sort="white sample">
        //             <a href="https://via.placeholder.com/1200/FFFFFF.png?text=7" data-toggle="lightbox" data-title="sample 7 - white">
        //                 <img src="https://via.placeholder.com/300/FFFFFF?text=7" className="img-fluid mb-2" alt="white sample" />
        //             </a>
        //         </div>
        //         <div className="filtr-item col-sm-2" data-category="2, 4" data-sort="black sample">
        //             <a href="https://via.placeholder.com/1200/000000.png?text=8" data-toggle="lightbox" data-title="sample 8 - black">
        //                 <img src="https://via.placeholder.com/300/000000?text=8" className="img-fluid mb-2" alt="black sample" />
        //             </a>
        //         </div>
        //         <div className="filtr-item col-sm-2" data-category="3, 4" data-sort="red sample">
        //             <a href="https://via.placeholder.com/1200/FF0000/FFFFFF.png?text=9" data-toggle="lightbox" data-title="sample 9 - red">
        //                 <img src="https://via.placeholder.com/300/FF0000/FFFFFF?text=9" className="img-fluid mb-2" alt="red sample" />
        //             </a>
        //         </div>
        //         <div className="filtr-item col-sm-2" data-category="1" data-sort="white sample">
        //             <a href="https://via.placeholder.com/1200/FFFFFF.png?text=10" data-toggle="lightbox" data-title="sample 10 - white">
        //                 <img src="https://via.placeholder.com/300/FFFFFF?text=10" className="img-fluid mb-2" alt="white sample" />
        //             </a>
        //         </div>
        //         <div className="filtr-item col-sm-2" data-category="1" data-sort="white sample">
        //             <a href="https://via.placeholder.com/1200/FFFFFF.png?text=11" data-toggle="lightbox" data-title="sample 11 - white">
        //                 <img src="https://via.placeholder.com/300/FFFFFF?text=11" className="img-fluid mb-2" alt="white sample" />
        //             </a>
        //         </div>
        //         <div className="filtr-item col-sm-2" data-category="2, 4" data-sort="black sample">
        //             <a href="https://via.placeholder.com/1200/000000.png?text=12" data-toggle="lightbox" data-title="sample 12 - black">
        //                 <img src="https://via.placeholder.com/300/000000?text=12" className="img-fluid mb-2" alt="black sample" />
        //             </a>
        //         </div>
        //     </div>
        // </div>
    )
}
