import { Routes, Route } from "react-router-dom";

import Layout from './components/Layout';
import RequireAuth from './components/RequiredAuth'; 

import { LoginPage } from "./pages/login";
import { RegisterPage } from "./pages/register";
import { HomePage } from "./pages/home";
import { CategoryPage } from "./pages/category"
import { FeaturesPage } from "./pages/features"
import { ListingPage } from "./pages/list"
import { StockHistoryPage } from "./pages/stockHistory"
import { StockVendorPage } from "./pages/stockVendor"

import { MakesPage } from "./pages/make";
import { ModelPage } from "./pages/model"
import { TransmissionPage } from "./pages/transmission";
import { FuelTypePage } from "./pages/fuelType";
import { LocationPage } from "./pages/location";

import { AddCategories } from "./pages/addCategory";
import { AddSubCategoryPage } from "./pages/addSubCategory";
import { AddFeaturesPage } from "./pages/addFeatures";
import { AddList } from "./pages/addList";

import { ViewListingPage } from "./pages/viewListing";
import { ViewListingMorePage } from "./pages/viewListingMore";

import { AddMakes } from "./pages/addMakes";
import { AddTrans } from "./pages/addTrans";
import { AddModel } from "./pages/addModel";
import { ManageFuelTypePage } from "./pages/manageFuelType";
import { ManageLocationPage } from "./pages/manageLocation";

import { CsvImport } from "./pages/csvImport";
import { SellListingPage } from "./pages/sellListing";
import { CustomerPage } from "./pages/customer";
import { ManageCustomerPage } from "./pages/manageCustomer";

import { UserPage } from "./pages/users";
import { ManageUserPage } from "./pages/manageUser";

import { ManageSalesOrderPage } from "./pages/manageSalesOrder";
import { SalesOrderListPage } from "./pages/salesOrder";
import { ViewSalesOrderPage } from "./pages/viewSalesOrder";
import { EditSalesOrderPage } from "./pages/editSalesOrder"; 

import { CurrencyPage } from "./pages/currency";
import { InvoicePage } from "./pages/invoice";

import { TileNavPage } from "./pages/tileNavPage";
import { StockReportPage } from "./pages/ReportStock";
import { SalesReportPage } from "./pages/reportSales";
import { ProfitReportPage } from "./pages/reportProfit"; 
import { UnauthorizedPage } from "./pages/unauthorized";

import { DataJobsPage } from "./pages/dataJobs";
import { AmendSalesOrderPage } from "./pages/amendSalesOrder";
import { SalesOrderListCancelledPage } from "./pages/salesOrderCancelled";

import { ContainerPage } from "./pages/container";
import { ContainerListPage } from "./pages/containerList";

if (typeof window !== "undefined") {
	import("bootstrap");
}

const ROLES = {
	'Staff': 3,
	'Executive': 2,
	'Admin': 1
}


export default function App() {

	return (
			<Routes basename={"/admin"}>
				<Route path={`${process.env.PUBLIC_URL}/`} element={<Layout />} >

					<Route path={`${process.env.PUBLIC_URL}/login`} element={<LoginPage />} />
					<Route path={`${process.env.PUBLIC_URL}/register`} element={<RegisterPage />} />
					<Route path={`${process.env.PUBLIC_URL}/unauthorized`} element={<UnauthorizedPage />} />
					
					{/* Protected Routes */}
					<Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
						<Route path={`${process.env.PUBLIC_URL}/user`} element={<UserPage />} />
						<Route path={`${process.env.PUBLIC_URL}/manage-user`} element={<ManageUserPage />} />
						<Route path={`${process.env.PUBLIC_URL}/manage-user/:id`} element={<ManageUserPage />} />
						<Route path={`${process.env.PUBLIC_URL}/data-jobs`} element={<DataJobsPage />} /> 
					</Route>

					<Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Executive]} />}>
						<Route path={`${process.env.PUBLIC_URL}/report-profit`} element={<ProfitReportPage />} />

						<Route path={`${process.env.PUBLIC_URL}/category`} element={<CategoryPage />} />
						<Route path={`${process.env.PUBLIC_URL}/features`} element={<FeaturesPage />} />

						<Route path={`${process.env.PUBLIC_URL}/makes`} element={<MakesPage />} />
						<Route path={`${process.env.PUBLIC_URL}/model`} element={<ModelPage />} />
						<Route path={`${process.env.PUBLIC_URL}/transmission`} element={<TransmissionPage />} />
						<Route path={`${process.env.PUBLIC_URL}/fueltype`} element={<FuelTypePage />} />
						<Route path={`${process.env.PUBLIC_URL}/location`} element={<LocationPage />} />

						<Route path={`${process.env.PUBLIC_URL}/add-category`} element={<AddCategories />} />
						<Route path={`${process.env.PUBLIC_URL}/add-category/:id`} element={<AddCategories />} />
						<Route path={`${process.env.PUBLIC_URL}/add-sub-category`} element={<AddSubCategoryPage />} />
						<Route path={`${process.env.PUBLIC_URL}/add-sub-category/:id`} element={<AddSubCategoryPage />} />
						

						<Route path={`${process.env.PUBLIC_URL}/add-features`} element={<AddFeaturesPage />} />
						<Route path={`${process.env.PUBLIC_URL}/add-features/:id`} element={<AddFeaturesPage />} />

						<Route path={`${process.env.PUBLIC_URL}/add-makes`} element={<AddMakes />} />
						<Route path={`${process.env.PUBLIC_URL}/add-makes/:id`} element={<AddMakes />} />

						<Route path={`${process.env.PUBLIC_URL}/add-model`} element={<AddModel />} />
						<Route path={`${process.env.PUBLIC_URL}/add-model/:id`} element={<AddModel />} />

						<Route path={`${process.env.PUBLIC_URL}/add-trans`} element={<AddTrans />} />
						<Route path={`${process.env.PUBLIC_URL}/add-trans/:id`} element={<AddTrans />} />

						<Route path={`${process.env.PUBLIC_URL}/manage-fueltype`} element={<ManageFuelTypePage />} />
						<Route path={`${process.env.PUBLIC_URL}/manage-fueltype/:id`} element={<ManageFuelTypePage />} />

						<Route path={`${process.env.PUBLIC_URL}/manage-location`} element={<ManageLocationPage />} />
						<Route path={`${process.env.PUBLIC_URL}/manage-location/:id`} element={<ManageLocationPage />} />

					</Route>

					<Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Executive, ROLES.Staff]} />}>
						<Route path={`${process.env.PUBLIC_URL}/`} element={<HomePage />} />

						{/* <Route path={`${process.env.PUBLIC_URL}/category`} element={<CategoryPage />} />
						<Route path={`${process.env.PUBLIC_URL}/features`} element={<FeaturesPage />} /> */}

						<Route path={`${process.env.PUBLIC_URL}/listing`} element={<ListingPage />} />

						{/* <Route path={`${process.env.PUBLIC_URL}/makes`} element={<MakesPage />} />
						<Route path={`${process.env.PUBLIC_URL}/model`} element={<ModelPage />} />
						<Route path={`${process.env.PUBLIC_URL}/transmission`} element={<TransmissionPage />} />
						<Route path={`${process.env.PUBLIC_URL}/fueltype`} element={<FuelTypePage />} />
						<Route path={`${process.env.PUBLIC_URL}/location`} element={<LocationPage />} />

						<Route path={`${process.env.PUBLIC_URL}/add-category`} element={<AddCategories />} />
						<Route path={`${process.env.PUBLIC_URL}/add-category/:id`} element={<AddCategories />} />
						<Route path={`${process.env.PUBLIC_URL}/add-sub-category`} element={<AddSubCategoryPage />} />
						<Route path={`${process.env.PUBLIC_URL}/add-sub-category/:id`} element={<AddSubCategoryPage />} />
						

						<Route path={`${process.env.PUBLIC_URL}/add-features`} element={<AddFeaturesPage />} />
						<Route path={`${process.env.PUBLIC_URL}/add-features/:id`} element={<AddFeaturesPage />} /> */}

						{/* Stock */}
						<Route path={`${process.env.PUBLIC_URL}/add-list`} element={<AddList />} />
						<Route path={`${process.env.PUBLIC_URL}/add-list/:id`} element={<AddList />} />
						<Route path={`${process.env.PUBLIC_URL}/view-list/:id`} element={<ViewListingPage />} />
						<Route path={`${process.env.PUBLIC_URL}/view-list-extend/:id`} element={<ViewListingMorePage />} /> 
						<Route path={`${process.env.PUBLIC_URL}/stock-history`} element={<StockHistoryPage />} />
						<Route path={`${process.env.PUBLIC_URL}/stock-vendor`} element={<StockVendorPage />} />
						

						{/* //<Route path={`${process.env.PUBLIC_URL}/add-makes`} element={<AddMakes />} />
						//<Route path={`${process.env.PUBLIC_URL}/add-makes/:id`} element={<AddMakes />} />

						//<Route path={`${process.env.PUBLIC_URL}/add-model`} element={<AddModel />} />
						//<Route path={`${process.env.PUBLIC_URL}/add-model/:id`} element={<AddModel />} />

						//<Route path={`${process.env.PUBLIC_URL}/add-trans`} element={<AddTrans />} />
						//<Route path={`${process.env.PUBLIC_URL}/add-trans/:id`} element={<AddTrans />} />

						//<Route path={`${process.env.PUBLIC_URL}/manage-fueltype`} element={<ManageFuelTypePage />} />
						//<Route path={`${process.env.PUBLIC_URL}/manage-fueltype/:id`} element={<ManageFuelTypePage />} />

						//<Route path={`${process.env.PUBLIC_URL}/manage-location`} element={<ManageLocationPage />} />
						//<Route path={`${process.env.PUBLIC_URL}/manage-location/:id`} element={<ManageLocationPage />} /> */}

						<Route path={`${process.env.PUBLIC_URL}/csv`} element={<CsvImport />} />

						<Route path={`${process.env.PUBLIC_URL}/sell-listing/:id`} element={<SellListingPage />} />

						<Route path={`${process.env.PUBLIC_URL}/customer`} element={<CustomerPage />} />
						<Route path={`${process.env.PUBLIC_URL}/manage-customer`} element={<ManageCustomerPage />} />
						<Route path={`${process.env.PUBLIC_URL}/manage-customer/:id`} element={<ManageCustomerPage />} />

						{/* <Route path={`${process.env.PUBLIC_URL}/user`} element={<UserPage />} />
						<Route path={`${process.env.PUBLIC_URL}/manage-user`} element={<ManageUserPage />} />
						<Route path={`${process.env.PUBLIC_URL}/manage-user/:id`} element={<ManageUserPage />} /> */}

						<Route path={`${process.env.PUBLIC_URL}/sales-order`} element={<ManageSalesOrderPage />} />
						<Route path={`${process.env.PUBLIC_URL}/sales-order/:id`} element={<ManageSalesOrderPage />} />
						<Route path={`${process.env.PUBLIC_URL}/sales-order-list`} element={<SalesOrderListPage />} />
						<Route path={`${process.env.PUBLIC_URL}/sales-order-history-list`} element={<SalesOrderListCancelledPage />} />
						<Route path={`${process.env.PUBLIC_URL}/sales-order-view/:id`} element={<ViewSalesOrderPage />} />
						<Route path={`${process.env.PUBLIC_URL}/sales-order-edit/:id`} element={<EditSalesOrderPage />} />
						<Route path={`${process.env.PUBLIC_URL}/sales-order-amend/:id`} element={<AmendSalesOrderPage />} />
						
						<Route path={`${process.env.PUBLIC_URL}/container`} element={<ContainerPage />} />
						<Route path={`${process.env.PUBLIC_URL}/container-list`} element={<ContainerListPage />} />
						<Route path={`${process.env.PUBLIC_URL}/container/:id`} element={<ContainerPage />} />

						<Route path={`${process.env.PUBLIC_URL}/currency`} element={<CurrencyPage />} />

						<Route path={`${process.env.PUBLIC_URL}/print-invoice/:id`} element={<InvoicePage />} />
						
						<Route path={`${process.env.PUBLIC_URL}/menu`} element={<TileNavPage />} />
						
						{/* Reports */}
						<Route path={`${process.env.PUBLIC_URL}/report-stock`} element={<StockReportPage />} />
						<Route path={`${process.env.PUBLIC_URL}/report-sales`} element={<SalesReportPage />} />
					</Route>

					

					{/* <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
						<Route path={`${process.env.PUBLIC_URL}/user`} element={<UserPage />} />
						<Route path={`${process.env.PUBLIC_URL}/manage-user`} element={<ManageUserPage />} />
						<Route path={`${process.env.PUBLIC_URL}/manage-user/:id`} element={<ManageUserPage />} />
					</Route> */}

				</Route>
			</Routes>
	);
}

