import React, { useEffect, useState } from 'react'
import CrudService from '../../util/crudService';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: false,
            text: 'Sales',
        },
    },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const PendingSalesOrders = ({ t }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [currPage, setCurrPage] = useState(1);
    const [page_, setPage_] = useState(1);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);

    const [orderList, setOrderList] = useState([]);

    //Next Page
    const nextPage = () => {
        if (hasNext)
            setPage_(currPage + parseInt('1'));
    }

    //Previous Page
    const prevPage = () => {
        if (hasPrev)
            setPage_(currPage - parseInt('1'));
    }

    const gotoPage = (page_) => {
        if (hasNext)
            setPage_(parseInt(page_));
    }

    const fetchData = () => {
        CrudService.GetDataWithParam(navigate, location, 'stats/pending-sales-order',
        {
            page: page_,
        })
        .then((res) => {
            console.log(res.data);
            setOrderList(res.data.data);
            setCurrPage(parseInt(res.data.curr_page));
            setHasNext(res.data.hasNext);
            setHasPrev(res.data.hasPrev);
        }).catch((error) => {
            console.error(error);
        })
    }

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        fetchData();
    }, [page_])

    return (
        <div className="card">
            <div className="card-header">
                <h3 class="card-title">Pending {t('dashboard.sales')}</h3>

                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                    </button>
                    <button type="button" class="btn btn-tool" data-card-widget="remove">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
            </div>
            {/* /.card-header */}
            <div className="card-body table-responsive p-0">
                <table className="table table-hover text-nowrap">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t('dashboard.vendor')}</th>
                            <th>{t('dashboard.date')}</th>
                            <th>{t('dashboard.type')}</th>
                            <th>{t('dashboard.currency')}</th>
                            <th>{t('dashboard.total')}</th>
                            <th>{t('dashboard.remanining-amount')}</th>
                            <th style={{ textAlign: 'center' }}>{t('dashboard.status')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderList?.map((value) => {
                            return (
                                <tr>
                                    <td align='center' width={'10%'}>
                                        <NavLink exact to={`/sales-order-view/${value.id}`}>
                                            <button className='btn btn-success btn-xs' style={{ marginRight: '5px' }}>View</button>
                                        </NavLink>
                                        
                                    </td>
                                    <td>{value.Vendor.name}</td>
                                    <td>{value.order_date}</td>
                                    <td>{value.type == 1 ? <span class="badge bg-info">Domestic</span> : <span class="badge bg-primary">Export</span>}</td>
                                    {/* <td>{ value.status == 1 ? <span class="badge bg-success">Pending</span>  : <span class="badge bg-danger">Cancelled</span> }</td> */}
                                    <td>{(value.currency == 1) ? 'JPY' : 'USD'}</td>
                                    <td width={'5%'} align='right'>{value.sub_total}</td>
                                    <td width={'10%'} align='right'>{value.amount_remaining}</td>
                                    <td align='center'>{(value.status == 0) && <span class="badge bg-danger">Cancelled</span> || (value.status == 1) && <span class="badge bg-warning">Pending</span> || <span class="badge bg-success">Completed</span>}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {/* /.card-body */}
            <div className='card-footer clearfix'>
                <ul class="pagination pagination-sm">
                    {(hasPrev == 'true') &&
                        <li class="page-item"><a class="page-link" href="javascript:void(0);" tabindex="-1" onClick={() => prevPage()}>«</a></li>
                    }
                    <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={() => gotoPage(1)}>1</a></li>
                    <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={() => gotoPage(2)}>2</a></li>
                    <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={() => gotoPage(3)}>3</a></li>
                    {(hasNext == 'true') &&
                        <li class="page-item"><a class="page-link" href="javascript:void(0);" onClick={() => nextPage()}>»</a></li>
                    }
                </ul>
            </div>
        </div>
    )
}

const InquiryList = ({ t }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [currPage, setCurrPage] = useState(1);
    const [page_, setPage_] = useState(1);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    const [orderList, setOrderList] = useState([]);

    //Next Page
    const nextPage = () => {
        if (hasNext)
            setPage_(currPage + parseInt('1'));
    }

    //Previous Page
    const prevPage = () => {
        if (hasPrev)
            setPage_(currPage - parseInt('1'));
    }

    const gotoPage = (page_) => {
        if (hasNext)
            setPage_(parseInt(page_));
    }

    const fetchData = () => {
        CrudService.GetDataWithParam(navigate, location, 'inquiry/extend',
        {
            page: page_,
        })
        .then((res) => {
            console.log(res.data);
            setOrderList(res.data.data);
            setCurrPage(parseInt(res.data.curr_page));
            setHasNext(res.data.hasNext);
            setHasPrev(res.data.hasPrev);
            setRowCount(res.data.count);
        }).catch((error) => {
            console.error(error);
        })
    }

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        fetchData();
    }, [page_])

    const pages = [];

    console.log('Row Count :' + rowCount);

    for (let i = 1; (i <= (rowCount/5) + 1) && (i <= 20); i++) {
		pages.push(
			<li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(i)}>{i}</a></li>
		);
	}

    return (
        <div className="card">
            <div className="card-header">
                <h3 class="card-title">{t('dashboard.inquiries')}</h3>

                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                    </button>
                    <button type="button" class="btn btn-tool" data-card-widget="remove">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
            </div>
            {/* /.card-header */}
            <div className="card-body table-responsive p-0">
                <table className="table table-hover text-nowrap">
                    <thead>
                        <tr>
                            <th>{t('dashboard.email')}</th>
                            <th>{t('dashboard.message')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderList?.map((value) => {
                            return (
                                <tr>
                                    <td><a href={`mailto:${value.email}`}>{value.email}</a></td>
                                    <td>{value.message}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {/* /.card-body */}
            <div className='card-footer clearfix'>
                <ul class="pagination pagination-sm">
                    {(hasPrev == 'true') &&
                        <li class="page-item"><a class="page-link" href="javascript:void(0);" tabindex="-1" onClick={() => prevPage()}>«</a></li>
                    }
                    {pages}
                    {/* <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={() => gotoPage(1)}>1</a></li>
                    <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={() => gotoPage(2)}>2</a></li>
                    <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={() => gotoPage(3)}>3</a></li> */}
                    {(hasNext == 'true') &&
                        <li class="page-item"><a class="page-link" href="javascript:void(0);" onClick={() => nextPage()}>»</a></li>
                    }
                </ul>
            </div>
        </div>
    )
}

function ContentWrapper() {
    const { t } = useTranslation();
    const [mainStats, setMainStats] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    const currentData = new Date();
    const currentMonth = currentData.toLocaleString('en-US', { month: 'long' });
    const currentYear = currentData.getFullYear();

    const userLocal = JSON.parse(window.localStorage.getItem('user'));

    const chart_data = {
        labels,
        datasets: [
            {
                label: 'Sales',
                data: (mainStats) ? mainStats?.sales_count_chart_data?.map((item) => item.count) : [],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 1)',
            }
        ],
    };

    const bar_data = {
        labels,
        datasets: [
            {
                label: 'Domestic',
                data: (mainStats) ? mainStats?.domestic_month_chart_data?.map((item) => item.count) : [],
                backgroundColor: 'rgba(255, 99, 132, 1)',
            },
            {
                label: 'Export',
                data: (mainStats) ? mainStats?.export_month_chart_data?.map((item) => item.count) : [],
                backgroundColor: 'rgba(53, 162, 235, 1)',
            },
        ],
    };

    const pie_data = {
        labels: (mainStats) ? mainStats?.sales_category_month?.map((item) => item.name) : [], //['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
            {
                label: '# of Sales',
                data: (mainStats) ? mainStats?.sales_category_month?.map((item) => item.sales_count) : [],    //[12, 19, 3, 5, 2, 3],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.8)',
                    'rgba(54, 162, 235, 2)',
                    'rgba(255, 206, 86, 2)',
                    'rgba(75, 192, 192, 2)',
                    'rgba(153, 102, 255, 2)',
                    'rgba(255, 159, 64, 2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const pie_data2 = {
        labels: (mainStats) ? mainStats?.sales_category_year?.map((item) => item.name) : [], //['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
            {
                label: '# of Sales',
                data: (mainStats) ? mainStats?.sales_category_year?.map((item) => item.sales_count) : [],    //[12, 19, 3, 5, 2, 3],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    useEffect(() => {
        CrudService.GetDataWithParam(navigate, location, 'stats/main',
            {})
            .then((res) => {
                console.log(res.data);
                setMainStats(res.data.data);
            })
    }, [])

    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">{t('dashboard.backoffice-dashboard')}</h1>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">{t('dashboard.backoffice-dashboard')}</li>
                            </ol>
                        </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>{/* /.container-fluid */}
            </div>
            {/* /.content-header */}
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">

                    <div className='row'>
                        <div class="col-md-6 col-sm-6 col-12">
                            <div class="info-box shadow">
                                <span class="info-box-icon bg-info"><i class="far fa-copy"></i></span>

                                <div class="info-box-content" style={{ fontSize: '18px' }}>
                                    <span class="info-box-text">{t('dashboard.current-stock')}</span>
                                    <span class="info-box-number" style={{ fontSize: '25px', marginTop: '-0.65rem' }}>{Number(mainStats.avail_stock_count)}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-12">
                            <div class="info-box shadow">
                                <span class="info-box-icon bg-warning"><i class="ion ion-bag"></i></span>

                                <div class="info-box-content" style={{ fontSize: '18px' }}>
                                    <span class="info-box-text">{t('dashboard.total-sales')} ({currentYear})</span>
                                    <span class="info-box-number" style={{ fontSize: '25px', marginTop: '-0.65rem' }}>¥ {Number(mainStats.sum_sales)}</span>
                                </div>
                            </div>
                        </div>
                        { (userLocal?.roles[0] == 1 || userLocal?.roles[0] == 2) &&
                        <>
                            <div class="col-md-6 col-sm-6 col-12">
                                <div class="info-box shadow">
                                    <span class="info-box-icon bg-success"><i class="ion ion-stats-bars"></i></span>

                                    <div class="info-box-content" style={{ fontSize: '18px' }}>
                                        <span class="info-box-text">{t('dashboard.profit')} ({currentMonth})</span>
                                        <span class="info-box-number" style={{ fontSize: '25px', marginTop: '-0.65rem' }}>¥ {Number(mainStats.profit_monthly)}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-12">
                                <div class="info-box shadow">
                                    <span class="info-box-icon bg-danger"><i class="ion ion-stats-bars"></i></span>

                                    <div class="info-box-content" style={{ fontSize: '18px' }}>
                                        <span class="info-box-text">{t('dashboard.profit')} ({currentYear})</span>
                                        <span class="info-box-number" style={{ fontSize: '25px', marginTop: '-0.65rem' }}>¥ {Number(mainStats.profit_annualy)}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                        }
                    </div>
                    {/* ------ */}

                    <hr></hr>

                    {/* Small boxes (Stat box) */}
                    <div className='row'>
                        <div className="col-lg-12">
                            <div class="card card-success-outline">
                                <div class="card-header">
                                    <h3 class="card-title">Monthly Sales Statistics</h3>

                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                        <button type="button" class="btn btn-tool" data-card-widget="remove">
                                            <i class="fas fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div className="row">
                                        <div className="col-lg-3 col-6">
                                            {/* small box */}
                                            <div className="small-box bg-success">
                                                <div className="inner">
                                                    <h3>{Number(mainStats.monthly_sales_count)}</h3>
                                                    <p>{t('dashboard.total-sales')} - <b>{currentMonth}</b></p>
                                                </div>
                                                <div className="icon">
                                                    <i className="ion ion-bag" />
                                                </div>
                                                {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a> */}
                                            </div>
                                        </div>
                                        {/* ./col */}
                                        <div className="col-lg-3 col-6">
                                            {/* small box */}
                                            <div className="small-box bg-success">
                                                <div className="inner">
                                                    <h3>{Number(mainStats.monthly_domestic_count)}</h3>
                                                    <p>{t('dashboard.domestic-sales')} - <b>{currentMonth}</b></p>
                                                </div>
                                                <div className="icon">
                                                    <i className="ion ion-bag" />
                                                </div>
                                                {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a> */}
                                            </div>
                                        </div>
                                        {/* ./col */}
                                        <div className="col-lg-3 col-6">
                                            {/* small box */}
                                            <div className="small-box bg-success">
                                                <div className="inner">
                                                    <h3>{Number(mainStats.monthly_export_count)}</h3>
                                                    <p>{t('dashboard.export-sales')} - <b>{currentMonth}</b></p>
                                                </div>
                                                <div className="icon">
                                                    <i className="ion ion-bag" />
                                                </div>
                                                {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a> */}
                                            </div>
                                        </div>
                                        {/* ./col */}

                                        <div className="col-lg-3 col-6">
                                            {/* small box */}
                                            <div className="small-box bg-primary">
                                                <div className="inner">
                                                    <h3>{Number(mainStats.new_stock_count)}</h3>
                                                    <p>{t('dashboard.new-stock')}  - <b>{currentMonth}</b></p>
                                                </div>
                                                <div className="icon">
                                                    <i className="ion ion-plus-round" /> {/* ion-person-add */}
                                                </div>
                                                {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a> */}
                                            </div>
                                        </div>
                                        {/* ./col */}
                                    </div>
                                    {/* ---------- */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  */}

                    {/* Small boxes (Stat box) */}
                    <div className='row'>
                        <div className="col-lg-12">
                            <div class="card card-success-outline">
                                <div class="card-header">
                                    <h3 class="card-title">{t('dashboard.yearly-sales-statistics')}</h3>

                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                        <button type="button" class="btn btn-tool" data-card-widget="remove">
                                            <i class="fas fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div className="row">
                                        <div className="col-lg-3 col-6">
                                            {/* small box */}
                                            <div className="small-box bg-warning">
                                                <div className="inner">
                                                    <h3>{Number(mainStats.total_sales)}</h3>
                                                    <p>{t('dashboard.total-sales')}</p>
                                                </div>
                                                <div className="icon">
                                                    <i className="ion ion-bag" />
                                                </div>
                                                {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a> */}
                                            </div>
                                        </div>
                                        {/* ./col */}
                                        <div className="col-lg-3 col-6">
                                            {/* small box */}
                                            <div className="small-box bg-warning">
                                                <div className="inner">
                                                    <h3>{Number(mainStats.domestic_count)}</h3>
                                                    <p>{t('dashboard.domestic-sales')}</p>
                                                </div>
                                                <div className="icon">
                                                    <i className="ion ion-pie-graph" />
                                                </div>
                                                {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a> */}
                                            </div>
                                        </div>
                                        {/* ./col */}
                                        <div className="col-lg-3 col-6">
                                            {/* small box */}
                                            <div className="small-box bg-warning">
                                                <div className="inner">
                                                    <h3>{Number(mainStats.export_count)}</h3>
                                                    <p>{t('dashboard.export-sales')}</p>
                                                </div>
                                                <div className="icon">
                                                    <i className="ion ion-pie-graph" />
                                                </div>
                                                {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a> */}
                                            </div>
                                        </div>
                                        {/* ./col */}
                                        <div className="col-lg-3 col-6">
                                            {/* small box */}
                                            <div className="small-box bg-danger">
                                                <div className="inner">
                                                    <h3>{Number(mainStats.dismantle_count)}</h3>
                                                    <p>{t('dashboard.total-dismantle')}</p>
                                                </div>
                                                <div className="icon">
                                                    <i className="ion ion-stats-bars" />
                                                </div>
                                                {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a> */}
                                            </div>
                                        </div>
                                        {/* ./col */}
                                    </div>
                                    {/* ---------- */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ---- */}

                    

                    <div className="row" hidden>
                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box bg-info">
                                <div className="inner">
                                    <h3>{Number(mainStats.total_sales)}</h3>
                                    <p>{t('dashboard.total-sales')}</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-bag" />
                                </div>
                                <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                            </div>
                        </div>
                        {/* ./col */}
                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box bg-success">
                                <div className="inner">
                                    <h3>{Number(mainStats.domestic_count)}</h3>
                                    <p>{t('dashboard.domestic-sales')}</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-pie-graph" />
                                </div>
                                <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                            </div>
                        </div>
                        {/* ./col */}
                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box bg-warning">
                                <div className="inner">
                                    <h3>{Number(mainStats.export_count)}</h3>
                                    <p>{t('dashboard.export-sales')}</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-pie-graph" />
                                </div>
                                <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                            </div>
                        </div>
                        {/* ./col */}
                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box bg-danger">
                                <div className="inner">
                                    <h3>{Number(mainStats.dismantle_count)}</h3>
                                    <p>{t('dashboard.total-dismantle')}</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-stats-bars" />
                                </div>
                                <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                            </div>
                        </div>
                        {/* ./col */}


                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box bg-info">
                                <div className="inner">
                                    <h3>{Number(mainStats.monthly_sales_count)}</h3>
                                    <p>{t('dashboard.total-sales')} - <b>{currentMonth}</b></p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-bag" />
                                </div>
                                <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                            </div>
                        </div>
                        {/* ./col */}
                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box bg-success">
                                <div className="inner">
                                    <h3>{Number(mainStats.monthly_domestic_count)}</h3>
                                    <p>{t('dashboard.domestic-sales')} - <b>{currentMonth}</b></p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-pie-graph" />
                                </div>
                                <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                            </div>
                        </div>
                        {/* ./col */}
                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box bg-warning">
                                <div className="inner">
                                    <h3>{Number(mainStats.monthly_export_count)}</h3>
                                    <p>{t('dashboard.export-sales')} - <b>{currentMonth}</b></p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-pie-graph" />
                                </div>
                                <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                            </div>
                        </div>
                        {/* ./col */}

                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box bg-primary">
                                <div className="inner">
                                    <h3>0</h3>
                                    <p>{t('dashboard.new-stock')}</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-person-add" />
                                </div>
                                <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                            </div>
                        </div>
                        {/* ./col */}

                    </div>
                    {/* /.row */}

                    {/* Main row */}
                    <div className="row">
                        {/* Left col */}
                        <section className="col-lg-12 connectedSortable">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div class="card card-success-outline">
                                        <div class="card-header">
                                            <h3 class="card-title">{t('dashboard.yearly-sales-statistics')} - {currentYear}</h3>

                                            <div class="card-tools">
                                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                    <i class="fas fa-minus"></i>
                                                </button>
                                                <button type="button" class="btn btn-tool" data-card-widget="remove">
                                                    <i class="fas fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="chart">
                                                <Line options={options} data={chart_data} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    {/* Custom tabs (Charts with tabs)*/}
                                    <div class="card">
                                        <div class="card-header">
                                            <h3 class="card-title">{t('dashboard.yearly-sales-statistics')} - {currentYear}</h3>

                                            <div class="card-tools">
                                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                    <i class="fas fa-minus"></i>
                                                </button>
                                                <button type="button" class="btn btn-tool" data-card-widget="remove">
                                                    <i class="fas fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="chart">
                                                <Bar options={options} data={bar_data} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* /.card */}
                                </div>
                                

                                <div className="col-lg-3">
                                    <div class="card card-success-outline">
                                        <div class="card-header">
                                            <h3 class="card-title">{t('dashboard.sales')} - {currentMonth}</h3>

                                            <div class="card-tools">
                                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                    <i class="fas fa-minus"></i>
                                                </button>
                                                <button type="button" class="btn btn-tool" data-card-widget="remove">
                                                    <i class="fas fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="chart">
                                                <Pie data={pie_data} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3">
                                    <div class="card card-success-outline">
                                        <div class="card-header">
                                            <h3 class="card-title">{t('dashboard.sales')} - {currentYear}</h3>

                                            <div class="card-tools">
                                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                    <i class="fas fa-minus"></i>
                                                </button>
                                                <button type="button" class="btn btn-tool" data-card-widget="remove">
                                                    <i class="fas fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="chart">
                                                <Pie data={pie_data2} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-lg-6">
                                    <InquiryList  t={t} />
                                </div>
                            </div>

                            {/* ------------ */}
                        </section>
                        {/* /.Left col */}
                    </div>
                    {/* /.row (main row) */}
                    <hr></hr>
                    <div className='row'>
                        <div className='col-md-12'>
                            <PendingSalesOrders t={t}/>
                        </div>
                    </div>

                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>

    )
}

export default ContentWrapper