import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import { NavLink, Link, useNavigate, useLocation } from 'react-router-dom'
import CrudService from '../../util/crudService'
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select'
import Swal from 'sweetalert2'
import Fp from '../../util/formatPrice';
import { useTranslation } from 'react-i18next';

const customControlStyles = base => ({
    ...base,
    height: 35,
    minHeight: 20,
    fontSize: '.875rem',
});

const FilterDiv = (props) => {
    const t = props.t;
    const [refNo_, setRefNo_] = useState('');
    const [avail_, setAvail_] = useState('');
    const [vin_, setVin_] = useState('');
    const [title_, setTitle_] = useState('');
    const [keyword_, setKeyword_] = useState('');
    const [make_, setMake_] = useState('');
    const [category_, setCategory_] = useState('');
    const [status_, setStatus_] = useState('');
    const [supplier_, setSupplier_] = useState('');

    const [makeList, setMakeList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [vendorList, setVendorList] = useState([]);

    let navigate = props.navigation;
    let locationHook = props.locationHook;

    const handleFilter = () => {
        console.log('refNo_ :' + refNo_)
        let data = { keyword: keyword_, refNo: refNo_, availability: avail_, vin: vin_, title: title_, make: make_, category: category_, status: status_ }
        props.searchQuery(data);
    }

    useEffect(() => {
        //Makes List
        CrudService.GetData(navigate, locationHook, 'make/basic')
        .then((res) => {
            setMakeList(res.data);
        })

        //Category List
        CrudService.GetData(navigate, locationHook, 'category/basic')
        .then((res) => {
            setCategoryList(res.data);
        })

        //Supplier List
        CrudService.GetData(navigate, locationHook, 'vendor/basic')
        .then((res) => {
            setVendorList(res.data);
        })
        .catch((err) => console.error(err))

    }, [])

    const handleExcel = async (e) => {

        try {
            const response = await CrudService.GetFileData(navigate, locationHook, 'listing/export-excel', 
            {
                //ref_no: refNo_,
                //vin: vin_,
                //title: title_,
                keyword: keyword_,
                make: make_,
                category: category_,
                status: status_
            });

            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'stock_report.xlsx');
            document.body.appendChild(link);
            link.click();

        } catch (error) {
            console.error('Error downloading the Excel file:', error);
        }
    }

    return (
        <section class="content">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className='col-md-2'>
                            <div className="form-group">
                                <input type="text" className="form-control form-control-sm" id="keyword" placeholder="Keyword" value={keyword_} onChange={(e) => setKeyword_(e.target.value)}/>
                            </div>
                        </div>
                        {/* <div className='col-md-2'>
                            <div className="form-group">
                                <input type="text" className="form-control form-control-sm" id="refNo" placeholder="Ref No" value={refNo_} onChange={(e) => setRefNo_(e.target.value)}/>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <input type="text" className="form-control form-control-sm" id="vin" placeholder="VIN" value={vin_} onChange={(e) => setVin_(e.target.value)}/>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <input type="text" className="form-control form-control-sm" id="title" placeholder="Title" value={title_} onChange={(e) => setTitle_(e.target.value)}/>
                            </div>
                        </div> */}
                        <div className='col-md-2'>
                            <div className="form-group">
                                <select class="form-control form-control-sm" id="modelCode" value={make_} onChange={(e) => { setMake_(e.target.value);}}>
                                    <option value=''>-- {t('stock-page.make')} --</option>
                                    { makeList?.map((item, key) => {
                                        return ( <option key={key} value={item.id}>{item.name}</option> )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <select class="form-control form-control-sm" id="modelCode" value={category_} onChange={(e) => { setCategory_(e.target.value);}}>
                                    <option value=''>-- {t('stock-page.category')} --</option>
                                    { categoryList?.map((item, key) => {
                                        return ( <option key={key} value={item.id}>{item.name}</option> )
                                    })}
                                </select>
                            </div>
                        </div>
                        {/* Supplier */}
                        <div className='col-md-2'>
                            <div className="form-group">
                                <Select styles={{control: customControlStyles}} options={vendorList.map((item, index) => ({ value: item.id, label: item.name }))} onChange={(e) => { console.log('e :' + e.value); setSupplier_(e.value);}} placeholder="Supplier"/>
                                {/* <select class="form-control form-control-sm" id="modelCode" value={category_} onChange={(e) => { setCategory_(e.target.value);}}>
                                    <option value=''>-- Category --</option>
                                    { categoryList?.map((item, key) => {
                                        return ( <option key={key} value={item.id}>{item.name}</option> )
                                    })}
                                </select> */}
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <select class="form-control form-control-sm" id="modelCode" value={status_} onChange={(e) => { setStatus_(e.target.value);}}>
                                    <option value=''>-- Status --</option>
                                    <option value='1'>Published</option>
                                    <option value='2'>Un-published</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-md-1'>
                            <div className="form-group">
                                <button className='btn btn-info btn-sm btn-block' onClick={handleFilter}>{t('stock-page.filter')}</button>
                            </div>
                        </div>
                        <div className='col-md-1'>
                            <div className="form-group">
                                <button className='btn btn-info btn-sm btn-block' onClick={handleExcel}>{t('stock-page.export')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )      
}

function CarList() {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const [listingList, setListingList] = useState([]);
    const [refresh, setRefresh] = useState(false);

    const [currPage, setCurrPage] = useState(1);
    const [page_, setPage_] = useState(1);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    const [showFilter, setShowFilter] = useState(true)
    const handleFilter = () => setShowFilter(!showFilter)

    const [searchQuery, setSearchQuery] = useState([]);

    const goToTop = () => {
        window.scrollTo({
            top: 0, //700
            behavior: 'smooth',
        });
    };

    //Next Page
    const nextPage = () => {
        if (hasNext)
            setPage_(currPage + parseInt('1'));
    }

    //Previous Page
    const prevPage = () => {
        if (hasPrev)
            setPage_(currPage - parseInt('1'));
    }

    const gotoPage = (page_) => {
        if (hasNext)
            setPage_(parseInt(page_));
    }

    const handleSearch = () => {
        // Update API endpoint with new search query parameters
        //const newApiUrl = `${process.env.REACT_APP_API_END_POINT}/api/listing?title=${searchQueries.title}&make=${searchQueries.make}&bodytype=${searchQueries.bodytype}&steering=${searchQueries.steering}&price=${searchQueries.price}&page=${page_}`;
        
        //const newApiUrl = `${process.env.REACT_APP_API_END_POINT}/api/listing?title=${searchQueries.title}&make=${searchQueries.make}&bodytype=${searchQueries.bodytype}&steering=${searchQueries.steering}&page=${page_}`;
        //const newApiUrl = `${process.env.REACT_APP_API_END_POINT}/api/listing?page=${page_}`;
        
        setListingList([]); // Clear previous filtered data
        // Fetch new data from API with updated search query parameters
        // fetch(newApiUrl)
        //     .then((response) => response.json())
        //     .then((data) => {
        //         setListingList(data.data);
        //         setCurrPage(parseInt(data.curr_page));
        //         setHasNext(data.hasNext);
        //         setHasPrev(data.hasPrev);
        //     });

        CrudService.GetDataWithParam(navigate, location, 'listing/extended', 
        { 
            page: page_, 
            //ref_no: searchQuery.refNo,
            //vin: searchQuery.vin,
            //title: searchQuery.title,
            keyword: searchQuery.keyword,
            make: searchQuery.make,
            category: searchQuery.category,
            status: searchQuery.status
        })
        .then((res) => {
            console.log(res.data);
            setListingList(res.data.data);
            setCurrPage(parseInt(res.data.curr_page));
            setHasNext(res.data.hasNext);
            setHasPrev(res.data.hasPrev);
            setRowCount(res.data.count);
        })
    }
    
    useEffect(() => {
        //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/listing`)
        CrudService.GetDataWithParam(navigate, location, 'listing/extended', { availability: 1 })
        .then((res) => {
            console.log(res.data);
            setListingList(res.data.data);
            setCurrPage(parseInt(res.data.curr_page));
            setHasNext(res.data.hasNext);
            setHasPrev(res.data.hasPrev);
            setRowCount(res.data.count);
        })
    }, []);

    const handleEdit = (e, id) => {
        
        e.preventDefault();
        console.log('ID : '+ id);
        // <Link to={`${process.env.PUBLIC_URL}/addList/${id}`}>T</Link>
        navigate(`${process.env.PUBLIC_URL}/add-list/${id}`);

    }

    const handleView = (e, id) => {  
        e.preventDefault();
        console.log('ID : '+ id);
        navigate(`${process.env.PUBLIC_URL}/view-list/${id}`);

    }

    const handleDelete = (e, id, refNo) => {   
        e.preventDefault();

        Swal.fire({
            icon: 'warning',
            iconColor: '#dc3545',
            title: 'Are you sure you want to Delete ?',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            confirmButtonColor: '#dc3545',
            //cancelButtonText: 'No'
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                
                CrudService.DeleteData(navigate, location, 'listing', id, { ref_no: refNo })
                .then((res) => {
                    toast.success("Stock Delete " + res.data);
                    console.log('Deleted :' + res);
                    handleSearch();
                    //setRefresh(!refresh);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error);
                })

            } 
        })

    }
    

    const handleSold = (e, id) => {
        e.preventDefault();
        console.log('ID : '+ id);
        navigate(`${process.env.PUBLIC_URL}/sell-listing/${id}`);
        //navigate(`${process.env.PUBLIC_URL}/sales-order/${id}`);
        
    }

    const handleDismantle = (e, id) => {
        e.preventDefault();
        console.log('ID : '+ id);

        Swal.fire({
            icon: 'warning',
            title: 'Are you sure want to Dismantle ?',
            showCancelButton: true,
            confirmButtonText: 'Dismantle',
            confirmButtonColor: '#ffc107',
            //cancelButtonText: 'No'
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                
                let data = {};
                CrudService.PutData(navigate, location, 'listing/dismantle', id, data)
                .then((res) => {
                    console.log('Dismantle :' + res);
                    //setRefresh(!refresh);
                    handleSearch();
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error);
                })

            } 
        })
        //navigate(`${process.env.PUBLIC_URL}/sell-listing/${id}`);
    }

    const handleShipped = (e, id) => {
        e.preventDefault();
        console.log('handleShipped ID : '+ id);

        Swal.fire({
            icon: 'question',
            title: 'Are you sure want to mark as Shipped ?',
            showCancelButton: true,
            confirmButtonText: 'Shipped',
            confirmButtonColor: '#28a745',
            //cancelButtonText: 'No'
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                
                let data = {};
                CrudService.PutData(navigate, location, 'listing/shipped', id, data)
                .then((res) => {
                    console.log('Shipped :' + res);
                    //setRefresh(!refresh);
                    handleSearch();
                })
                .catch((error) => {
                    console.error(error);
                    toast.error('' + error);
                })

            } 
        })
    }


    useEffect(() => {
        handleSearch();
        setPage_(parseInt(1));
    },
    [searchQuery])

    useEffect(() => {
        handleSearch();
    },
    [page_])

    const pages = [];

    for (let i = 1; i <= (rowCount/10) + 1; i++) {
		pages.push(
			<li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(i)}>{i}</a></li>
		);
	}

    /*const [pageSize, setPageSize] = useState(10);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = useCallback(async ({ pageIndex }) => {
        setLoading(true);
        try {
            const response = await axios.get(
                `https://api.example.com/data?page=${pageIndex}&pageSize=${pageSize}`
            );
            setData(response.data);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }, [pageSize]);

    const columns = useMemo(
        () => [{ Header: 'Name', accessor: 'name', }, { Header: 'Email', accessor: 'email', }, { Header: 'Phone', accessor: 'phone', },],
        []
    );*/

    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0"><NavLink to="/" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; {t('stock-page.stock-page')}</h1>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <NavLink exact to="/" activeClassName="selected" >Dashboard</NavLink></li>
                                <li className="breadcrumb-item active">Stock List</li>
                            </ol>
                        </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>{/* /.container-fluid */}
            </div>

            { showFilter && <FilterDiv navigate={navigate} locationHook={location} searchQuery={setSearchQuery} t={t} /> }

            <section class="content">
                <div className="card">
                    <div className="card-header">
                        {/* <h3 className="card-title">Car List Table</h3> */}
                        <div className="card-tools">
                            <div className='btn btn-tool'>
                                <button type="button" class="btn btn-block btn-outline-warning btn-sm" onClick={handleFilter}><i class="fas fa-search fa-sm"></i></button>
                            </div>
                            <div className='btn btn-tool'>
                                <NavLink exact to={`${process.env.PUBLIC_URL}/add-list`} activeClassName="selected" >
                                    <button type="button" class="btn btn-block btn-outline-success btn-sm"><i class="fas fa-plus fa-sm"></i>&nbsp;{t('stock-page.add-list')}</button>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body table-responsive p-0">
                        <table className="table table-hover text-nowrap" style={{ minHeight: '300px' }}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>{t('stock-page.ref')} #</th>
                                    <th></th>
                                    <th>{t('stock-page.title')}</th>
                                    <th>{t('stock-page.vin')}</th>
                                    <th>{t('stock-page.category')}</th>
                                    <th>{t('stock-page.make')}</th>
                                    <th>{t('stock-page.model-code')}</th>
                                    <th style={{ textAlign: 'center'}}>{t('stock-page.registration-year')}</th>
                                    {/* <th>{t('stock-page.condition')}</th> */}
                                    <th>{t('stock-page.price')}</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                { listingList?.map((value) => {
                                    return(
                                    <tr>
                                        <td>
                                            <div class="btn-group">
                                                {/* <button className='btn btn-warning btn-xs' onClick={() => {handleEdit(value.id)}}><i class="far fa-edit"></i></button>  */}
                                                <button className='btn btn-outline-primary btn-xs' onClick={(e) => { handleView(e, value.id) }}>View</button>
                                                <div class="btn-group">
                                                    <button type="button" class="btn btn-info btn-xs dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false" style={{ zIndex: '1' }}></button>
                                                    <div class="dropdown-menu">
                                                        <a class="dropdown-item" href="" onClick={(e) => { handleSold(e, value.id) }}>Sell</a>
                                                        <a class="dropdown-item" href="" onClick={(e) => handleDismantle(e, value.id)}>Dismantle</a>
                                                        { value.shipped == 0  &&
                                                            <a class="dropdown-item" href="" onClick={(e) => handleShipped(e, value.id)}>Mark as Shipped</a>
                                                        }
                                                        <div class="dropdown-divider"></div>
                                                        <a class="dropdown-item" href="" onClick={(e) => {handleEdit(e, value.id)}}>Edit</a>
                                                        <a class="dropdown-item" href="" onClick={(e) => handleDelete(e, value.id, value.ref_no)}>Delete</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        {/* <td>{ value.sold == 1 ? <span class="badge bg-danger">Sold</span>  : <span class="badge bg-success">Available</span> }</td> */}
                                        <td>{value.ref_no}</td>
                                        <td><img src={ (value.tumbnail) ? value.tumbnail : (value.tcv_images) ? JSON.parse(value.tcv_images)[0] : null }  height={'70px'}/></td>
                                        <td>{value.title}</td>
                                        {/* <td>{value.slug}</td> */}
                                        {/* <td>{value.Location.name}</td> */}
                                        <td>{value.vin}</td>
                                        <td>{(value.Category) ? value.Category?.name : '-' }</td>
                                        <td>{(value.Make) ? value.Make?.name : '-'}</td>
                                        <td>{value.model_code}</td>
                                        <td align='center'>{value.r_year}</td>
                                        {/* <td>{value.r_montd}</td> */}
                                        {/* <td>{value.m_year}</td> */}
                                        {/* <td>{value.m_montd}</td> */}
                                        {/* <td>{value.grade}</td> */}
                                        {/* <td>{ value.vcondition == 1 ? <span class="badge bg-success">New</span>  : <span class="badge bg-secondary">Used</span> }</td> */}
                                        {/* <td>{value.mileage}</td> */}
                                        {/* <td>{value.chng_odometer}</td> */}
                                        <td><span class="badge bg-warning">¥ {Fp.formatPrice(parseInt(value.price))}</span></td> 
                                        {/* <td>{value.price_sale}</td> */}
                                        {/* <td>{value.lengtd}</td>
                                        <td>{value.widtd}</td>
                                        <td>{value.height}</td>
                                        <td>{value.color_e}</td>
                                        <td>{value.color_i}</td> */}
                                        {/* <td>{value.doors}</td> */}
                                        {/* <td>{value.FuelType.name}</td> */}
                                        {/* <td>{value.steering}</td> */}
                                        {/* <td>{value.TransmissionType.name}</td> */}
                                        {/* <td>{value.displacement}</td> */}
                                        {/* <td>{value.no_of_passengers}</td> */}
                                        {/* <td>{value.drive_type}</td> */}
                                        {/* <td>{value.features}</td> */}
                                        {/* <td>{value.gallery}</td> */}
                                        {/* <td>{value.tvc_img}</td>
                                        <td>{value.tumbnail}</td>
                                        <td>{value.body}</td> */}
                                        {/* <td>{value.featured}</td> */}
                                        {/* <td>{value.hits}</td> */}
                                        <td>{(value.status == 1) ? <span class="badge bg-success">Published</span> : <span class="badge bg-danger">Unpublished</span> }</td>
                                        {/* <td>{value.created_by}</td> */}
                                        {/* <td>{value.createdAt}</td> */}
                                        {/* <td>{value.updated_by}</td> */}
                                        {/* <td>{value.updatedAt}</td> */}
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/* /.card-body */}
                    <div className='card-footer clearfix'>
                        {/* <div className="center-block text-center">
                            <ul className="pagination mb-3">
                                { (hasPrev == 'true') &&
                                <li className={"page-item page-prev "+ ( hasPrev ? " " : "disabled")}>
                                    <a className="page-link" href="javascript:void(0);" tabindex="-1" onClick={ () => prevPage()}>Prev</a>
                                </li>
                                }
                                { (hasNext == 'true') &&
                                    <li className={"page-item page-next "+ ( hasNext ? " " : "disabled")}>
                                        <a className="page-link" href="javascript:void(0);" onClick={ () => nextPage()}>Next</a>
                                    </li>
                                }
                            </ul>
                        </div> */}
                        <ul class="pagination pagination-sm">
                            { (hasPrev == 'true') &&
                                <li class="page-item"><a class="page-link" href="javascript:void(0);" tabindex="-1" onClick={ () => prevPage()}>«</a></li>
                            }
                            {pages}
                            {/* <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(1)}>1</a></li>
                            <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(2)}>2</a></li>
                            <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(3)}>3</a></li> */}
                            { (hasNext == 'true') &&
                                <li class="page-item"><a class="page-link" href="javascript:void(0);" onClick={ () => nextPage()}>»</a></li>
                            }
                        </ul>
                    </div>
                </div>
            </section>
            <ToastContainer 
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" 
            />
        </div>
    )
}

export default CarList