import React from 'react'
import Placeholder from '../components/home/placeholder';
import SideNav from '../components/home/sideNav';
import Footer from '../components/home/footer';
import StockHistory from '../components/listing/stockHistory';

export const StockHistoryPage = () => (
    <div class="wrapper">
        <Placeholder />
        <SideNav />
        <StockHistory />
        <Footer />
    </div>
);