import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import CrudService from '../../util/crudService'
import { Cost } from '../salesOrder/cost';
import Select from 'react-select'

function AddListing() {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [id, setId] = useState(0);
    const [makeId, setMakeId] = useState(0);
    const [price, setPrice] = useState(0);
    const [priceSale, setPriceSale] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [amountPaid, setAmountPaid] = useState(0);
    const [remainingAmount, setRemainingAmount] = useState(0);

    const [listingData, setListingData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [vendorId, setVendorId] = useState(0);

    const [currency, setCurrency] = useState(0);
    const [lockCurrency, setLockCurrency] = useState(false);
    const [exrate, setExrate] = useState(() => FetchExchangeRate());
    const [type, setType] = useState(0);

    const [vendorList, setVendorList] = useState([]);
    const [costList, setCostList] = useState([]);

    function FetchExchangeRate() {
        axios.get(`https://openexchangerates.org/api/latest.json?app_id=955708c18bae414d9f818c87d3364969&base=JPY&symbols=USD`)
        .then((res) => {
            console.log(res.data);
            setExrate(res.data.rates.USD);
            return res.data.rates.USD;
        })
        .catch((err) => console.error(err))
    }

    useEffect(() => {
        //Vendor List
        //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/vendor?page=${0}&pageSize=${20}`)
        CrudService.GetData(navigate, location, 'vendor/basic')
        .then((res) => {
            setVendorList(res.data);
        })

        if (params.id) {
            setId(params.id);
            //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/listing/${params.id}`)
            CrudService.GetDataById(navigate, location, 'listing', params.id)
            .then((res) => {
                console.log(res.data);
                setListingData(res.data);
                // setTitle(res.data.title);
                setPrice(res.data.price);
                setPriceSale(res.data.price_sale);
            })
            .catch((err) => {
                alert(err)
            })
        }

        
    }, [params.id])

    useEffect(() => {
        //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/vendor/${vendorId}`)

        if (vendorId == 0){
            setCustomerData([]);
            return;
        }

        CrudService.GetDataById(navigate, location, 'vendor', vendorId)
        .then((res) => {
            setCustomerData(res.data);
        })
        .catch((err) => {
            console.log(err);
            alert(err)
        })

    }, [vendorId])

    useEffect(() => {
        if (currency == 2) {
            //var exrate = '143.695'; // FetchExchangeRate();
            console.log('exrate:' + exrate);
            let val_ = (parseFloat(price) * parseFloat(exrate)).toFixed(2);
            setPrice(parseFloat(val_));
            
            if (subTotal == 0){
                setSubTotal(parseFloat(val_))
            }else{
                setSubTotal((parseFloat(subTotal) * parseFloat(exrate)).toFixed(2));
            }
        }
    }, [currency])

    useEffect(() => {
        calSubTotal();
    }, [listingData, costList, discount])


    const validateData = () => {
        return (vendorId == 0 || amountPaid == 0) ? false : true;
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!validateData()) {
            alert('Please fill the required field!');
            return;
        }

        console.log('id :'+ id);
        if (id != 0){

            let refNo = listingData.ref_no;

            //axios.put(`${process.env.REACT_APP_API_END_POINT}/api/listing/sell/${id}`,
            CrudService.PutData(navigate, location, 'listing/sell', params.id,
            { 
                id, vendorId, refNo, discount, subTotal, amountPaid, remainingAmount, currency, type, costList
            })
            .then((response) => {
                console.log(response.data);
                navigate(`${process.env.PUBLIC_URL}/listing`);
            })
            .catch((error) => {
                console.log(error);
            });
        }

    };

    const calSubTotal = () => {
        //let val = (!priceSale) ? parseFloat(priceSale).toFixed(2) + parseInt(tax) : parseFloat(price).toFixed(2) + parseInt(tax); 

        //let val = (priceSale != null) ? parseInt(priceSale) + parseInt(tax) : parseInt(price) + parseInt(tax); 
        //let val = (subTotal != 0) ? parseInt(subTotal) - parseInt(tax) : subTotal; 
        //setSubTotal(val);

        //-----------
        let totoalCost = 0;

        let val = parseInt(price) - parseInt(discount); 

        costList.map((item) => {
            totoalCost += item.value;
        })
        setSubTotal(parseFloat(val) + parseFloat(totoalCost) )
    }

    const calRemaining = (amountPaid_) => {
        let val = parseInt(subTotal) - parseInt(amountPaid_);
        setRemainingAmount(val);
    }

    const handleType = (value_) => {
        if (value_ == 1) {
            setCurrency(1);
            setLockCurrency(true);
        }else{
            setCurrency(0);
            setLockCurrency(false);
        }
    }

    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Sell a Vehicle</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Car List</a></li>
                                <li className="breadcrumb-item active">Sell</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Customer</label>
                                {/* <select class="form-control" id="vendorId" value={vendorId} onChange={(e) => { setVendorId(e.target.value);}}>
                                    <option value='0'>-- Select Vendor --</option>
                                    { vendorList.map((item, index) => {
                                        return(
                                            <option value={item.id} key={index}>{item.name}</option>
                                        )
                                    })}
                                </select> */}
                                <Select options={vendorList.map((item, index) => ({ value: item.id, label: item.name }))} onChange={(e) => { console.log('e :' + e.value); setVendorId(e.value);}} />
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="form-group">
                                    <label htmlFor="title">Type</label>
                                    <select class="form-control" id="modelCode" value={type} onChange={(e) => { setType(e.target.value); handleType(e.target.value)}}>
                                        <option value='0'>-- Select Type --</option>
                                        <option value='1'>Domestic</option>
                                        <option value='2'>Export</option>
                                    </select>
                                </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="form-group">
                                <label htmlFor="slug">Currency</label>
                                <select class="form-control" id="modelCode" value={currency} onChange={(e) => { setCurrency(e.target.value);}} disabled={lockCurrency}>
                                    <option value='0'>-- Select Currency --</option>
                                    <option value='1'>JPY</option>
                                    <option value='2'>USD</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* left column */}
                        <div className="col-md-3">
                            {/* general form elements */}
                            <div className="card card-primary">
                                <form>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className='col-md-12'>
                                                <h5>Vehicle Info</h5>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-12 col-lg-12'>
                                                <hr></hr>
                                                <div><b>{ listingData.title}</b></div>
                                                <div>Ref No: { listingData.ref_no}</div>
                                                <div>VIN:    { listingData.vin}</div>
                                            </div>

                                        </div>
                                        
                                    </div>
                                    {/* /.card-body */}
                                </form>
                            </div>
                            {/* /.card */}
                            <div className="card card-primary">
                                <div className="card-body">
                                    <Cost costList={setCostList}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card card-primary">
                                <form>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className='col-md-12'>
                                                <h5>Customer Info</h5>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-12 col-lg-12'>
                                                <hr></hr>
                                                <div><b>{ customerData.name }</b></div>
                                                <div>{ customerData.address1 },</div>
                                                <div>{ customerData.address2 },</div>
                                                <div>{ customerData.city },</div>
                                                { (customerData.state) && <div>{ customerData.state },</div>}
                                                <div>{ customerData.country }</div>
                                                <div>{ customerData.postal_code }</div>
                                            </div>

                                        </div>
                                        
                                    </div>
                                    {/* /.card-body */}
                                </form>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='col-md-12'>
                                                <div className="form-group">
                                                    <label htmlFor="title">Price</label>
                                                    <input type="text" className="form-control" id="price" placeholder="" value={price} onChange={(e) => {setPrice(e.target.value); calSubTotal();}} readOnly />
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className="form-group">
                                                    <label htmlFor="title">Discount</label>
                                                    <input type="text" className="form-control" id="discount" placeholder="" value={discount} onChange={(e) => {setDiscount(e.target.value); calSubTotal();} } />
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className="form-group">
                                                    <label htmlFor="title">Sub Total</label>
                                                    <input type="text" className="form-control" id="subTotal" placeholder="" value={subTotal} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='col-md-12'>
                                                <div className="form-group">
                                                    <label htmlFor="title">Amount Paid</label>
                                                    <input type="text" className="form-control" id="paid" placeholder="" value={amountPaid} onChange={(e) => {setAmountPaid(e.target.value); calRemaining(e.target.value)}} />
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className="form-group">
                                                    <label htmlFor="slug">Amount Remaining</label>
                                                    <input type="text" className="form-control" id="slug" placeholder="" value={remainingAmount} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* --- */}
                                </div>
                                <div className="card-footer">
                                    <button type="submit" className="btn btn-success btn-block" onClick={handleSubmit}>Proceed</button>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    {/* /.row */}
                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>

    )
}

export default AddListing