import React, { useRef, useState, useEffect } from 'react'
import useAuth from '../../hooks/useAuth';
import axios from 'axios';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { useLocalStorage } from "../../hooks/useLocalStorage";

const Login = () => {
    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    const [userLocal, setUserLocal] = useLocalStorage("user", null);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/api/auth/login`,
                // JSON.stringify({ user, pwd }),
                {
                    email: user,
                    password: pwd
                },
                {
                    headers: { 'Content-Type': 'application/json',
                               'Access-Control-Allow-Origin': "*" },
                    // withCredentials: true
                }
            );

            const accessToken = response?.data?.token;
            //let roles = [5150]; //response?.data?.roles;
            let roles = [];
            roles.push(response?.data?.permission_set);
            const userId = response?.data?.id;

            setAuth({ user, pwd, roles, accessToken });
            setUserLocal({ user, userId, roles, accessToken });
            setUser('');
            setPwd('');

            navigate(from, { replace: true });

        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

    const HandleLogin = (e) => {
        e.preventDefault();
        const [user, setUser] = useLocalStorage("user", "12345");
    }

    return (
        <div className="login-box">
            <div className="card card-outline card-primary">
                <div className="card-header text-center">
                    <img src='/logo.png' width={'80%'} /><br />
                    {/* <a href="" class="h1"><b>KAGA MOTORS</b></a> */}
                </div>
                <div className="card-body">
                    <p className="login-box-msg">Sign in to access Backoffice System</p>
                    <p ref={errRef} className={'login-box-msg ' + (errMsg ? "errmsg" : "offscreen")} aria-live="assertive"><b>{errMsg}</b></p>

                    <form onSubmit={handleSubmit} method="post">
                        <div className="input-group mb-3">
                            <input type="email" class="form-control" placeholder="Email" 
                                ref={userRef} autoComplete="off"
                                onChange={(e) => setUser(e.target.value)}
                                value={user} 
                                required
                            />
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-envelope"></span>
                                </div>
                            </div>
                        </div>
                        <div class="input-group mb-3">
                            <input type="password" class="form-control" placeholder="Password" 
                                onChange={(e) => setPwd(e.target.value)}
                                value={pwd}
                                required
                            />
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-lock"></span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8">
                                {/* <div class="icheck-primary">
                                    <input type="checkbox" id="remember" />
                                    <label for="remember">
                                        Remember Me
                                    </label>
                                </div> */}
                            </div>
                            {/* <!-- /.col --> */}
                            <div class="col-12">
                                <button type="submit" class="btn btn-primary btn-block">Sign In</button>
                            </div>
                            {/* <!-- /.col --> */}
                        </div>
                    </form>

                    {/* <div class="social-auth-links text-center mt-2 mb-3">
                        <a href="#" class="btn btn-block btn-primary">
                            <i class="fab fa-facebook mr-2"></i> Sign in using Facebook
                        </a>
                        <a href="#" class="btn btn-block btn-danger">
                            <i class="fab fa-google-plus mr-2"></i> Sign in using Google+
                        </a>
                    </div> */}
                    {/* <!-- /.social-auth-links --> */}

                    {/* <p class="mb-1">
                        <a href="forgot-password.html">I forgot my password</a>
                    </p> */}
                    <p class="mb-0">
                        {/* <a href="register.html" class="text-center">Register a new membership</a> */}
                        {/* <Link to="/register" class="text-center">Sign Up</Link> */}
                    </p>
                </div>
                {/* <!-- /.card-body --> */}
            </div>
            {/* <!-- /.card --> */}
        </div>
    )
}


export default Login;