import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useLocalStorage } from "../hooks/useLocalStorage";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();
    const [userLocal, setUserLocal] = useLocalStorage('user');
    
    if (userLocal?.roles?.find(role => allowedRoles?.includes(role))){
        console.log('userLocal?.roles: ' + userLocal?.roles);
        console.log('allowedRoles: ' + allowedRoles);
    }

    return (
        (userLocal?.roles?.find(role => allowedRoles?.includes(role)) && (userLocal.accessToken || auth?.accessToken)) //auth?.roles?.find(role => allowedRoles?.includes(role)
            ? <Outlet /> 
            : auth?.user || userLocal?.user //auth?.user
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;