import React from 'react'
import Placeholder from '../components/home/placeholder';
import SideNav from '../components/home/sideNav';
import Footer from '../components/home/footer';
import SalesReport from '../components/report/salesReport';

export const SalesReportPage = () => (
    <div class="wrapper">
        <Placeholder />
        <SideNav />
        <SalesReport />
        <Footer />
    </div>
);