import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import CrudService from '../../../util/crudService'
import { toast } from 'react-toastify';

export const CostModal = (props) => {
    const locationHook = useLocation();
    const navigate = useNavigate();
    const t = props.t;

    const [name, setName] = useState('');
    const [value, setValue] = useState(0);
    const [costList, setCostList] = useState([]);
    //const [detailList, setDetailList] = useState([]);

    const handleAdd = (e) => {
        e.preventDefault();

        try {
            const data = {
                name: name, 
                value: parseFloat(value),
                cost_type: 3,
            }
    
            let data_ = [...costList, data];

            data_?.map((item, index) => {
                console.log(index + ' Cost item :' + item.value);
            })

            setCostList(data_);
            setValue(0);
            props.costList(data_);
            props.refresh(Math.random());
        } catch (error) {
            console.error(error);
            alert('Error at Cost Manger : ' + error);
        }finally{
            setName('');
            //setValue(0);
        }

        //setDetailList(detailList => [...detailList, data]);

        if (props.refNo && props.refNo != null) {
            CrudService.PostData(navigate, locationHook, 'cost', 
            { 
                ref_no: props.refNo,
                name: name, 
                value: value,
                cost_type: 3,
            })
            // .then((res) => {
            //     fetchData();
            //     props.refresh(true);
            // })
            .catch((err) => {
                console.error(err);
            })
        }
    }

    const resetFields = () => {
        setName('')
        setValue(0)
    }

    const fetchData = () => {
        CrudService.GetDataWithParam(navigate, locationHook, 'cost/all', {ref_no: props.refNo})
        .then((res) => {
            setCostList(res.data);
        })
        .catch((err) => {
            console.error(err);
        })
    } 

    const handleDelete = (index_, id_) => {
        if (window.confirm('Are you sure want to delete this Cost ?')){
            
            let data_ = costList.filter((item, index) => index !== index_);
            setCostList((costList) =>
                //costList.filter((item, index) => index !== index_)
                data_
            );

            props.costList(data_);
            props.refresh(Math.random());

            if (props.refNo && props.refNo != null && id_ != 0) {

                CrudService.DeleteData(navigate, locationHook, 'cost', id_)
                .then((res) => {
                    props.refresh(Math.random());
                })
                .catch((err) => {
                    console.error(err);
                })
            }
        }
    }

    useEffect(() => {
        console.log('On mount Cost Modal')
        setCostList(props.data);
    }, [props.data])

    return (
        // //modal fade
        <div class="modal fade" id="modal-cost"> 
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Cost Manager</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="slug" placeholder="Cost Name" value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="title" placeholder="Value" value={value} onChange={(e) => setValue(e.target.value)} />
                                </div>
                            </div>
                            <div className='col-md-2'>
                                <div className="form-group">
                                    <button className='btn btn-success' onClick={handleAdd}><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;&nbsp;Add</button>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-12">
                                <table className='table table-hover text-nowrap'>
                                    <tr>
                                        <th>{t('container-page.cost')}</th>
                                        <th style={{ textAlign: 'right' }}>{t('container-page.amount')}</th>
                                        <th></th>
                                    </tr>
                                    <tbody>
                                        { costList.map((item, index) => {
                                            return(
                                                <tr>
                                                    <td>{item.name}</td>
                                                    <td align='right'>{parseInt(item.value).toLocaleString()}</td>
                                                    <td><button className='btn btn-xs btn-outline-danger' onClick={() => handleDelete(index, (item.id) ? item.id : 0)}><i class="far fa-trash-alt"></i></button></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                        {/* <button type="button" class="btn btn-primary" onClick={handleDocSubmit} >Submit</button> */}
                    </div>
                </div>
                {/* <!-- /.modal-content --> */}
            </div>
            {/* <!-- /.modal-dialog --> */}
        </div>
        //    <!-- /.modal -->
    )
}
