import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useLocalStorage } from "../../hooks/useLocalStorage";
import i18next from 'i18next';

function Placeholder() {
    const [userLocal, setUserLocal] = useLocalStorage('user');
    const navigate = useNavigate();

    var curr_lang = '';

    function handleClick(lang) {
        i18next.changeLanguage(lang)
        
        curr_lang = i18next.language;
        console.log('curr_lang :' + curr_lang);
    }

    const logOut = (e) => {
        e.preventDefault();

        console.log('In logOut')
        setUserLocal(null);
        navigate("/login");
    };

    return (
        // navbar-white navbar-light  navbar-dark
        <nav className="main-header navbar navbar-expand navbar-dark">
            {/* Left navbar links */}
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                    <NavLink exact to="/" activeClassName="selected" className="nav-link">Home</NavLink>
                </li>
            </ul>
            {/* Right navbar links */}
            <ul className="navbar-nav ml-auto">
                {/* <!-- Language Dropdown Menu --> */}
                <li className="nav-item dropdown">
                    <a className="nav-link" data-toggle="dropdown" href="#" >
                        { (curr_lang == 'en') ? <i className="flag-icon flag-icon-gb"></i> : <i className="flag-icon flag-icon-jp" style={{ boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2" }}></i> }
                        {/* { (curr_lang == 'en') ? "EN" : "JP" } */}
                        {/* <i className="flag-icon flag-icon-gb"></i> */}
                    </a>
                    <div className="dropdown-menu dropdown-menu-right p-0">
                        <a href="javascript:void(0);" className="dropdown-item" onClick={() => handleClick('en')}>
                            <i className="flag-icon flag-icon-gb mr-2"></i> English
                        </a>
                        <a href="javascript:void(0);" className="dropdown-item" onClick={() => handleClick('jp')}>
                            <i className="flag-icon flag-icon-jp mr-2"></i> Japanese
                        </a>
                    </div>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                        <i className="fas fa-expand-arrows-alt" />
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#" role="button" onClick={(e) => logOut(e)}>
                        <i className="fas fa-sign-out-alt" />
                    </a>
                </li>
            </ul>
        </nav>

    )
}

export default Placeholder
