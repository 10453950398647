import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import CrudService from '../../util/crudService'
import Select from 'react-select'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useTranslation } from 'react-i18next';

const MySwal = withReactContent(Swal);

const customControlStyles = base => ({
    ...base,
    height: 35,
    minHeight: 20,
    fontSize: '.875rem',
});

const FilterDiv = (props) => {
    const t = props.t;

    const [orderId_, setOrderId_] = useState('');
    const [orderDate_, setOrderDate_] = useState('');
    const [orderDateTo_, setOrderDateTo_] = useState('');
    const [status_, setStatus_] = useState('');
    const [reference_, setReference_] = useState('');

    let navigate = props.navigation;
    let locationHook = props.locationHook;

    const handleFilter = () => {
        let data = { containerId: orderId_, orderDate: orderDate_, orderDateTo: orderDateTo_, status: status_, reference: reference_ }
        props.searchQuery(data);
    }

    const handleExcel = async (e) => {

        try {
            const response = await CrudService.GetFileData(navigate, locationHook, 'sale/export-excel', 
            {
                order_id: orderId_,
                order_date: orderDate_,
                order_date_to: orderDateTo_,
                status: status_
            });

            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'sales_report.xlsx');
            document.body.appendChild(link);
            link.click();

        } catch (error) {
            console.error('Error downloading the Excel file:', error);
        }
    }

    const handleClear = () => {
        setOrderDate_('');
        setOrderDateTo_('');
        handleFilter();
    }

    return (
        <section class="content">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        {/* <div className='col-md-1'>
                            <div className="form-group">
                                <input type="text" className="form-control form-control-sm" id="orderId" placeholder="Container Id" value={orderId_} onChange={(e) => setOrderId_(e.target.value)}/>
                            </div>
                        </div> */}
                        <div className='col-md-2'>
                            <div className="form-group">
                                <input type="text" className="form-control form-control-sm" id="reference" placeholder={t('order-pages.reference')} value={reference_} onChange={(e) => setReference_(e.target.value)}/>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <input type="date" className="form-control form-control-sm" id="orderDateFrm" placeholder="Date From" value={orderDate_} onChange={(e) => setOrderDate_(e.target.value)}/>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <input type="date" className="form-control form-control-sm" id="orderDateTo" placeholder="Date To" value={orderDateTo_} onChange={(e) => setOrderDateTo_(e.target.value)}/>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <select class="form-control form-control-sm" id="status" value={status_} onChange={(e) => { setStatus_(e.target.value);}}>
                                    <option value=''>-- Status --</option>
                                    <option value='1'>Preparing</option>
                                    <option value='2'>In-Transit</option>
                                    <option value='3'>Completed</option>
                                    <option value='0'>Cancelled</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-md-1'>
                            <div className="form-group">
                                <button className='btn btn-info btn-sm btn-block' onClick={handleFilter}>{t('stock-page.filter')}</button>
                            </div>
                        </div>
                        <div className='col-md-1'>
                            <div className="form-group">
                                <button className='btn btn-info btn-sm btn-block' onClick={handleExcel}>{t('stock-page.export')}</button>
                            </div>
                        </div>
                        <div className='col-md-1'>
                            <div className="form-group">
                                <button className='btn btn-info btn-sm btn-block' onClick={handleClear}>Clear</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )      
}

function COntainerList() {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const [makeList, setMakeList] = useState([]);

    const [currPage, setCurrPage] = useState(1);
    const [page_, setPage_] = useState(1);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [showFilter, setShowFilter] = useState(true);
    const handleFilter = () => setShowFilter(!showFilter);

    const [searchQuery, setSearchQuery] = useState([]);
    
    const fetchData = () => {
        console.log('fetchData page:' + page_)
        //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/category?page=${0}&pageSize=${10}`)
        CrudService.GetDataWithParam(navigate, location, 'container/all', 
        { 
            page: page_,
            order_id: searchQuery.orderId, 
            order_date: searchQuery.orderDate,
            order_date_to:  searchQuery.orderDateTo,
            status: searchQuery.status,
            container_ref: searchQuery.reference,
        })
        .then((res) => {
            console.log(res.data);
            setMakeList(res.data.data);
            setCurrPage(parseInt(res.data.curr_page));
            setHasNext(res.data.hasNext);
            setHasPrev(res.data.hasPrev);
            setRowCount(res.data.count);
        }).catch((error) => {
            console.error(error);
        })
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [page_]);

    useEffect(() => {
        fetchData();
        setPage_(parseInt(1));
    }, [searchQuery]);

    const handleEdit = (id) => {
        console.log('ID : '+ id);
        navigate(`${process.env.PUBLIC_URL}/add-category/${id}`);
    }

    const handlePrint = (id) => {
        console.log('ID : '+ id);
        navigate(`${process.env.PUBLIC_URL}/print-invoice/${id}`);
    }
    

    //Next Page
    const nextPage = () => {
        if (hasNext)
            setPage_(currPage + parseInt('1'));
    }

    //Previous Page
    const prevPage = () => {
        if (hasPrev)
            setPage_(currPage - parseInt('1'));
    }

    const gotoPage = (page_) => {
        if (hasNext)
            setPage_(parseInt(page_));
    }

    const pages = [];

    for (let i = 1; i <= (rowCount/10) + 1; i++) {
		pages.push(
			<li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(i)}>{i}</a></li>
		);
	}

    const handleCancell = (id_) => {
        Swal.fire({
            icon: 'warning',
            title: 'Do you want to Cancell this Container?',
            //showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Cancell this Continer',
            //denyButtonText: `Cancell`,
            confirmButtonColor: 'red',
            cancelButtonText: 'No'
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                
                CrudService.PutData(navigate, location, 'container/cancel', id_, { 
                    id: id_
                })
                .then((response) => {
                    console.log(response.data);
                    //navigate(`${process.env.PUBLIC_URL}/container-list`);
                    fetchData();
                    Swal.fire('Cancelled!', '', 'success')
                })
                .catch((error) => {
                    console.log(error);
                });

            } 
            // else if (result.isDenied) {
            //     Swal.fire('Changes are not saved', '', 'info')
            // }
        })
    }

    const handleView = (e, id) => {  
        e.preventDefault();
        navigate(`${process.env.PUBLIC_URL}/container/${id}`);

    }

    const handleCancell_ = (id_) => {
        if (window.confirm('Are you sure want to Cancell this Container ?')){
            CrudService.PutData(navigate, location, 'container/cancel', id_, { 
                id: id_
            })
            .then((response) => {
                console.log(response.data);
                //navigate(`${process.env.PUBLIC_URL}/container-list`);
                fetchData();
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }

    const handlePdfExport = async (e, id_) => {
        e.preventDefault();
        try {
            const response = await CrudService.GetFileData(navigate, location, 'pdf/container', 
            {
                id: id_ //params.id
            });

            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'vehicle_information.pdf');
            document.body.appendChild(link);
            link.click();

        } catch (error) {
            console.error('Error downloading the PDF file:', error);
        }
    }

    return (

        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0"><NavLink to="/" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; Container</h1>
                            {/* <div>
                                <NavLink exact to="/addCategory" activeClassName="selected">
                                    <button type="button" class="btn btn-block btn-primary">Add Category</button>
                                </NavLink>
                            </div> */}
                        </div>
                        {/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <NavLink exact to={`${process.env.PUBLIC_URL}/`} activeClassName="selected" >Dashboard</NavLink></li>
                                <li className="breadcrumb-item active">Container List</li>
                            </ol>
                        </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>{/* /.container-fluid */}
            </div>

            { showFilter && <FilterDiv avigate={navigate} locationHook={location} searchQuery={setSearchQuery} t={t} /> }

            <section class="content">
                <div className="card">
                    <div className="card-header">
                        <div className="card-tools">
                            <div className='btn btn-tool'>
                                <button type="button" class="btn btn-block btn-outline-warning btn-sm" onClick={handleFilter}><i class="fas fa-search fa-sm"></i></button>
                            </div>
                            
                        </div>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body table-responsive p-0">
                        <table className="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                    <th></th>
                                    {/* <th onClick={e => console.log('Sort')}>#</th> */}
                                    <th>{t('container-page.container-ref')}</th>
                                    <th>{t('container-page.container-no')}</th>
                                    <th>Date</th>
                                    {/* <th>Invoice No</th> */}
                                    <th>{t('container-page.note')}</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                { makeList?.map((value) => {
                                    return (
                                        <tr>
                                            <td width={'10%'}>
                                                {/* <NavLink exact to={`/container/${value.id}`}>
                                                    <button className='btn btn-warning btn-xs' style={{ marginRight: '5px'}}>View</button>
                                                </NavLink>
                                                { value.status != 0  &&    
                                                    <button type="button" class="btn btn-danger btn-xs" style={{ marginRight: '5px'}} onClick={ () => handleCancell(value.id) }>Cancel</button>
                                                    // <NavLink exact to={`/print-invoice/${value.id}`} target='_blank'>
                                                    //     <button type="button" class="btn btn-success btn-xs">Print</button>
                                                    // </NavLink>
                                                }
                                                <button className="btn btn-outline-info btn-xs" onClick={ (e) => handlePdfExport(e, value.id) }>Export</button> */}

                                                {/* ----- */}
                                                <div class="btn-group">
                                                    <button className='btn btn-outline-info btn-xs' onClick={(e) => { handleView(e, value.id) }}>View</button>
                                                    <div class="btn-group">
                                                        <button type="button" class="btn btn-info btn-xs dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false" style={{ zIndex: '1' }}></button>
                                                        <div class="dropdown-menu">
                                                            { value.status != 0  && 
                                                                <a class="dropdown-item" href="" onClick={ () => handleCancell(value.id) }>Cancel</a>
                                                            }
                                                            <a class="dropdown-item" href="" onClick={ (e) => handlePdfExport(e, value.id) }>Export</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ----- */}
                                            </td>
                                            {/* <td>{ value.id }</td> */}
                                            <td>{ value.container_ref }</td>
                                            <td>{ value.container_no }</td>
                                            <td>{ value.order_date }</td>
                                            {/* <td>{ value.invoice_no }</td> */}
                                            <td>{ value.remark.length > 20 ? value.remark.substring(0, 20) + "..." : value.remark }</td>
                                            <td>{(value.status == 0) && <span class="badge bg-danger">Cancelled</span> || (value.status == 1) && <span class="badge bg-primary">Preparing</span> || (value.status == 2) && <span class="badge bg-warning">In-Transit</span> || <span class="badge bg-success">Completed</span>}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/* /.card-body */}
                    <div className='card-footer clearfix'>
                        <ul class="pagination pagination-sm">
                            { (hasPrev == 'true') &&
                                <li class="page-item"><a class="page-link" href="javascript:void(0);" tabindex="-1" onClick={ () => prevPage()}>«</a></li>
                            }
                            {pages}
                            { (hasNext == 'true') &&
                                <li class="page-item"><a class="page-link" href="javascript:void(0);" onClick={ () => nextPage()}>»</a></li>
                            }
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default COntainerList