import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import CrudService from '../../util/crudService';
import { ToastContainer, toast } from 'react-toastify';
import Fp from '../../util/formatPrice';

function ViewListing2() {
    const locationHook = useLocation();
    const navigate = useNavigate();

    const [id, setId] = useState(0);
    const [stockData, setStockData] = useState([]);

    const [refNo, setRefNo] = useState('');
    const [modelCode, setModelCode] = useState(0);
    const [makeId, setMakeId] = useState(0);

    const [featuresArr, setFeaturesArr] = useState([]);

    const [featureList, setFeatureList] = useState([]);

    const [imgArr, setImgArr] = useState([]);

    const params = useParams();

    useEffect(() => {
        if (params.id) {
            setId(params.id);
            //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/listing/${params.id}`)
            CrudService.GetDataById(navigate, locationHook, 'listing', params.id)
                .then((res) => {
                    console.log('data:' + res.data);
                    setStockData(res.data);
                    console.log('res.data.ref_no:' + res.data.ref_no);
                    console.log('res.data.Make:' + res.data.Make.name);
                    setRefNo(res.data.ref_no);
                    setFeaturesArr(JSON.parse(res.data.features));
                    
                    setImgArr([]);
                    if (res.data.gallery){
                        let gi_ = 0;
                        for (let i = 0; i < JSON.parse(res.data.gallery).length; i++) {
                            //console.log('Gallery:' + JSON.parse(res.data.gallery)[i]);
                            setImgArr(imgArr => [...imgArr, process.env.PUBLIC_URL + JSON.parse(res.data.gallery)[i]]);
                            gi_ = i;
                        }
                        if (res.data.tcv_images){
                            gi_ += 1;
                            for (let i = gi_; i < JSON.parse(res.data.tcv_images).length; i++) {
                                //console.log('TVC_Image:' + JSON.parse(res.data.tcv_images)[i]);
                                setImgArr(imgArr => [...imgArr, JSON.parse(res.data.tcv_images)[i]]);
                            }
                        }

                    }
                    else if (res.data.tcv_images)
                        for (let i = 0; i < JSON.parse(res.data.tcv_images).length; i++) {
                            //console.log('TVC_Image:' + JSON.parse(res.data.tcv_images)[i]);
                            setImgArr(imgArr => [...imgArr, JSON.parse(res.data.tcv_images)[i]]);
                        }

                    // JSON.parse(res.data.features).map((item) => {
                    //     checkFeatureBox(item.id);
                    // })
                })
        }

        
    }, [params.id])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_END_POINT}/api/client/feature/filtered`, {
            params: {
                features: stockData.features,
            }
        })
        .then((res) => {
            console.log(res);
            setFeatureList(res.data);
        })
    }, [stockData])

    //Body Comment
    var bodyArr = [];
    var body_ = stockData.body;
    
    if (body_)
        bodyArr = body_.split('{BR}');
    
    //End Body Comment

    const handleEdit = (e) => {
        
        e.preventDefault();
        console.log('ID : '+ params.id);
        navigate(`${process.env.PUBLIC_URL}/add-list/${params.id}`);

    }

    const handleView = (e) => {
        
        e.preventDefault();
        navigate(`${process.env.PUBLIC_URL}/view-list-extend/${params.id}`);

    }

    const handlePdfExport = async (e) => {

        try {
            const response = await CrudService.GetFileData(navigate, locationHook, 'pdf/listing', 
            {
                id: params.id
            });

            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'vehicle_information.pdf');
            document.body.appendChild(link);
            link.click();

        } catch (error) {
            console.error('Error downloading the PDF file:', error);
        }
    }

    const handlePdfExport2 = async (e) => {

        try {
            const response = await CrudService.GetFileData(navigate, locationHook, 'pdf/listing-client', 
            {
                id: params.id
            });

            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'vehicle_information_external.pdf');
            document.body.appendChild(link);
            link.click();

        } catch (error) {
            console.error('Error downloading the PDF file:', error);
        }
    }

    const handleShipped = (e) => {
        e.preventDefault();
        console.log('handleShipped ID : '+ params.id);
        if (window.confirm('Are you sure want to mark as Shipped ?')){

            let data = {};
            CrudService.PutData(navigate, locationHook, 'listing/shipped', params.id, data)
            .then((res) => {
                console.log('Shipped :' + res);
                //setRefresh(!refresh);
                toast.success('Marked as Shipped.');
            })
            .catch((error) => {
                console.error(error);
                toast.error('' + error);
            })
        }
    }

    const validateData = () => {
        return (makeId == 0 || modelCode == 0) ? false : true;
    }

    return (
        // style={{ minHeight: '1345.6px' }}
        <div className="content-wrapper" >
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1><NavLink to="/listing" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; View Stock - {(stockData.status == 1) ? <span class="badge bg-success">Published</span> : <span class="badge bg-warning">Un-Published</span>}&nbsp;{ stockData.availability == 1 ? <span class="badge bg-success">In-Stock</span> : stockData.availability == 2 ? <span class="badge bg-danger">Sold</span> : <span class="badge bg-warning">Unavailable</span> } { (stockData.shipped == 1) &&  <span class="badge bg-success">Shipped</span> }</h1>
                            
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><NavLink to="/listing">Car List</NavLink></li>
                                <li className="breadcrumb-item active">View Stock</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-12">
                            <h2>Vehicle Information Sheet - &nbsp;
                                <button className="btn btn-outline-success btn-sm" onClick={ e => handleView(e)} ><i class="fas fa-pencil fa-sm"></i>&nbsp;More Details</button> <button className="btn btn-outline-warning btn-sm" onClick={ e => handleEdit(e)}><i class="fas fa-pencil fa-sm"></i>&nbsp;Edit Stock</button> <button className="btn btn-outline-info btn-sm" onClick={ () => handlePdfExport() }>Export</button> <button className="btn btn-outline-info btn-sm" onClick={ () => handlePdfExport2() }>Export Client</button> { (stockData.shipped == 0) && <button className="btn btn-outline-success btn-sm" onClick={ (e) => handleShipped(e) }>Mark as Shipped</button> }
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        {/* Tabs */}
                        <div class="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    {/* <p>Condition : {(stockData.vcondition == '1') ? 'Used' : 'New' } </p> */}
                                    <b>Condition :</b> {(stockData.vcondition == '2') ? 'Used' : 'New' }
                                </div>
                            </div>
                            {/* ======== */}
                        </div>

                        <div class="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    {/* <p>Title : {stockData.title}</p> */}
                                    <b>Title :</b> {stockData.title}
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    {/* <p>{stockData.TransmissionType?.name} | {stockData.FuelType?.name} | {stockData.drive_type} | {stockData.steering}</p> */}
                                    {stockData.TransmissionType?.name} | {stockData.FuelType?.name} | {stockData.steering} | {stockData.drive_type}
                                </div>
                            </div>
                        </div>
                        
                        {/* Tab Ends */}
                    </div>
                    {/* Tab Row Ends */}

                    <div className="row">
                        
                        <div class="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div class="col-md-6">

                                            <dl>
                                                <dt>Ref No :</dt>
                                                <dd>{stockData.ref_no}</dd>
                                                <dt>Category :</dt>
                                                <dd>{stockData.Category?.name}</dd>
                                                <dt>Make :</dt>
                                                <dd>{stockData.Make?.name}</dd>
                                                <dt>VIN :</dt>
                                                <dd>{stockData.vin}</dd>
                                            </dl>
                                        </div>
                                        <div class="col-md-6">
                                            <dl>
                                                <dt>Model / Model Code : </dt>
                                                <dd>{stockData.Model?.name} / {stockData.model_code}</dd>
                                                <dt>Reg Year : </dt>
                                                <dd>{stockData.r_year}</dd>
                                                <dt>Man. Year : </dt>
                                                <dd>{stockData.m_year}</dd>
                                                <dt>Mileage : </dt>
                                                <dd>{stockData.mileage}</dd>
                                            </dl>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className="row">
                                <div class="col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <dl>
                                                <dt>Dimension : </dt> 
                                                <dd>{stockData.height} x {stockData.width} x {stockData.length}</dd>
                                                {/* <dt>Interior Color : </dt>
                                                <dd>{stockData.color_i}</dd> */}
                                                <dt>Exterior Color : </dt>
                                                <dd>{stockData.color_e}</dd>
                                                <dt>No of Passengers : </dt>
                                                <dd>{stockData.no_of_passengers}</dd>
                                                <dt>Displacement : </dt>
                                                <dd>{stockData.displacement}</dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            {/* <p>Features : { featureList?.map((item) => ( <a className="btn btn-light border mt-1 mb-1 icons me-1" href="javascript:void(0);"> {item.name}</a> ))}  
                                            </p> */}
                                            {/* <dl>
                                                <dt>Features : </dt>
                                                { featureList?.map((item) => ( <dd>{item.name}</dd> ))}  
                                            </dl> */}
                                            <dl>
                                                <dt>Features : </dt>
                                                <dd>
                                                    { featureList?.map((item) => ( item.name + ' | ' ))}  
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    {/* <p>Image</p> */}
                                    <img src={ (stockData.tumbnail) ? stockData.tumbnail : (stockData.tcv_images) ? JSON.parse(stockData.tcv_images)[0] : null }  height={'300px'}/>
                                </div>
                            </div>
                            {/* <div className="card">
                                <div className="card-body">
                                    <p>Man. Year : {stockData.m_year}</p>
                                </div>
                            </div> */}
                            <div className="card">
                                <div className="card-body">
                                    {/* <p>Body Comments : {stockData.body}</p> */}
                                    {/* <ul>
                                        {
                                            bodyArr.map((item) => {
                                                return (
                                                    <li>{item}</li>
                                                )
                                            })
                                        }
                                    </ul> */}
                                    {
                                        bodyArr.map((item) => {
                                            return (
                                                ' < ' + item + ' >'
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        
                        {/* Tab Ends */}
                    </div>
                    <div className="row">
                        <div class="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <dl>
                                                <dt>Price : </dt>
                                                <dd>¥ {Fp.formatPrice(parseInt(stockData.price))}</dd>
                                                <dt>Purchase Price : </dt>
                                                <dd>{stockData.purchase_price}</dd>
                                            </dl>

                                        </div>
                                        <div className='col-md-6'>
                                            <dl>
                                                <dt>Purchase Date : </dt>
                                                <dd>{(stockData.purchase_date) ? stockData.purchase_date : 'NA'}</dd>
                                                <dt>Supplier : </dt>
                                                <dd>{(stockData.supplier) ? stockData.supplier : 'NA'}</dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* Table */}
                    <div className="row" hidden>
                        <table border={1} style={{ padding: '100px' }}>
                            {/* <thead>
                                <tr>
                                    <th>Header 1</th>
                                    <th>Header 2</th>
                                    <th>Header 3</th>
                                    <th>Header 4</th>
                                </tr>
                            </thead> */}
                            <tbody>
                                <tr>
                                    <td style={{ padding: '15px' }} ><b>Condition :</b> {(stockData.vcondition == '1') ? 'Used' : 'New' }</td>
                                    <td style={{ padding: '15px' }} ><b>Title :</b> {stockData.title}</td>
                                    <td style={{ padding: '15px' }} colspan="2" >{stockData.TransmissionType?.name} <b> | </b> {stockData.FuelType?.name} <b> | </b> {stockData.steering} <b> | </b> {stockData.drive_type}</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '15px' }}>
                                        <dl>
                                            <dt>Ref No :</dt>
                                            <dd>{stockData.ref_no}</dd>
                                            <dt>Category :</dt>
                                            <dd>{stockData.Category?.name}</dd>
                                            <dt>Make :</dt>
                                            <dd>{stockData.Make?.name}</dd>
                                            <dt>VIN :</dt>
                                            <dd>{stockData.vin}</dd>
                                        </dl>
                                    </td>
                                    <td style={{ padding: '15px' }}>
                                        <dl>
                                            <dt>Model / Model Code : </dt>
                                            <dd>{stockData.Model?.name} / {stockData.model_code}</dd>
                                            <dt>Reg Year : </dt>
                                            <dd>{stockData.r_year}</dd>
                                            <dt>Man. Year : </dt>
                                            <dd>{stockData.m_year}</dd>
                                            <dt>Mileage : </dt>
                                            <dd>{stockData.mileage}</dd>
                                        </dl>
                                    </td>
                                    <td style={{ padding: '15px' }} colspan="2">
                                        <img src={ (stockData.tumbnail) ? stockData.tumbnail : (stockData.tcv_images) ? JSON.parse(stockData.tcv_images)[0] : null }  height={'250px'}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '15px' }} colspan="2">
                                        <dl>
                                            <dt>Dimension : </dt> 
                                            <dd>{stockData.height} x {stockData.width} x {stockData.length}</dd>
                                            <dt>Exterior Color : </dt>
                                            <dd>{stockData.color_e}</dd>
                                            <dt>No of Passengers : </dt>
                                            <dd>{stockData.no_of_passengers}</dd>
                                            <dt>Displacement : </dt>
                                            <dd>{stockData.displacement}</dd>
                                        </dl>
                                    </td>
                                    
                                    <td style={{ padding: '15px' }} colspan="2">
                                        <dl>
                                            <dt>Price : </dt>
                                            <dd>{stockData.price}</dd>
                                            <dt>Purchase Price : </dt>
                                            <dd>{stockData.purchase_price}</dd>
                                            <dt>Purchase Date : </dt>
                                            <dd>{(stockData.purchase_date) ? stockData.purchase_date : 'NA'}</dd>
                                            <dt>Supplier : </dt>
                                            <dd>{(stockData.supplier) ? stockData.supplier : 'NA'}</dd>
                                        </dl>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '15px' }} colspan="4">
                                        <dl>
                                            <dt>Features : </dt>
                                            <dd>
                                                { featureList?.map((item) => ( item.name + ' | ' ))}  
                                            </dd>
                                        </dl>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '15px' }} colspan="4">
                                        {
                                            bodyArr.map((item) => {
                                                return (
                                                    ' < ' + item + ' >'
                                                )
                                            })
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>

    )
}

export default ViewListing2