import React from 'react'
import Placeholder from '../components/home/placeholder';
import SideNav from '../components/home/sideNav';
import Footer from '../components/home/footer';
import {TileNav} from '../components/home/tileNav';

export const TileNavPage = () => (
    <div class="wrapper">
        <Placeholder />
        <SideNav />
        <TileNav />
        <Footer />
    </div>
);
