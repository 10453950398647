import React, { useEffect, useState } from 'react'
import CrudService from '../../../util/crudService';
import { useLocation, useNavigate } from 'react-router-dom';
import { CostModal } from './costModal';

export const Cost = (props) => {
    const locationHook = useLocation();
    const navigate = useNavigate();
    const t = props.t;

    const [costList, setCostList] = useState([]);
    const [costData, setCostData] = useState([]);

    const [refresh, setRefresh] = useState(0);

    // if (props.data) 
    //     setCostList([...costList, props.data]);

    const handleDelete = (index_, id) => {
        if (window.confirm('Are you sure want to delete this Cost ?')){
            
            if (id != 0) {
                CrudService.DeleteData(navigate, locationHook, 'cost', id)
                .catch((err) => {
                    console.error(err);
                })
            }

            setCostList((costList) =>
                costList.filter((item, index) => index !== index_)
            );
        }
    }

    // const setCostList_ = (value_) => {
    //     if (value_ != null){
    //         //setCostList((costList) => [...costList, value_]);
    //         setCostList(costList => [...costList, { id: item.id, name: item.cost, value: item.amount, cost_type: item.type }])
    //     }
    // }

    const fetchData = () => {
        if (props.orderId && props.orderId != null){
            
            CrudService.GetDataById(navigate, locationHook, 'container/detail', props.orderId)
                .then((res) => {
                    console.log(res.data);
                    setCostData([]);
                    //setCostList(res.data.cost); 
                    setCostData(res.data.cost);
                    console.log('Set Cost Data');
                });
        }
    }

    useEffect(() => {
        if (props.orderId && props.orderId != null){
            
            CrudService.GetDataById(navigate, locationHook, 'container/detail', props.orderId)
                .then((res) => {
                    console.log(res.data);
                    setCostData([]);
                    //setCostList(res.data.cost); 
                    setCostData(res.data.cost);
                    console.log('Set Cost Data');
                });
        }
    }, [])

    useEffect(() => {
        console.log(costList.length);
        //setCostList([]);
        console.log('Set CostList');
        console.log(costList.length);

        if (costData.length > 0 && costList.length == 0) {
            costData.forEach((item) => {
                console.log('item.cost ' + item.cost);
                console.log('item.amount ' + item.amount);
                setCostList(costList => [...costList, { id: item.id, name: item.cost, value: item.amount, cost_type: item.type }])
            })
        }
    }, [costData])

    useEffect(() => {
        console.log('Trig CostList');

        console.log('costList.length : ' + costList.length);
        if (costList.length >= 0) {
            costList.map((item) => {
                console.log('costList name: ' + item.name)
            })
            props.costList(costList);
        }
        fetchData();
    }, [costList])

    // useEffect(() => {
    //     console.log('Cost component');
    //     props.data.map((item) => {
    //         console.log(item.name);
    //         console.log(item.value);
    //     })

    //     if (props.data && props.data.length != 0) 
    //         setCostList([...costList, props.data]);
    // }, [props.refresh])

    return (
        <>
            <div className='row'>
                <div className='col-md-6'>
                    <div className="form-group">
                        <button className='btn btn-outline-info btn-xs' data-toggle="modal" data-target="#modal-cost">{t('container-page.manage-cost')}</button>
                    </div>
                </div>
            </div>
            <CostModal refresh={setRefresh} costList={setCostList} data={costList} refNo={props.orderId} t={t} />

            <div className='row'>
                <div className="col-md-12">
                    {/* text-nowrap */}
                    <table className='table table-hover'> 
                        <tr>
                            <th>{t('container-page.cost')}</th>
                            <th>{t('container-page.amount')}</th>
                            {/* <th></th> */}
                        </tr>
                        <tbody>
                            { costList.map((item, index) => {
                                return(
                                    <tr>
                                        <td>{item.name}</td>
                                        <td align='right'>{parseInt(item.value).toLocaleString()}</td>
                                        <td><button className='btn btn-xs btn-outline-danger' onClick={() => handleDelete(index, (item.id) ? item.id : 0)}><i class="far fa-trash-alt"></i></button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

