import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import CrudService from '../../util/crudService'
import { Cost } from './cost';
//import SelectSearch from 'react-select-search';
//import 'react-select-search/style.css'

//import Select2 from 'react-select2';
//import 'react-select2/css/select2.css';

import Select from 'react-select'
import { useTranslation } from 'react-i18next';


function SalesOrder() {
    const { t } = useTranslation();

    const [id, setId] = useState(0);
    const [price, setPrice] = useState(0);
    const [priceSale, setPriceSale] = useState(0);
    const [total, setTotal] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [amountPaid, setAmountPaid] = useState(0);
    const [remainingAmount, setRemainingAmount] = useState(0);
    const [remark, setRemark] = useState('');

    const [listingData, setListingData] = useState([]);
    //const [customerData, setCustomerData] = useState([]);
    const [vendorId, setVendorId] = useState(0);
    const [currency, setCurrency] = useState(0);
    const [type, setType] = useState(0);
    const [desc, setDesc] = useState('');
    const [itemType, setItemType] = useState(0);
    const [listingId, setListingId] = useState(0);
    const [qty, setQty] = useState(0);
    const [vin, setVin] = useState('');

    const [vendorList, setVendorList] = useState([]);
    const [costList, setCostList] = useState([]);
    const [detailList, setDetailList] = useState([]);
    const [exrate, setExrate] = useState(() => FetchExchangeRate());

    const [lockCurrency, setLockCurrency] = useState(false);

    const [totalCost_, setTotalCost_] = useState(0);
    
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
 
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]

    function FetchExchangeRate() {
        //axios.get(`https://openexchangerates.org/api/latest.json?app_id=e49e070324e8444b855386876df2c682&symbols=JPY`)
        axios.get(`https://openexchangerates.org/api/latest.json?app_id=955708c18bae414d9f818c87d3364969&base=JPY&symbols=USD`)
        .then((res) => {
            console.log(res.data);
            console.log(res.data.rates);
            console.log(res.data.rates.USD);
            setExrate(res.data.rates.USD);
            return res.data.rates.USD;
        })
        .catch((err) => console.error(err))
    }

    //When mount
    useEffect(() => {
        //Vendor List
        //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/vendor?page=${0}&pageSize=${20}`)
        CrudService.GetData(navigate, location, 'vendor/basic')
        .then((res) => {
            setVendorList(res.data);


        })
        .catch((err) => console.error(err))

        //Listing List
        CrudService.GetData(navigate, location, 'listing/basic') //, 0, 500
        .then((res) => {
            setListingData(res.data.data);
        })
        .catch((err) => console.error(err))

    }, [])

    //If Ref Id exists
    useEffect(() => {

        if (params.id) {
            setId(params.id);
            //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/listing/${params.id}`)
            CrudService.GetDataById(navigate, location, 'listing', params.id)
            .then((res) => {
                console.log(res.data);

                setItemType(1);
                setListingId(res.data.id);
                setQty(1);

                if (listingId != 0){
                    handleDetailAdd();
                }

            })
            .catch((err) => {
                alert(err)
            })
        }

        
    }, [params.id])

    //When Ref changes
    useEffect(() => {

        if (listingId != 0) {
            CrudService.GetDataById(navigate, location, 'listing', listingId)
            .then((res) => {
                setDesc(res.data.title);
                setVin(res.data.vin);

                if (currency == 2) {
                    //var exrate = '143.695'; // FetchExchangeRate();
                    console.log('exrate:' + exrate);
                    setPrice((parseFloat(res.data.price) * parseFloat(exrate)).toFixed(2));
                }else{
                    setPrice(res.data.price);
                }
                setPriceSale(res.data.price_sale);
            })
            .catch((err) => {
                alert(err)
            })
        }

        
    }, [listingId])


    const handleDetailAdd = () => {
        
        const data = {
            type: itemType,
            ref: listingId,
            desc: desc,
            qty: qty,
            price: price,
        }
        
        if (!validateDetailData(data))
        {
            alert('Invalid Data');
            return;
        }

        // if (params.id){
        //     if (currency == 2) {
        //         console.log('exrate:' + exrate);
        //         setPrice((parseFloat(res.data.price) * parseFloat(exrate)).toFixed(2));
        //     }else{
        //         setPrice(res.data.price);
        //     }
        // }

        let data_ = [...detailList, data];
        setDetailList(data_);
        setTotal(parseFloat(total) + parseFloat(data.price));
        setSubTotal(parseFloat(subTotal) + parseFloat(data.price));
        resetFields('ALL');
        setLockCurrency(true);

        (itemType == 1) && setRemark((remark) => remark + ' ' + vin + ' | ');
    }

    //Calculate Sub Total when cost list change
    useEffect(() => {
        let totoalCost = 0;
        let costCount = 0;
        costList.map((item, index) => {
            totoalCost += parseFloat(item.value);
            costCount = index;
            console.log('Total Cost : ' + totoalCost);

        })

        //console.log('F Total Cost : ' + totoalCost);

        //let temp_ = (costList[costCount].value) ? parseFloat(costList[costCount]?.value);

        //console.log('F costList[costCount]?.value : ' + costList[costCount]?.value);

        //setTotal(parseFloat(total) + (parseFloat(costList[costCount]?.value) || 0));
        //setSubTotal(parseFloat(subTotal) + (parseFloat(costList[costCount]?.value) || 0));
        //calRemaining(amountPaid);

        //setTotal(parseFloat(total) + parseFloat(totoalCost) );
        //setSubTotal(parseFloat(subTotal) + parseFloat(totoalCost) )

        //---
        setTotalCost_(totoalCost);
        //calRemaining(); //amountPaid

        calRemaining2(totoalCost);
    }, [costList])

    useEffect(() => {
        calRemaining();
    }, [subTotal])

    //Delete Detail line
    const handleDetailDelete = (index_) => {
        setDetailList((detailList) =>
            detailList.filter((item, index) => index !== index_)
        );

        console.log('Length:' + detailList.length)
        if (detailList.length <= 1){
            setLockCurrency(false);
        }

        setTotal(parseFloat(total) - (parseFloat(detailList[index_]?.price) || 0));
        setSubTotal(parseFloat(subTotal) - (parseFloat(detailList[index_]?.price) || 0));
        setAmountPaid(0);
        setDiscount(0);
        setRemainingAmount(0);
    }

    function validateDetailData(data) {
        if (data.type == 0)
            return false;
        else if (data.type == 1 && data.ref == 0)
            return false;
        else if (data.price == 0 )
            return false;
        
        return true;
    }

    const resetFields = (option_) => {
        if (option_ == 'ALL')
            setItemType(0);
        
        setDesc('');
        setListingId(0);
        setQty(0);
        setPrice(0);
    }

    const validateData = () => {
        return (vendorId == 0 || amountPaid < 0) ? false : true;
    }

    //Submit Order
    const handleSubmit = (event) => {
        event.preventDefault();

        if (!validateData()) {
            alert('Please fill the required field!');
            return;
        }

        CrudService.PostData(navigate, location, 'sale', 
        { 
            vendorId, subTotal, discount, amountPaid, remainingAmount, currency, type, remark, detailList, costList, exrate, totalCost_
        })
        .then((response) => {
            console.log(response.data);
            navigate(`${process.env.PUBLIC_URL}/sales-order-list`);
        })
        .catch((error) => {
            console.log(error);
        });

    };

    //Calculate Remaining Amount
    const calRemaining = (amountPaid_) => {

        if (amountPaid_ == null) {
            let val = (parseFloat(subTotal) + parseFloat(totalCost_)) - parseFloat(amountPaid);
            setRemainingAmount(val);
        }else {
            let val = (parseFloat(subTotal) + parseFloat(totalCost_)) - parseFloat(amountPaid_);
            setRemainingAmount(val);
        }
    }

    const calRemaining2 = (totalCost_) => {
            let val = (parseFloat(subTotal) + parseFloat(totalCost_)) - parseFloat(amountPaid);
            setRemainingAmount(val);
    }

    const calDiscount = (value_) => {
        if (total > 0)
            setSubTotal((parseFloat(subTotal)) - parseFloat(value_));
        else
            setSubTotal(0);
    }

    const handleType = (value_) => {
        if (value_ == 1) {
            setCurrency(1);
            setLockCurrency(true);
        }else{
            setCurrency(0);
            setLockCurrency(false);
        }
    }

    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1><NavLink to="/sales-order-list" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; {t('order-pages.sell-vehicle')}</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
                                <li className="breadcrumb-item active">Sales Order</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className='col-md-12'>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <div className="row">
                                        <div className='col-md-12'>
                                            <h5>Sales Order</h5>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">{t('order-pages.vendor')}</label>
                                                {/* <select class="form-control" id="modelCode" value={vendorId} onChange={(e) => { setVendorId(e.target.value);}}>
                                                    <option value='0'>-- Select Vendor --</option>
                                                    { vendorList.map((item, index) => {
                                                        return(
                                                            <option value={item.id} key={index}>{item.name}</option>
                                                        )
                                                    })}
                                                </select> */}
    
                                                <Select options={vendorList.map((item, index) => ({ value: item.id, label: (item.name_jp) ? item.name + ' / ' + item.name_jp : item.name }))} onChange={(e) => { console.log('e :' + e.value); setVendorId(e.value);}} />
                                                {/* <Select2
                                                    data={[
                                                        { text: 'bug', id: 1 },
                                                        { text: 'feature', id: 2 },
                                                        { text: 'documents', id: 3 },
                                                        { text: 'discussion', id: 4 },
                                                    ]}
                                                    options={{
                                                        placeholder: 'search by tags',
                                                    }}
                                                /> */}
                                                {/* <SelectSearch options={options} value="sv" name="language" placeholder="Choose your language"/> */}
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">{t('order-pages.type')}</label>
                                                <select class="form-control" id="modelCode" value={type} onChange={(e) => { setType(e.target.value); handleType(e.target.value)}}>
                                                    <option value='0'>-- Select {t('order-pages.type')} --</option>
                                                    <option value='1'>Domestic</option>
                                                    <option value='2'>Export</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="slug">{t('order-pages.currency')}</label>
                                                <select class="form-control" id="modelCode" value={currency} onChange={(e) => { setCurrency(e.target.value);}} disabled={lockCurrency}>
                                                    <option value='0'>-- Select {t('order-pages.currency')} --</option>
                                                    <option value='1'>JPY</option>
                                                    <option value='2'>USD</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="slug">{t('order-pages.reference')}</label>
                                                <input type="text" className="form-control" id="desc" placeholder="" value={remark} onChange={(e) => setRemark(e.target.value)} />
                                                {/* <textarea type="text" className="form-control" id="desc" placeholder="" value={remark} onChange={(e) => setRemark(e.target.value)}/> */}
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-8'>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <div className='row'>
                                        <table className='col-12'>
                                            <tr>
                                                <th>{t('order-pages.type')}</th>
                                                <th>{t('order-pages.ref')}</th>
                                                <th>{t('order-pages.description')}</th>
                                                <th>{t('order-pages.qty')}</th>
                                                <th>{t('order-pages.price')}</th>
                                                <th></th>
                                            </tr>
                                            <tbody>
                                                <tr>
                                                    <td width={'15%'}>
                                                        <select class="form-control" id="modelCode" value={itemType} onChange={(e) => { setItemType(e.target.value); (e.target.value == 1) ? setQty(1) : resetFields(''); }}>
                                                            <option value='0'>-- Select Type --</option>
                                                            <option value='1'>Inventory</option>
                                                            <option value='2'>Non-Inv</option>
                                                        </select>
                                                    </td>
                                                    <td width={'20%'}>
                                                        {/* { itemType != 2 && */}
                                                        {/* <select class="form-control" id="modelCode" value={listingId} onChange={(e) => { setListingId(e.target.value); (e.target.value != 0 && itemType == 0) ? setItemType(1) : void(0); (e.target.value != 0 && qty != 1) ? setQty(1) : void(0) }} disabled={(itemType == 2 ? true : false)}>
                                                            <option value='0'>-- Select Resource --</option>
                                                            {
                                                                listingData?.map((item, index) => {
                                                                    return(
                                                                        <option value={item.id} key={index}>{item.ref_no} | {item.title}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select> */}
                                                        {/* } */}
                                                        { itemType != 2 &&  
                                                            <Select options={listingData.map((item, index) => ({ value: item.id, label: item.ref_no + ' | ' + item.title}))} onChange={(e) => { console.log('e :' + e.value); setListingId(e.value); (e.value != 0 && itemType == 0) ? setItemType(1) : void(0); (e.value != 0 && qty != 1) ? setQty(1) : void(0)}} disabled={(itemType == 2 ? true : false)} />
                                                        }
                                                    </td>
                                                    <td>
                                                        <input type="text" className="form-control" id="desc" placeholder="" value={desc} onChange={(e) => { setDesc(e.target.value) } } />
                                                    </td>
                                                    <td width={'10%'}>
                                                        <input type="number" className="form-control" id="qty" placeholder="" value={qty} onChange={(e) => { setQty(e.target.value) }} disabled={(itemType == 1 ? true : false)} />
                                                    </td>
                                                    <td>
                                                        <input type="text" className="form-control" id="price" placeholder="" value={price} onChange={(e) => { setPrice(e.target.value) } } />
                                                    </td>
                                                    <td>
                                                        <button className='btn btn-success' onClick={handleDetailAdd}> <i class="fas fa-plus"></i></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><hr></hr></td>
                                                    <td><hr></hr></td>
                                                    <td><hr></hr></td>
                                                    <td><hr></hr></td>
                                                    <td><hr></hr></td>
                                                    
                                                </tr>
                                                {
                                                    detailList?.map((item, index) => {
                                                        return(
                                                            <tr>
                                                                <td>
                                                                    <select class="form-control" id="modelCode" value={item.type} onChange={(e) => { item.type = e.target.value }} disabled={true}>
                                                                        <option value='0'>-- Select Type --</option>
                                                                        <option value='1'>Inventory</option>
                                                                        <option value='2'>Non-Inv</option>
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    {/* onChange={(e) => { item.ref = e.target.value }} disabled={(item.type == 2 ? true : false)} */}
                                                                    <select class="form-control" id="refNo" value={item.ref} disabled={(item.type == 2 ? true : false)} > 
                                                                        <option value='0'>-- Select Ref --</option>
                                                                        {
                                                                            listingData?.map((item, index) => {
                                                                                return(
                                                                                    <option value={item.id} key={index}>{item.ref_no} | {item.title}</option>
                                                                                )
                                                                        })}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <input type="text" className="form-control" id="title" placeholder="" value={item.desc} onChange={(e) => { item.desc = e.target.value;} } />
                                                                </td>
                                                                <td>
                                                                    <input type="number" className="form-control" id="title" placeholder="" value={item.qty} onChange={(e) => { item.qty = e.target.value;} } disabled={(item.type == 1 ? true : false)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" className="form-control" id="title" placeholder="" value={item.price} onChange={(e) => { item.price = e.target.value } } />
                                                                </td>
                                                                <td>
                                                                    <button className='btn btn-danger' onClick={() => handleDetailDelete(index)}><i class="fas fa-trash"></i></button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        
                                    </div>
                                    {/* --- */}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <Cost costList={setCostList} data={costList} t={t} />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    {/* /.row */}
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <div className='row'>
                                        {/* <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="title">Total</label>
                                                <input type="text" className="form-control" id="title" placeholder="" value={total + totalCost_} readOnly />
                                            </div>
                                        </div> */}
                                        {/* <div className='col-md-2'>
                                            <div className="form-group">
                                                <label htmlFor="slug">Discount</label>
                                                <input type="number" className="form-control" id="discount" placeholder="" value={discount} onChange={(e) => { setDiscount(e.target.value); calDiscount(e.target.value) }} />
                                            </div>
                                        </div> */}
                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="title">{t('order-pages.sub-total')}</label>
                                                <input type="text" className="form-control" id="title" placeholder="" value={subTotal + totalCost_} readOnly />
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="title">{t('order-pages.amount-paid')}</label>
                                                <input type="text" className="form-control" id="title" placeholder="" value={amountPaid} onChange={(e) => {setAmountPaid(e.target.value); calRemaining(e.target.value)}} />
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="slug">{t('order-pages.amount-remaining')}</label>
                                                <input type="text" className="form-control" id="slug" placeholder="" value={remainingAmount} readOnly />
                                            </div>
                                        </div>
                                        {/* <div class="form-check">
                                            <input class="form-check-input" type="checkbox" />
                                            <label class="form-check-label">Loream Espisum 1</label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" />
                                            <label class="form-check-label">Loream Espisum 2</label>
                                        </div> */}
                                    </div>
                                    
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <button type="submit" className="btn btn-success btn-block" onClick={handleSubmit}>{t('order-pages.proceed')}</button>
                        </div>
                    </div>

                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>

    )
}

export default SalesOrder