import React, { useState, useEffect } from 'react'
//import axios from 'axios';
import { useParams, useNavigate, useLocation, NavLink } from 'react-router-dom';
import CrudService from '../../util/crudService'
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

function AddCategory() {
    const [id, setId] = useState(0);
    const [name, setName] = useState('');
    const [slug, setSlug] = useState('');
    const [status, setStatus] = useState(1);

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        console.log(`${params.id}`);
        if (params.id) {
            setId(params.id);

            CrudService.GetDataById(navigate, location, 'category', params.id)
            //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/category/${params.id}`)
            .then((res) => {
                console.log(res.data);
                setName(res.data.name);
                setSlug(res.data.slug);
                setStatus(res.data.status);
            })
        }

    }, [params.id])

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('id :'+id);
        if (id == null || id == 0){
            CrudService.PostData(navigate, location, 'category', {name, slug, status})
            // axios.post(`${process.env.REACT_APP_API_END_POINT}/api/admin/category`, {
            //     name,
            //     slug,
            //     status
            // })
            .then((response) => {
                alert('Sucess');
                console.log(response.data);
                navigate(`${process.env.PUBLIC_URL}/category`);
            })
            .catch((error) => {
                toast.error('' + error);
                console.log(error);
            });
        } else {
            CrudService.PutData(navigate, location, 'category', id, {id, name, slug, status})
            // axios.put(`${process.env.REACT_APP_API_END_POINT}/api/admin/category/${id}`, {
            //     id,
            //     name,
            //     slug,
            //     status
            // })
            .then((response) => {
                console.log(response.data);
                navigate(`${process.env.PUBLIC_URL}/category`);
            })
            .catch((error) => {
                toast.error('' + error);
                console.log(error);
            });
        }
    };

    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1><NavLink to="/category" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; Add Category</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><NavLink exact to="/category" activeClassName="selected" >Category</NavLink></li>
                                <li className="breadcrumb-item active">Add Categories</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        {/* left column */}
                        <div className="col-md-8">
                            {/* general form elements */}
                            <div className="card card-primary">
                                {/* <div className="card-header">
                                    <h3 className="card-title">Add Category</h3>
                                </div> */}
                                {/* /.card-header */}
                                {/* form start */}
                                <form>
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Name</label>
                                                    <input type="text" className="form-control" id="name" placeholder="Enter name" value={name} onChange={(e) => setName(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Slug</label>
                                                    <input type="text" className="form-control" id="slug" placeholder="Enter slug" value={slug} onChange={(e) => setSlug(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="form-group">
                                            <label htmlFor="exampleInputFile"> Choose Image</label>
                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" id="exampleInputFile" />
                                                    <label className="custom-file-label" htmlFor="exampleInputFile">Choose file</label>
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text">Upload</span>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <div class="form-group">
                                                    <label htmlFor="exampleInputEmail1">Status</label>
                                                    <select class="form-control" id="status" value={status} onChange={(e) => { console.log(e.target.value); setStatus(e.target.value);}}>
                                                        <option value="1">Enable</option>
                                                        <option value="2">Disable</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                                    </div>
                                </form>
                            </div>
                            {/* /.card */}
                            <div className="card card-secondary" hidden>
                                <div className="card-header">
                                    <h3 className="card-title">Misc Info</h3>
                                </div>
                                {/* /.card-header */}
                                {/* form start */}
                                <form>
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Created By</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Created At</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Updated By</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Updated At</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" />
                                                </div>
                                            </div>
                                        </div>  
                                    </div>
                                    {/* /.card-body */}
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* /.row */}
                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>

    )
}

export default AddCategory