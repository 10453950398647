import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import UploadImage from './uploadImage2';
import CrudService from '../../util/crudService'
import { ToastContainer, toast } from 'react-toastify';
import { DocumentModal } from './documentModal';
import Document from './document';
import { Cost } from './cost';
import Fp from '../../util/formatPrice';

function ViewListing() {
    const locationHook = useLocation();
    const navigate = useNavigate();

    const [id, setId] = useState(0);
    const [stockData, setStockData] = useState([]);

    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [location, setLocation] = useState(0);
    const [vin, setVin] = useState('');
    const [refNo, setRefNo] = useState('');
    const [modelCode, setModelCode] = useState(0);
    const [makeId, setMakeId] = useState(0);
    const [rYear, setRYear] = useState('');
    const [rMonth, setRMonth] = useState('');
    const [mYear, setMYear] = useState('');
    const [mMonth, setMMonth] = useState('');
    const [vcondition, setVCondition] = useState('');
    const [category, setCategory] = useState('');
    const [mileage, setMileage] = useState('');
    const [price, setPrice] = useState('');
    const [priceSale, setPriceSale] = useState('');
    const [doors, setDoors] = useState('');
    const [fuel, setFuel] = useState(0);
    const [steering, setSteering] = useState('');
    const [transmission, setTransmission] = useState('');
    const [driveType, setDriveType] = useState('2W');
    const [height, setHeight] = useState('0');
    const [width, setWidth] = useState('0');
    const [length, setLength] = useState('0');
    const [eColor, setEColor] = useState('');
    const [iColor, setIColor] = useState('');
    const [displacement, setDisplacement] = useState('');
    const [seats, setSeats] = useState(0);
    const [fromCSV, setFromCSV] = useState(0);

    const [status, setStatus] = useState(1);
    const [gallery, setGallery] = useState('');
    const [thumbnail, setThumbnail] = useState('');
    const [features, setFeatures] = useState('');
    const [featuresArr, setFeaturesArr] = useState([]);

    const [initPrice, setInitPrice] = useState('');
    const [purchaseDate, setPurchaseDate] = useState('');
    const [supplier, setSupplier] = useState('');
    const [externalLink1, setExternalLink1] = useState('');
    const [externalLink2, setExternalLink2] = useState(''); 

    const [url_, setUrl_] = useState([]);
    const [makeList, setMakeList] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [featureList, setFeatureList] = useState([]);
    const [transmList, setTransmList] = useState([]);
    const [activeFeatureList, setActiveFeatureList] = useState([]);
    const [fuelTypeList, setFuelTypeList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);

    const [imgArr, setImgArr] = useState([]);

    const [docModalShow, setDocModalShow] = useState(false);
    const [rev, setRev] = useState(0);

    const params = useParams();

    const Msg = ({ closeToast, toastProps }) => (
        <div>
            Lorem ipsum dolor {toastProps.position}
            <button>Retry</button>
            <button onClick={closeToast}>Close</button>
        </div>
    )

    const displayMsg = () => {
        toast(<Msg />) 
        // toast(Msg) would also work
      }

    useEffect(() => {
        //Makes List
        //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/make?page=${0}&pageSize=${20}`)
        CrudService.GetData(navigate, locationHook, 'make')
        .then((res) => {
            setMakeList(res.data);
        })

        //Makes List
        //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/model?page=${0}&pageSize=${20}`)
        CrudService.GetData(navigate, locationHook, 'model')
        .then((res) => {
            setModelList(res.data);
        })

        //Feature List
        //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/feature?page=${0}&pageSize=${20}`)
        CrudService.GetData(navigate, locationHook, 'feature')
        .then((res) => {
            setFeatureList(res.data);
        })

        //Transmission List
        //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/transmission-type?page=${0}&pageSize=${20}`)
        CrudService.GetData(navigate, locationHook, 'transmission-type')
        .then((res) => {
            setTransmList(res.data);
        })

        //Fuel List
        //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/fuel-type?page=${0}&pageSize=${20}`)
        CrudService.GetData(navigate, locationHook, 'fuel-type')
        .then((res) => {
            setFuelTypeList(res.data);
        })

        //Location List
        //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/location?page=${0}&pageSize=${20}`)
        CrudService.GetData(navigate, locationHook, 'location')
        .then((res) => {
            setLocationList(res.data);
        })

        //Category List
        //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/category?page=${0}&pageSize=${20}`)
        CrudService.GetData(navigate, locationHook, 'category')
        .then((res) => {
            setCategoryList(res.data);
        })



        if (params.id) {
            setId(params.id);
            //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/listing/${params.id}`)
            CrudService.GetDataById(navigate, locationHook, 'listing', params.id)
                .then((res) => {
                    console.log('data:' + res.data);
                    setStockData(res.data);
                    console.log('res.data.ref_no:' + res.data.ref_no);
                    console.log('res.data.Make:' + res.data.Make.name);
                    setRefNo(res.data.ref_no);
                    setFeaturesArr(JSON.parse(res.data.features));
                    
                    setImgArr([]);
                    if (res.data.gallery){
                        let gi_ = 0;
                        for (let i = 0; i < JSON.parse(res.data.gallery).length; i++) {
                            //console.log('Gallery:' + JSON.parse(res.data.gallery)[i]);
                            setImgArr(imgArr => [...imgArr, JSON.parse(res.data.gallery)[i]]);
                            gi_ = i;
                        }
                        if (res.data.tcv_images){
                            gi_ += 1;
                            for (let i = gi_; i < JSON.parse(res.data.tcv_images).length; i++) {
                                //console.log('TVC_Image:' + JSON.parse(res.data.tcv_images)[i]);
                                setImgArr(imgArr => [...imgArr, JSON.parse(res.data.tcv_images)[i]]);
                            }
                        }

                    }
                    else if (res.data.tcv_images)
                        for (let i = 0; i < JSON.parse(res.data.tcv_images).length; i++) {
                            //console.log('TVC_Image:' + JSON.parse(res.data.tcv_images)[i]);
                            setImgArr(imgArr => [...imgArr, JSON.parse(res.data.tcv_images)[i]]);
                        }

                    // JSON.parse(res.data.features).map((item) => {
                    //     checkFeatureBox(item.id);
                    // })
                })
        }

        
    }, [params.id])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_END_POINT}/api/client/feature/filtered`, {
            params: {
                features: stockData.features,
            }
        })
        .then((res) => {
            console.log(res);
            setFeatureList(res.data);
        })
    }, [stockData])

    const handleEdit = (e, id) => {
        
        e.preventDefault();
        console.log('ID : '+ id);
        navigate(`${process.env.PUBLIC_URL}/add-list/${id}`);

    }

    const validateData = () => {
        return (makeId == 0 || modelCode == 0) ? false : true;
    }

    const handleImgDownload = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_END_POINT}/api/upload/download/${id}`, {
            responseType: 'blob'
        });

        const blob = new Blob([response.data], { type: 'application/zip' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'images.zip');
        document.body.appendChild(link);
        link.click();
}

    return (
        <div className="content-wrapper" style={{ minHeight: '1345.6px' }}>
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1><NavLink to="/listing" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; View Stock - {(stockData.status == 1) ? <span class="badge bg-success">Published</span> : <span class="badge bg-warning">UnPublished</span>}</h1>
                            
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><NavLink to="/listing">Car List</NavLink></li>
                                <li className="breadcrumb-item active">View Stock</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    {/* Tab row */}
                    <div className="row">
                        {/* Tabs */}
                        
                            <div class="col-12">
                                <div class="card card-primary card-outline card-outline-tabs">
                                    <div class="card-header p-0 border-bottom-0">
                                        <ul class="nav nav-tabs" id="custom-tabs-four-tab-1" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link active" id="custom-tabs-four-1-home-tab" data-toggle="pill" href="#custom-tabs-four-1-home" role="tab" aria-controls="custom-tabs-four-1-home" aria-selected="true">Basic Info</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="custom-tabs-four-1-profile-tab" data-toggle="pill" href="#custom-tabs-four-1-profile" role="tab" aria-controls="custom-tabs-four-1-profile" aria-selected="false">Features</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="custom-tabs-four-1-messages-tab" data-toggle="pill" href="#custom-tabs-four-1-messages" role="tab" aria-controls="custom-tabs-four-1-messages" aria-selected="false">Specs</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="custom-tabs-four-1-images-tab" data-toggle="pill" href="#custom-tabs-four-1-images" role="tab" aria-controls="custom-tabs-four-1-images" aria-selected="false">Media</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="custom-tabs-four-1-misc-tab" data-toggle="pill" href="#custom-tabs-four-1-misc" role="tab" aria-controls="custom-tabs-four-1-misc" aria-selected="false">Misc Info</a>
                                            </li>
                                            <li class="nav-item">
                                                <div className="card-tools">
                                                    <div className='btn btn-tool'>
                                                        <NavLink exact to={`${process.env.PUBLIC_URL}/add-list/${params.id}`} activeClassName="selected" >
                                                            <button type="button" class="btn btn-block btn-outline-success btn-sm"><i class="fas fa-pencil fa-sm"></i>&nbsp;Edit Stock</button>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="card-body">
                                        <div class="tab-content" id="custom-tabs-four-tab-1Content">
                                            <div class="tab-pane fade show active" id="custom-tabs-four-1-home" role="tabpanel" aria-labelledby="custom-tabs-four-1-home-tab">
                                                
                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="refNo">Ref # *</label>
                                                            <input type="text" className="form-control" id="refNo" placeholder="" value={stockData.ref_no} readOnly/>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="vin">VIN *</label>
                                                            <input type="text" className="form-control" id="vin" placeholder="" value={stockData.vin} readOnly/>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3 col-lg-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">Title</label>
                                                            <input type="text" className="form-control" id="title" placeholder="" value={stockData.title} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3 col-lg-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="slug">Slug</label>
                                                            <input type="text" className="form-control" id="slug" placeholder="" value={stockData.slug} readOnly />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="category">Category *</label>
                                                            {/* stockData.Category.name */}
                                                            <input type="text" className="form-control" id="category" placeholder="" value={(stockData.Category) ? stockData.Category.name : ''} readOnly /> 
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="category">Sub Category</label>
                                                            <input type="text" className="form-control" id="category" placeholder="" value={(stockData.SubCategory) ? stockData.SubCategory.name : ''} readOnly /> 
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div class="form-group">
                                                            <label htmlFor="makeId">Make</label>
                                                            {/* stockData.Make.name */}
                                                            <input type="text" className="form-control" id="make" placeholder="" value={(stockData.Make) ? stockData.Make.name : ''} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div class="form-group">
                                                            <label htmlFor="modelCode">Model</label>
                                                            {/* stockData.Model.name */}
                                                            <input type="text" className="form-control" id="model" placeholder="" value={(stockData.Model) ? stockData.Model.name : ''} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="location">Location</label>
                                                            {/* stockData.Location.name */}
                                                            <input type="text" className="form-control" id="location" placeholder="" value={(stockData.Location) ? stockData.Location.name : ''} readOnly />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                </div>


                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="rYear">Registration Year *</label>
                                                            <input type="number" className="form-control" id="rYear" placeholder="" value={stockData.r_year} readOnly/>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="rMonth">Registration Month *</label>
                                                            <input type="number" className="form-control" id="rMonth" placeholder="" value={stockData.r_month} readOnly/>
                                                        </div>                                               
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="mYear">Manufacture Year *</label>
                                                            <input type="number" className="form-control" id="mYear" placeholder="" value={stockData.m_year} readOnly/>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="mMonth">Manufacture Month</label>
                                                            <input type="number" className="form-control" id="mMonth" placeholder="" value={stockData.m_month} readOnly/>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">Grade</label>
                                                            <input type="text" className="form-control" id="grade" placeholder="" value={stockData.grade} readOnly />
                                                        </div>

                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="vCondition">Condition</label>
                                                            {/* <input type="text" className="form-control" id="vCondition" placeholder="" value={vcondition} onChange={(e) => setVCondition(e.target.value)} /> */}
                                                            <select class="form-control" id="vCondition" value={stockData.vcondition} readOnly >
                                                                <option value='1'>New</option>
                                                                <option value='2'>Used</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="mileage">Mileage</label>
                                                            <input type="text" className="form-control" id="mileage" placeholder="" value={stockData.mileage} readOnly />
                                                        </div>

                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">Changed Odometer</label>
                                                            <select class="form-control" id="chngOdometer" value={stockData.chng_odometer} readOnly >
                                                                <option value='1'>Yes</option>
                                                                <option value='0'>No</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label htmlFor="price">Price *</label>
                                                            <input type="text" className="form-control" id="price" placeholder="" value={Fp.formatPrice(parseInt(stockData.price))} readOnly />
                                                        </div>

                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label htmlFor="priceSale">Price Sale</label>
                                                            <input type="text" className="form-control" id="priceSale" placeholder="" value={stockData.priceSale} readOnly />
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label htmlFor="comment">Comment</label>
                                                            <textarea className="form-control" id="body" placeholder="" value={stockData.body} readOnly></textarea>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label htmlFor="comment">Comment - JP</label>
                                                            <textarea className="form-control" id="bodyJp" placeholder="" value={stockData.body_jp} readOnly></textarea>
                                                        </div>
                                                    </div>
                                                </div> 
                                                
                                                
                                            </div>
                                            {/* Features */}
                                            <div class="tab-pane fade" id="custom-tabs-four-1-profile" role="tabpanel" aria-labelledby="custom-tabs-four-1-profile-tab">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        {/* <div class="form-group"> */}
                                                            {/* <label htmlFor="exampleInputEmail1">Features</label> */}
                                                            {/* { featureList?.map((item, index) => {
                                                                let checked_ = false;
                                                                featuresArr.sort((a,b) => a.id - b.id);
                                                                if (featuresArr.includes(item.id))
                                                                    checked_ = true;

                                                                const logtest = (e) => {
                                                                    console.log('chk :' + e.target.value);
                                                                }
                                                                return ( 
                                                                    
                                                                        <div class="col-sm-4 col-md-2">
                                                                            <div class="form-check" key={index}>
                                                                                <input class="form-check-input" type="checkbox" id={item.id}  checked={checked_} />
                                                                                <label class="form-check-label">{item.name}</label>
                                                                            </div>
                                                                        </div>
                                                                    
                                                                )
                                                            })} */}

                                                            {
                                                                featureList?.map((item, index) => {
                                                                    return (
                                                                        <div class="form-check" key={index}>
                                                                            <input class="form-check-input" type="checkbox" id={item.id}  checked={true} />
                                                                            <label class="form-check-label">{item.name}</label>
                                                                        </div>
                                                                    )
                                                                })

                                                                //{ featureList?.map((item) => ( item.name + ' | ' ))}  
                                                            }
                                                        {/* </div> */}
                                                    </div>
                                                    <div class="col-sm-6">
                                                            
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Specs */}
                                            <div class="tab-pane fade" id="custom-tabs-four-1-messages" role="tabpanel" aria-labelledby="custom-tabs-four-1-messages-tab">
                                                <div className='row'>
                                                    <div className='col-md-2'>
                                                        <div className="form-group">
                                                            <label htmlFor="doors">Doors *</label>
                                                            <input type="number" className="form-control" id="doors" placeholder="" value={stockData.doors} readOnly /> 
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <div className="form-group">
                                                            <label htmlFor="fuel">Fuel *</label>
                                                            {/* stockData.FuelType.name */}
                                                            <input type="text" className="form-control" id="fuelType" placeholder="" value={(stockData.FuelType) ? stockData.FuelType.name : ''} readOnly /> 
                                                        </div>

                                                    </div>
                                                    <div className='col-md-2'>
                                                        <div className="form-group">
                                                            <label htmlFor="steering">Steering *</label>
                                                            <select class="form-control" id="steering" placeholder="" value={stockData.steering} readOnly>
                                                                <option value='Left'>Left</option>
                                                                <option value='Right'>Right</option>
                                                                <option value='Center'>Center</option>
                                                                <option value='Unspecified'>Unspecified</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="transmission">Transmission *</label>
                                                            {/* stockData.TransmissionType.name */}
                                                            <input type="text" className="form-control" id="transmissionType" placeholder="" value={(stockData.TransmissionType) ? stockData.TransmissionType.name : ''} readOnly /> 
                                                        </div>

                                                    </div>
                                                    <div className='col-md-2'>
                                                        <div className="form-group">
                                                            <label htmlFor="driveType">Drive Type</label>
                                                            <select class="form-control" id="driveType" value={stockData.drive_type} readOnly>
                                                                <option value='2W'>2W</option>
                                                                <option value='4W'>4W</option>
                                                                <option value='ALL'>ALL</option>
                                                                <option value=''>N/A</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">Length</label>
                                                            <input type="text" className="form-control" id="length" placeholder="" value={stockData.length} readOnly />
                                                        </div>

                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">Width</label>
                                                            <input type="text" className="form-control" id="width" placeholder="" value={stockData.width} readOnly />
                                                        </div>

                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">Height</label>
                                                            <input type="text" className="form-control" id="height" placeholder="" value={stockData.height} readOnly />
                                                        </div>

                                                    </div>
                                                    <div className='col-md-3'>

                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">Exterior Color</label>
                                                            <input type="text" className="form-control" id="eColor" placeholder="" value={stockData.color_e} readOnly />
                                                        </div>

                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">Interior Color</label>
                                                            <input type="text" className="form-control" id="iColor" placeholder="" value={stockData.color_i} readOnly />
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">Displacement</label>
                                                            <input type="text" className="form-control" id="displacement" placeholder="" value={stockData.displacement} readOnly />
                                                        </div>

                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">No of Passengers</label>
                                                            <input type="number" className="form-control" id="seats" placeholder="" value={stockData.no_of_passengers} readOnly />
                                                        </div>

                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            {/* Media */}
                                            <div class="tab-pane fade" id="custom-tabs-four-1-images" role="tabpanel" aria-labelledby="custom-tabs-four-1-images-tab">
                                                <div className="row">                                  
                                                    <div className='col-md-3 col-lg-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">Youtube Link</label>
                                                            <input type="text" className="form-control" id="externalLink1" placeholder="youtu.be/xYzm" value={stockData.external_link1} readOnly/>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3 col-lg-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">Facebook Link</label>
                                                            <input type="text" className="form-control" id="externalLink2" placeholder="facebook.com/lnk?p=we23" value={stockData.external_link2} readOnly/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <img
                                                            src={stockData.tumbnail} //filename
                                                            alt={stockData.tumbnail}
                                                            width={"150px"}
                                                            className="img-thumbnail"
                                                        />
                                                        <span class="badge bg-secondary">{ (fromCSV) ? 'CSV' : 'Manual' }</span>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            {/* MiscInfo */}
                                            <div class="tab-pane fade" id="custom-tabs-four-1-misc" role="tabpanel" aria-labelledby="custom-tabs-four-1-misc-tab">
                                                <div className='row'>
                                                    <div className='col-md-4 col-lg-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">Initial Price</label>
                                                            <input type="text" className="form-control" id="initialPrice" placeholder="" value={stockData.purchase_price} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 col-lg-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">Purchase Date</label>
                                                            <input type="text" className="form-control" id="purchaseDate" placeholder="" value={stockData.purchase_date} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 col-lg-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="slug">Supplier</label>
                                                            {/* <input type="text" className="form-control" id="supplier" placeholder="" value={supplier} onChange={(e) => setSupplier(e.target.value)} /> */}
                                                            <select class="form-control" id="supplier" value={stockData.supplier} readOnly>
                                                                <option value='0'>-- Select Supplier --</option>
                                                                { supplierList.map((item, index) => {
                                                                    return(
                                                                        <option value={item.id} key={index}>{item.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className='row'>
                                                    <div className='col-md-4 col-lg-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="slug">Tag</label>
                                                            <input type="text" className="form-control" id="purchaseDate" placeholder="" value={stockData.tag} readOnly />
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>                               
                                </div>
                            </div>
                        
                        {/* Tab Ends */}
                    </div>
                    {/* Tab Row Ends */}

                    {/* Tab row */}
                    <div className="row">
                        {/* Tabs */}
                        { (refNo || stockData.ref_no) &&
                            <div class="col-12">
                                <div class="card card-primary card-outline card-outline-tabs">
                                    <div class="card-header p-0 border-bottom-0">
                                        <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill" href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home" aria-selected="true">Cost</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="custom-tabs-four-profile-tab" data-toggle="pill" href="#custom-tabs-four-profile" role="tab" aria-controls="custom-tabs-four-profile" aria-selected="false">Documents</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="custom-tabs-four-messages-tab" data-toggle="pill" href="#custom-tabs-four-messages" role="tab" aria-controls="custom-tabs-four-messages" aria-selected="false">Documents - After Sale</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="custom-tabs-four-images-tab" data-toggle="pill" href="#custom-tabs-four-images" role="tab" aria-controls="custom-tabs-four-images" aria-selected="false">Images</a>
                                            </li>
                                            {/* <li class="nav-item">
                                                <a class="nav-link" id="custom-tabs-four-miscinfo-tab" data-toggle="pill" href="#custom-tabs-four-miscinfo" role="tab" aria-controls="custom-tabs-four-miscinfo" aria-selected="false">Misc Info</a>
                                            </li> */}
                                        </ul>
                                    </div>
                                    <div class="card-body">
                                        <div class="tab-content" id="custom-tabs-four-tabContent">
                                            <div class="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
                                                <Cost refNo={refNo} viewOnly={true} />
                                                
                                            </div>
                                            {/* Documents */}
                                            <div class="tab-pane fade" id="custom-tabs-four-profile" role="tabpanel" aria-labelledby="custom-tabs-four-profile-tab">
                                                
                                                <div className='row'>
                                                    <div className="col-md-12">
                                                        <Document refresh={docModalShow} rev={1} ref_no={refNo} viewOnly={true}/>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div class="tab-pane fade" id="custom-tabs-four-messages" role="tabpanel" aria-labelledby="custom-tabs-four-messages-tab">
                                                
                                                <Document refresh={docModalShow} rev={2} ref_no={refNo} viewOnly={true} />
                                            </div>
                                            {/* Image Gallary */}
                                            <div class="tab-pane fade" id="custom-tabs-four-images" role="tabpanel" aria-labelledby="custom-tabs-four-images-tab">
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <button className='btn btn-info btn-sm' onClick={() => handleImgDownload()}>Download Images</button>
                                                        <hr />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    {/* <div className='col-md-12'> */}
                                                        { imgArr.map((item, index) => {
                                                            //console.log('item-'+item);
                                                            return(
                                                                <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3' style={{ paddingBottom: 10}}>
                                                                    {/* <button className='btn btn-sm btn-danger' key={index} onClick={() => window.confirm('Are you sure You want to delete ?')}>x</button> */}
                                                                    <img src={item} width={300}></img>
                                                                </div>
                                                            )
                                                        })}
                                                    {/* </div> */}
                                                </div>
                                                
                                            </div>
                                            {/* <div class="tab-pane fade" id="custom-tabs-four-miscinfo" role="tabpanel" aria-labelledby="custom-tabs-four-miscinfo-tab">
                                                <div className='row'>
                                                    <div className='col-md-4 col-lg-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">Initial Price</label>
                                                            <input type="text" className="form-control" id="title" placeholder="" value={initPrice} onChange={(e) => setInitPrice(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 col-lg-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="title">Purchase Date</label>
                                                            <input type="date" className="form-control" id="title" placeholder="" value={purchaseDate} onChange={(e) => setPurchaseDate(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 col-lg-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="slug">Supplier</label>
                                                            <input type="text" className="form-control" id="slug" placeholder="" value={supplier} onChange={(e) => setSupplier(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div> */}
                                            
                                        </div>
                                    </div>

                                </div>
                            </div>
                        }
                        {/* Tab Ends */}
                    </div>

                    <DocumentModal show={docModalShow} refNo={refNo} refresh={setDocModalShow} rev={rev}/>
                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
            <ToastContainer 
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" 
            />
        </div>

    )
}

export default ViewListing