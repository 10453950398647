import React from 'react'
import ContentWrapper from '../components/home/contentWrapper';
import Placeholder from '../components/home/placeholder';
import SideNav from '../components/home/sideNav';
import Footer from '../components/home/footer';

export const HomePage = () => (
    <div class="wrapper">
        <Placeholder />
        <SideNav />
        <ContentWrapper />
        <Footer />
    </div>
);