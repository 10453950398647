import React from 'react'
import Placeholder from '../components/home/placeholder';
import SideNav from '../components/home/sideNav';
import Footer from '../components/home/footer';
import Invoice2 from '../components/salesOrder/invoice2';

export const InvoicePage = () => (
    <div class="wrapper">
        {/* <Placeholder /> */}
        {/* <SideNav /> */}
        <Invoice2 />
        <Footer />
    </div>
);