import React from 'react'
import Placeholder from '../components/home/placeholder';
import SideNav from '../components/home/sideNav';
import Footer from '../components/home/footer';
import AddTransmission from '../components/transmissionType/addTrans';

export const AddTrans = () => (
    <div class="wrapper">
        <Placeholder />
        <SideNav />
        <AddTransmission />
        <Footer />
    </div>
);