import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import CrudService from '../../util/crudService'
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import 'react-toastify/dist/ReactToastify.css';

function ManageUser() {
    const { t } = useTranslation();
    const [id, setId] = useState(0);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [permissionSet, setPermissionSet] = useState(1);
    const [status, setStatus] = useState(1);
    const [udata, setUdata] = useState([]);

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (params.id) {
            setId(params.id);

            try {
                CrudService.GetDataById(navigate, location, 'user', params.id)
                .then((res) => {
                    setUdata(res.data);
                    setId(res.data.id);
                    setFirstName(res.data.first_name);
                    setLastName(res.data.last_name);
                    setEmail(res.data.email);
                    setPermissionSet(res.data.permission_set);
                    setStatus(res.data.status);
                });
                
            } catch (error) {
                console.error(error);
            }
        }
    }, [params.id])

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('id :'+id);
        if (id == null || id == 0){
            CrudService.PostData(navigate, location, 'user', 
            {
                first_name: firstName, 
                last_name: lastName, 
                email: email, 
                password: password, 
                role: permissionSet, 
                status: status
            })
            .then((response) => {
                toast.success("Successfully Added");
                navigate(`${process.env.PUBLIC_URL}/user`);
            })
            .catch((error) => {
                toast.error('Error:' + error);
                console.error(error);
            });
        } else {
            CrudService.PutData(navigate, location, 'user', id, 
            {
                id: id,
                first_name: firstName, 
                last_name: lastName, 
                email: email, 
                password: password, 
                role: permissionSet, 
                status: status
            })
            .then((response) => {
                console.log(response.data);
                navigate(`${process.env.PUBLIC_URL}/user`);
            })
            .catch((error) => {
                toast.error('Error:' + error);
                console.error(error);
            });
        }

    };

    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1><NavLink to="/user" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; Add User</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <NavLink exact to="/user" activeClassName="selected" >Users</NavLink>
                                </li>
                                <li className="breadcrumb-item active">Add User</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        {/* left column */}
                        <div className="col-md-8">
                            {/* general form elements */}
                            <div className="card card-primary">
                                {/* <div className="card-header">
                                    <h3 className="card-title">User Info</h3>
                                </div> */}
                                {/* /.card-header */}
                                {/* form start */}
                                <form>
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">{t('user-page.email')}</label>
                                                    <input type="text" className="form-control" id="email" placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">{t('user-page.password')}</label>
                                                    <input type="password" className="form-control" id="password" placeholder="New Password" value={password} onChange={(e) => setPassword(e.target.value)} autoComplete='false'/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">{t('user-page.name')}</label>
                                                    <input type="text" className="form-control" id="name" placeholder="Enter First name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                                </div>
                                            </div>
                                            {/* <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Last Name</label>
                                                    <input type="text" className="form-control" id="slug" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">{t('user-page.permission-set')}</label>
                                                    {/* <input type="text" className="form-control" id="name" placeholder="Enter First name" value={permissionSet} onChange={(e) => setPermissionSet(e.target.value)} /> */}
                                                    <select class="form-control" id="status" value={permissionSet} onChange={(e) => { setPermissionSet(e.target.value);}}>
                                                        <option value="1">Admin</option>
                                                        <option value="2">Executive</option>
                                                        <option value="3">Staff</option>
                                                        <option value="4">External</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label htmlFor="exampleInputEmail1">Status</label>
                                                    <select class="form-control" id="status" value={status} onChange={(e) => { console.log(e.target.value); setStatus(e.target.value);}}>
                                                        <option value="1">Enable</option>
                                                        <option value="2">Disable</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                                    </div>
                                </form>
                            </div>
                            {/* /.card */}
                            {/* general form elements */}
                            <div className="card card-secondary">
                                <div className="card-header">
                                    <h3 className="card-title">Misc Info</h3>
                                </div>
                                {/* /.card-header */}
                                {/* form start */}
                                <form>
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Created By</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" value={udata.created_by} readOnly />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Created At</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" value={udata.createdAt} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Updated By</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" value={udata.updated_by} readOnly />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Updated At</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" value={udata.updatedAt} readOnly />
                                                </div>
                                            </div>
                                        </div>  
                                    </div>
                                    {/* /.card-body */}
                                </form>
                            </div>
                            {/* /.card */}
                        </div>
                    </div>
                    {/* /.row */}
                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>
    )
}

export default ManageUser