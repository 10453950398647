import React, { useRef, useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation, NavLink } from 'react-router-dom';
import CrudService from '../../util/crudService'
import { Payment } from './modals/paymentModal'
import { ToastContainer, toast } from 'react-toastify';
import Fp from '../../util/formatPrice';
import { useTranslation } from 'react-i18next';

const CancelModal = (props) => {

    const locationHook = useLocation();
    const navigate = useNavigate();

    const [reason, setReason] = useState('');
    const [show, setShow] = useState(true);

    const resetFields = () => {
        setReason('')
    }

    const handleSubmit = () => {

        CrudService.PutData(navigate, locationHook, 'sale/cancel', props.orderNo, 
        { 
            order_id: props.orderNo,
            reason: reason
        })
        .then((res) => {
            resetFields();
            setShow(false);
            navigate(`${process.env.PUBLIC_URL}/sales-order-list`);
        })
        .catch((err) => {
            console.error(err);
            //alert(err);
            toast.error('' + err);
        })
    }

    useEffect(() => {
        if (!show)
            return null;

    }, [show])

    return (
        // //modal fade
        <div class="modal fade" id="modal-cancel"> 
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Cancel Order - {props.orderNo}</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="form-group">
                                    <label htmlFor="title">Reason for Cancellation</label>
                                    <textarea className="form-control" id="slug" placeholder="Reason" value={reason} onChange={(e) => setReason(e.target.value)}></textarea>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" data-dismiss="modal" onClick={() => handleSubmit()}>Submit</button>
                    </div>
                </div>
                {/* <!-- /.modal-content --> */}
            </div>
            {/* <!-- /.modal-dialog --> */}
        </div>
        //    <!-- /.modal -->
    )
}

function SalesOrderView() {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    
    const [id, setId] = useState(0);
    const [orderData, setOrderData] = useState([]);
    const [orderDeailData, setOrderDetailData] = useState([]);
    const [costData, setCostData] = useState([]);
    const [vendorData, setVendorData] = useState([]);

    const handleShipped = (e, id) => {
        e.preventDefault();
        console.log('handleShipped ID : '+ id);
        if (window.confirm('Are you sure want to mark as Shipped ?')){

            let data = {};
            CrudService.PutData(navigate, location, 'sale/shipped', id, data)
            .then((res) => {
                console.log('Shipped :' + res);
                toast.success('Marked as Shipped.');
            })
            .catch((error) => {
                console.error(error);
                toast.error('' + error);
            })
        }
    }

    useEffect(() => {
        if (params.id) {
            setId(params.id);

            try {
                CrudService.GetDataById(navigate, location, 'sale/invoice', params.id)
                .then((res) => {
                    console.log(res.data);
                    setOrderData(res.data.order);
                    setOrderDetailData(res.data.detail);
                    setCostData(res.data.cost);
                    setVendorData(res.data.vendor);
                });
                
            } catch (error) {
                console.error(error);
            }
        }
    }, [params.id])

    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1><NavLink to="/sales-order-list" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; Sales Order Overview - {(orderData.status == 0) && <span class="badge bg-danger">Cancelled</span> || (orderData.status == 1) && <span class="badge bg-warning">Pending</span> || <span class="badge bg-success">Completed</span>} { (orderData.shipped == 1) &&  <span class="badge bg-success">Shipped</span> } </h1>
                                {/* { orderData.status == 1 ? <span class="badge bg-success">Pending</span>  : <span class="badge bg-danger">Cancelled</span> } */}
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><NavLink to="/sales-order-list">Sales Order List</NavLink></li>
                                <li className="breadcrumb-item active">View Sales Order</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className='col-md-12'>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">{t('order-pages.vendor')}</label>
                                                <input type="text" className="form-control" id="vendor" placeholder="" value={(vendorData != null) ? vendorData?.name : ''} readOnly />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">{t('order-pages.type')}</label>
                                                <input type="text" className="form-control" id="type" placeholder="" value={(orderData.type == 1) ? 'Domestic' : 'Export'} readOnly />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="slug">{t('order-pages.currency')}</label>
                                                <input type="text" className="form-control" id="currency" placeholder="" value={(orderData.currency == 1) ? 'JPY' : 'USD'} readOnly />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="slug">Note</label>
                                                <textarea type="text" className="form-control" id="desc" placeholder="" value={orderData.remark} readOnly />
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-8'>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <table className='table table-hover text-nowrap'> 
                                                <tr>
                                                    <th>{t('order-pages.type')}</th>
                                                    <th>{t('order-pages.ref')}</th>
                                                    <th>{t('order-pages.description')}</th>
                                                    <th>{t('order-pages.qty')}</th>
                                                    <th>{t('order-pages.price')}</th>
                                                </tr>
                                                <tbody>
                                                    {
                                                        orderDeailData?.map((item, index) => {
                                                            return(
                                                                <tr key={index}>
                                                                    <td width={'15%'}>
                                                                        {(item.type == 1) ? 'Inventory' : 'Non-Inv' }
                                                                    </td>
                                                                    <td width={'20%'}>                                       
                                                                        {(item.ref_no != 0) ?
                                                                            <NavLink exact to={`/view-list/${item.ref_no}`}>
                                                                                {item.ref_no} | {item.Listing.ref_no}
                                                                            </NavLink>
                                                                        : item.ref_no
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {item.ref_desc}
                                                                    </td>
                                                                    <td width={'10%'}>
                                                                        {item.qty}
                                                                    </td>
                                                                    <td>
                                                                        {Fp.formatPrice(item.sub_total)}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* --- */}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className="col-md-12">
                                            <table className='table table-hover text-nowrap'>
                                                <tr>
                                                    <th>{t('order-pages.cost')}</th>
                                                    <th>{t('order-pages.amount')}</th>
                                                </tr>
                                                <tbody>
                                                    { costData.map((item, index) => {
                                                        return(
                                                            <tr key={index}>
                                                                <td>{item.cost}</td>
                                                                <td>{item.amount}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    {/* /.row */}
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <div className='row'>
                                        {/* <div className='col-md-2'>
                                            <div className="form-group">
                                                <label htmlFor="title">Total</label>
                                                <input type="text" className="form-control" id="title" placeholder="" value={parseFloat(orderData.sub_total) - parseFloat(orderData.discount)} readOnly />
                                            </div>
                                        </div> */}
                                        {/* <div className='col-md-2'>
                                            <div className="form-group">
                                                <label htmlFor="slug">Discount</label>
                                                <input type="number" className="form-control" id="discount" placeholder="" value={orderData.discount} readOnly />
                                            </div>
                                        </div> */}
                                        <div className='col-md-2'>
                                            <div className="form-group">
                                                <label htmlFor="title">{t('order-pages.sub-total')}</label>
                                                <input type="text" className="form-control" id="title" placeholder="" value={Fp.formatPrice(orderData.sub_total)} readOnly />
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="title">{t('order-pages.amount-paid')}</label>
                                                <input type="text" className="form-control" id="title" placeholder="" value={Fp.formatPrice(orderData.amount_paid)} readOnly />
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="slug">{t('order-pages.amount-remaining')}</label>
                                                <input type="text" className="form-control" id="slug" placeholder="" value={Fp.formatPrice(orderData.amount_remaining)} readOnly />
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                    </div>

                    { orderData.status != 0  &&
                    <>
                        <div className='row'>
                            <div className='col-md-6' >
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <Payment orderNo={params.id} t={t} />
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <NavLink exact to={`/print-invoice/${orderData.id}`} target='_blank'>
                                            <button type="submit" className="btn btn-info" style={{ marginRight: '10px'}} >Print Invoice</button>
                                        </NavLink>
                                        <NavLink exact to={`/sales-order-edit/${orderData.id}`}>
                                            <button type="submit" className="btn btn-warning" style={{ marginRight: '10px'}} >Edit Order</button>
                                        </NavLink>
                                        <NavLink exact to={`/sales-order-amend/${orderData.id}`}>
                                            <button type="submit" className="btn btn-warning" style={{ marginRight: '10px'}} >Amend to Order</button>
                                        </NavLink>
                                        {/* <button type="submit" className="btn btn-info" style={{ marginRight: '10px'}} >Print Invoice</button> */}
                                        <button type="submit" className="btn btn-danger" data-toggle="modal" data-target="#modal-cancel">Cancel Order</button>
                                    </div>
                                </div>
                                {
                                    (orderData.shipped == 0) &&
                                        <div className='row' style={{ marginTop: '10px' }}>
                                            <div className='col-md-12'>
                                                <button type="submit" className="btn btn-success" onClick={ e => handleShipped(e, id) }>Mark as Shipped</button>
                                            </div>
                                        </div>
                                }
                            </div>

                        </div>
                        
                    </>                          
                    }
                    <CancelModal orderNo={id} />
                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
            <ToastContainer 
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" 
            />
        </div>

    )
}

export default SalesOrderView