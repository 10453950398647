import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import CrudService from '../../util/crudService'
import { toast } from 'react-toastify';

export const DocumentModal = (props) => {
    const locationHook = useLocation();
    const navigate = useNavigate();

    const [docFile, setDocFile] = useState();
    const [data, setData] = useState([]);
    const [fileName, setFileName] = useState('');
    const [docType, setDocType] = useState(0);

    const handleUpload = (event) => {
        setDocFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
        console.log('Revision:' + props.rev);
    };

    const handleDocSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("doc", docFile);
        formData.append("type", docType);
        formData.append("ref_no", props.refNo);
        formData.append("rev", props.rev);

        CrudService.PostFileData(navigate, locationHook, 'docman/upload', formData) 
        .then((res) => {
            if (res.status === 200) {
                setData(res.data);
                toast.success("Upload Completed. Data processing in Progress.");
                props.refresh(false);
            }
        })
        .catch((err) => {
            alert('Error Occured');
            console.error(err);
        });
    };
        
    // if (!props.show)
    //     return null;

    return (
        // //modal fade
        <div class="modal fade" id="modal-default"> 
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Document Upload</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <select class="form-control" id="status" value={docType} onChange={(e) => setDocType(e.target.value)}>
                                        <option value="0">-- Document Type --</option>
                                        <option value="1">Road Inspection Certificate</option>
                                        <option value="2">Authorization Letter</option>
                                        <option value="3">Recycle Ticket</option>
                                        <option value="4">Company Automobile liability Insurance</option>
                                        <option value="5">Seal Certificate</option>
                                        <option value="6">Purchase Invoice</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" id="exampleInputFile" name='doc' onChange={ handleUpload } accept=".pdf,text/pdf" />
                                            <label className="custom-file-label" htmlFor="exampleInputFile">{fileName ? fileName : 'Choose File'}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" onClick={handleDocSubmit} >Submit</button>
                    </div>
                </div>
                {/* <!-- /.modal-content --> */}
            </div>
            {/* <!-- /.modal-dialog --> */}
        </div>
        //    <!-- /.modal -->
    )
}
