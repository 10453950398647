import React from 'react'

function footer() {
    return (
        <footer className="main-footer">
            <strong>Copyright © { new Date().getFullYear()} <a href="https://kagamotors.jp">KagaMotors.jp</a>. </strong>
            All rights reserved.
            <div className="float-right d-none d-sm-inline-block">
                <b>Version</b> 1.0.1
            </div>
        </footer>

    )
}

export default footer