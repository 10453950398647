import React from 'react'
import Placeholder from '../components/home/placeholder';
import SideNav from '../components/home/sideNav';
import Footer from '../components/home/footer';
import AddSubCategory from '../components/categories/addSubCategory';

export const AddSubCategoryPage = () => (
    <div class="wrapper">
        <Placeholder />
        <SideNav />
        <AddSubCategory />
        <Footer />
    </div>
);