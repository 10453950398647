import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import CrudService from '../../util/crudService'
import { toast } from 'react-toastify';

export const CostModal = (props) => {
    const locationHook = useLocation();
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [value, setValue] = useState('');
    const [costList, setCostList] = useState([]);

    const handleAdd = (e) => {
        e.preventDefault();

        CrudService.PostData(navigate, locationHook, 'cost', 
        { 
            ref_no: props.refNo,
            name: name, 
            value: value,
            cost_type: 1,
        })
        .then((res) => {
            fetchData();
            props.refresh(true);
        })
        .catch((err) => {
            console.error(err);
        })
    }

    const fetchData = () => {
        CrudService.GetDataWithParam(navigate, locationHook, 'cost/all', {ref_no: props.refNo})
        .then((res) => {
            setCostList(res.data);
        })
        .catch((err) => {
            console.error(err);
        })
    } 

    const handleDelete = (id) => {
        if (window.confirm('Are you sure want to delete this Cost ?')){
            CrudService.DeleteData(navigate, locationHook, 'cost', id)
            .then((res) => {
                props.refresh(true);
            })
            .catch((err) => {
                console.error(err);
            })
        }
    }
        
    // if (!props.show)
    //     return null;

    return (
        // //modal fade
        <div class="modal fade" id="modal-cost"> 
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Cost Manager</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="slug" placeholder="Cost Name" value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="title" placeholder="Value" value={value} onChange={(e) => setValue(e.target.value)} />
                                </div>
                            </div>
                            <div className='col-md-2'>
                                <div className="form-group">
                                    <button className='btn btn-success' onClick={handleAdd}>Add</button>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-6">
                                <table className='table table-hover text-nowrap'>
                                    <tr>
                                        <th>Cost</th>
                                        <th>Amount</th>
                                        <th></th>
                                    </tr>
                                    <tbody>
                                        { costList.map((item) => {
                                            return(
                                                <tr>
                                                    <td>{item.cost}</td>
                                                    <td>{item.amount}</td>
                                                    <td><button className='btn btn-xs btn-outline-danger' onClick={() => handleDelete(item.id)}><i class="far fa-trash-alt"></i></button></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                        {/* <button type="button" class="btn btn-primary" onClick={handleDocSubmit} >Submit</button> */}
                    </div>
                </div>
                {/* <!-- /.modal-content --> */}
            </div>
            {/* <!-- /.modal-dialog --> */}
        </div>
        //    <!-- /.modal -->
    )
}
