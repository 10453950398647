import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import axios from 'axios';
import { NavLink, Link, useNavigate, useLocation } from 'react-router-dom'
import CrudService from '../../util/crudService'
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select'
import Fp from '../../util/formatPrice';
import { useTranslation } from 'react-i18next';

const customControlStyles = base => ({
    ...base,
    height: 35,
    minHeight: 20,
    fontSize: '.875rem',
});

const FilterDiv = (props) => {
    const [refNo_, setRefNo_] = useState('');
    const [vendor_, setVendor_] = useState('');
    const [shipped_, setShipped_] = useState('');

    const [vendorList, setVendorList] = useState([]);

    let navigate = props.navigation;
    let locationHook = props.locationHook;

    const handleFilter = () => {
        console.log('refNo_ :' + refNo_)

        let data = { vendor_id: vendor_, shipped: shipped_ }
        props.searchQuery(data);
    }

    useEffect(() => {
        //Vendor List
        CrudService.GetData(navigate, locationHook, 'vendor/basic')
        .then((res) => {
            setVendorList(res.data);
        })
        .catch((err) => console.error(err))
    }, [])

    return (
        <section class="content">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        
                        {/* Vendor */}
                        <div className='col-md-4'>
                            <div className="form-group">
                                <Select styles={{control: customControlStyles}} options={vendorList.map((item, index) => ({ value: item.id, label: item.name }))} onChange={(e) => { console.log('e :' + e.value); setVendor_(e.value);}} placeholder="Vendor" />
                            </div>
                        </div>

                        <div className='col-md-2'>
                            <div className="form-group">
                                <select class="form-control form-control-sm" id="shipped" value={shipped_} onChange={(e) => { setShipped_(e.target.value);}}>
                                    <option value=''>-- Shipped --</option>
                                    <option value='1'>Yes</option>
                                    <option value='0'>No</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-md-1'>
                            <div className="form-group">
                                <button className='btn btn-info btn-sm btn-block' onClick={handleFilter}>Filter</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )      
}

function StockVendor() {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const [listingList, setListingList] = useState([]);
    const [refresh, setRefresh] = useState(0);

    const [currPage, setCurrPage] = useState(1);
    const [page_, setPage_] = useState(1);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    const [showFilter, setShowFilter] = useState(true)
    const handleFilter = () => setShowFilter(!showFilter)

    const [searchQuery, setSearchQuery] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const goToTop = () => {
        window.scrollTo({
            top: 0, //700
            behavior: 'smooth',
        });
    };

    //Next Page
    const nextPage = () => {
        if (hasNext)
            setPage_(currPage + parseInt('1'));
    }

    //Previous Page
    const prevPage = () => {
        if (hasPrev)
            setPage_(currPage - parseInt('1'));
    }

    const gotoPage = (page_) => {
        if (hasNext)
            setPage_(parseInt(page_));
    }

    const handleSearch = () => {        
        setListingList([]); // Clear previous filtered data
        
        CrudService.GetDataWithParam(navigate, location, 'listing/vendor', 
        { 
            page: page_, 
            vendor_id: searchQuery.vendor_id,
            shipped: searchQuery.shipped,

        })
        .then((res) => {
            console.log(res.data);
            setListingList(res.data.data);
            setCurrPage(parseInt(res.data.curr_page));
            setHasNext(res.data.hasNext);
            setHasPrev(res.data.hasPrev);
            setRowCount(res.data.count);
        })
    }

    const handleView = (e, id) => {  
        e.preventDefault();
        navigate(`${process.env.PUBLIC_URL}/view-list/${id}`);
    }

    const handleEdit = (e, id) => {
        
        e.preventDefault();
        console.log('ID : '+ id);
        // <Link to={`${process.env.PUBLIC_URL}/addList/${id}`}>T</Link>
        navigate(`${process.env.PUBLIC_URL}/add-list/${id}`);

    }

    const handleDelete = (e, id) => {
        
        e.preventDefault();

        if (window.confirm('Are you sure want to DELETE ?')){
            CrudService.DeleteData(navigate, location, 'listing', id)
            .then((res) => {
                console.log('Deleted :' + res);
            })
            .catch((error) => {
                console.error(error);
            })
        }

    }
    

    const handleSold = (e, id) => {
        e.preventDefault();
        console.log('ID : '+ id);
        navigate(`${process.env.PUBLIC_URL}/sell-listing/${id}`);
    }

    const handleDismantle = (e, id) => {
        e.preventDefault();
        console.log('ID : '+ id);
        if (window.confirm('Are you sure want to Revert Dismantle ?')){

            let data = {};
            CrudService.PutData(navigate, location, 'listing/rev-dismantle', id, data)
            .then((res) => {
                console.log('Rev-Dismantle :' + res);
                //setRefresh(Math.random()); //!refresh);
                handleSearch();
            })
            .catch((error) => {
                console.error(error);
            })
        }
        //navigate(`${process.env.PUBLIC_URL}/sell-listing/${id}`);
    }

    const handleShipped = (e, id) => {
        e.preventDefault();
        console.log('handleShipped ID : '+ id);
        if (window.confirm('Are you sure want to mark as Shipped ?')){

            let data = {};
            CrudService.PutData(navigate, location, 'listing/shipped', id, data)
            .then((res) => {
                console.log('Shipped :' + res);
                //setRefresh(!refresh);
                handleSearch();
                toast.success('Marked as Shipped.');
            })
            .catch((error) => {
                console.error(error);
                toast.error('' + error);
            })
        }
    }

    const handleBulkShipped = (e, id) => {
        e.preventDefault();
        console.log('handleShipped ID : '+ id);
        if (window.confirm('Are you sure want to mark as Shipped ?')){

            let data = { data: selectedRows };
            CrudService.PutData(navigate, location, 'listing/shipped-bulk', id, data)
            .then((res) => {
                console.log('Shipped :' + res);
                //setRefresh(!refresh);
                handleSearch();
                toast.success('Marked as Shipped.');

                setSelectedRows([]);
            })
            .catch((error) => {
                console.error(error);
                toast.error('' + error);
            })
        }
    }

    const handleSelectRow = (e, id_) => {
        //e.preventDefault();

        //console.log('Check Value : ' + e.target.value);
        console.log('Check Id : ' + id_);
        console.log('Check Checked : ' + e.target.checked);

        if (e.target.checked) {
            setSelectedRows([...selectedRows, id_]);
        }
        else {
            const temp_ = selectedRows.filter((item) => item != id_);

            setSelectedRows(temp_);
        }

    }

    useEffect(() => {
        handleSearch();
        setPage_(parseInt(1));
    },
    [searchQuery])

    useEffect(() => {
        handleSearch();
    },
    [page_])

    // Pagination
    const pages = [];

    for (let i = 1; i <= (rowCount/14) + 1; i++) {
		pages.push(
			<li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(i)}>{i}</a></li>
		);
	}
    // End Pagination

    /*const [pageSize, setPageSize] = useState(10);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = useCallback(async ({ pageIndex }) => {
        setLoading(true);
        try {
            const response = await axios.get(
                `https://api.example.com/data?page=${pageIndex}&pageSize=${pageSize}`
            );
            setData(response.data);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }, [pageSize]);

    const columns = useMemo(
        () => [{ Header: 'Name', accessor: 'name', }, { Header: 'Email', accessor: 'email', }, { Header: 'Phone', accessor: 'phone', },],
        []
    );*/

    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0"><NavLink to="/" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; Stock History</h1>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <NavLink exact to="/" activeClassName="selected" >Dashboard</NavLink></li>
                                <li className="breadcrumb-item active">Stock List</li>
                            </ol>
                        </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>{/* /.container-fluid */}
            </div>

            {/* availability={setAvailability} refNo={setRefNo} */}
            { showFilter && <FilterDiv navigate={navigate} locationHook={location} searchQuery={setSearchQuery} /> }

            <section class="content">
                <div className="card">
                    <div className="card-header">
                        {/* <h3 className="card-title">Car List Table</h3> */}
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" />
                            {/* <label class="form-check-label">Select/De-seslect All</label> */}
                        </div>
                        <div className="card-tools">
                            {/* <div className='btn btn-tool'>
                                <div class="btn-group">
                                    <button type="button" class="btn btn-outline-info btn-sm dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" href="" onClick={(e) => {handleEdit(e, 1)}}>Edit</a>
                                    </div>
                                </div>
                            </div> */}

                            {
                                (selectedRows.length > 0) &&
                                    <>
                                        <div className='btn btn-tool'>
                                            <button type="button" class="btn btn-block btn-outline-success btn-sm" onClick={ e => handleBulkShipped(e, 1) }>Mark as Shipped ({selectedRows.length})</button>
                                        </div>   
                                    </>
                            }

                            <div className='btn btn-tool'>
                                <button type="button" class="btn btn-block btn-outline-warning btn-sm" onClick={handleFilter}><i class="fas fa-search fa-sm"></i></button>
                            </div>
                            <div className='btn btn-tool'>
                                <NavLink exact to={`${process.env.PUBLIC_URL}/add-list`} activeClassName="selected" >
                                    <button type="button" class="btn btn-block btn-outline-success btn-sm"><i class="fas fa-plus fa-sm"></i>&nbsp;Add List</button>
                                </NavLink>

                            </div>
                        </div>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body table-responsive p-0">
                        <table className="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Shipped</th>
                                    <th>{t('stock-page.ref')} #</th>
                                    <th>{t('stock-page.title')}</th>
                                    <th>{t('stock-page.vin')}</th>
                                    {/* <th>Category</th> */}
                                    {/* <th>Make</th> */}
                                    <th>{t('stock-page.model-code')}</th>
                                    <th>{t('stock-page.registration-year')}</th>
                                    <th>{t('stock-page.condition')}</th>
                                    <th>{t('stock-page.price')}</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                { listingList?.map((value) => {
                                    return(
                                    <tr>
                                        <td>
                                            <div class="btn-group">
                                                <input type='checkbox' className='form-check-input' onChange={ e => handleSelectRow(e, value.id)}/>
                                                {/* <button className='btn btn-warning btn-xs' onClick={() => {handleEdit(value.id)}}><i class="far fa-edit"></i></button>  */}
                                                <button className='btn btn-outline-primary btn-xs' onClick={(e) => { handleView(e, value.id) }}>View</button>
                                                <div class="btn-group">
                                                    <button type="button" class="btn btn-info btn-xs dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
                                                    <div class="dropdown-menu">
                                                        <a class="dropdown-item" href="" onClick={(e) => {handleEdit(e, value.id)}}>Edit</a>
                                                        <a class="dropdown-item" href="" onClick={(e) => handleDelete(e, value.id)}>Delete</a>
                                                        { (value.shipped == 0)  &&
                                                            <a class="dropdown-item" href="" onClick={(e) => handleShipped(e, value.id)}>Mark as Shipped</a>
                                                        }
                                                        {
                                                            (value.availability == 3) &&
                                                                <a class="dropdown-item" href="" onClick={(e) => handleDismantle(e, value.id)}>Revert Dismantle</a>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td align=''>
                                            { (value.shipped === 1) && <span class="badge bg-success">Shipped</span> || <span class="badge bg-secondary">Pending</span> }
                                        </td>
                                        <td>{value.ref_no}</td>
                                        <td>{value.title}</td>
                                        <td>{value.vin}</td>
                                        {/* <td>{value.Category.name}</td> */}
                                        {/* <td>{value.Make.name}</td> */}
                                        <td>{value.model_code}</td>
                                        <td>{value.r_year}</td>
                                        <td>{ value.vcondition == 1 ? <span class="badge bg-success">New</span>  : <span class="badge bg-secondary">Used</span> }</td>
                                        <td>{(value.currency == 1) ? '¥' : '$'} { Fp.formatPrice(parseInt(value.price)) }</td>
                                        
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/* /.card-body */}
                    <div className='card-footer clearfix'>
                        {/* <div className="center-block text-center">
                            <ul className="pagination mb-3">
                                { (hasPrev == 'true') &&
                                <li className={"page-item page-prev "+ ( hasPrev ? " " : "disabled")}>
                                    <a className="page-link" href="javascript:void(0);" tabindex="-1" onClick={ () => prevPage()}>Prev</a>
                                </li>
                                }
                                { (hasNext == 'true') &&
                                    <li className={"page-item page-next "+ ( hasNext ? " " : "disabled")}>
                                        <a className="page-link" href="javascript:void(0);" onClick={ () => nextPage()}>Next</a>
                                    </li>
                                }
                            </ul>
                        </div> */}
                        <ul class="pagination pagination-sm">
                            { (hasPrev == 'true') &&
                                <li class="page-item"><a class="page-link" href="javascript:void(0);" tabindex="-1" onClick={ () => prevPage()}>«</a></li>
                            }
                            { (!(currPage == 0 && hasNext == 'false')) &&
                                pages
                                // <>
                                //     <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(1)}>1</a></li>
                                //     <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(2)}>2</a></li>
                                //     <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(3)}>3</a></li>
                                // </>
                            }
                            { (hasNext == 'true') &&
                                <li class="page-item"><a class="page-link" href="javascript:void(0);" onClick={ () => nextPage()}>»</a></li>
                            }
                        </ul>

                        {/* -- */}
                        <ul class="pagination pagination-sm">
                            { 
                                selectedRows.map((item) => {
                                    
                                    return (<li class="page-item"><a class="page-link" href="javascript:void(0);" tabindex="-1"> {item}</a></li>)
                                })
                            }
                        </ul>
                    </div>
                </div>
            </section>
            <ToastContainer 
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" 
            />
        </div>
    )
}

export default StockVendor