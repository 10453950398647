import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation, NavLink } from 'react-router-dom';
import CrudService from '../../util/crudService'
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

function AddTrans() {
    const [id, setId] = useState(0);
    const [name, setName] = useState('');
    const [slug, setSlug] = useState('');
    const [status, setStatus] = useState(1);
    
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (params.id != null) {
            setId(params.id);
            CrudService.GetDataById(navigate, location, 'transmission-type', params.id)
            // axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/transmission-type/${params.id}`)
            .then((res) => {
                setName(res.data.name);
                setSlug(res.data.slug);
                setStatus(res.data.status);

            }).catch(function(error) {
                toast.error('' + error);
                console.error(error);
            })
        }

        console.log(`params.id : ${params.id}`)
    }, params.id)

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('id :'+id);
        if (id == null || id == 0){

            CrudService.PostData(navigate, location, 'transmission-type', {name, slug, status})
            .then((response) => {
                alert('Successfully Added!');
                navigate(`${process.env.PUBLIC_URL}/transmission`);

            })
            .catch((error) => {
                toast.error('' + error);
                console.error(error);
            });
        } else {
            CrudService.PutData(navigate, location, 'transmission-type', id, {id, name, slug, status})
            .then((response) => {
                //alert('Successfully Added!');
                navigate(`${process.env.PUBLIC_URL}/transmission`);
            })
            .catch((error) => {
                toast.error('' + error);
                console.log(error);
            });
        }

    };

    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            {/* <h1><NavLink to="/transmission" replace={true} role='button'><i class="fas fa-chevron-left fa-sm"></i></NavLink> &nbsp; Add Transmission</h1> */}
                            <h1><NavLink to="/transmission" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; Add Transmission</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><NavLink to="/transmission" replace={true}>Transmission Types</NavLink></li>
                                <li className="breadcrumb-item active">Add Transmission</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        {/* left column */}
                        <div className="col-md-8">
                            {/* general form elements */}
                            <div className="card card-primary">
                                {/* <div className="card-header">
                                    <h3 className="card-title">Details</h3>
                                </div> */}
                                {/* /.card-header */}
                                {/* form start */}
                                <form>
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Name</label>
                                                    <input type="text" className="form-control" id="name" placeholder="Enter name" value={name} onChange={(e) => setName(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Slug</label>
                                                    <input type="text" className="form-control" id="slug" placeholder="Enter slug" value={slug} onChange={(e) => setSlug(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label htmlFor="exampleInputEmail1">Status</label>
                                                    <select class="form-control" id="status" value={status} onChange={(e) => { console.log(e.target.value); setStatus(e.target.value); }}>
                                                        <option value="1">Enable</option>
                                                        <option value="2">Disable</option>
                                                    </select>
                                                </div>      
                                            </div>
                                        </div>

                                        
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                                    </div>
                                </form>
                            </div>
                            {/* /.card */}
                            {/* general form elements */}
                            <div className="card card-secondary" hidden>
                                <div className="card-header">
                                    <h3 className="card-title">Misc Info</h3>
                                </div>
                                {/* /.card-header */}
                                {/* form start */}
                                <form>
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Created By</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Created At</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Updated By</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Updated At</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" />
                                                </div>
                                            </div>
                                        </div>  
                                    </div>
                                    {/* /.card-body */}
                                </form>
                            </div>
                            {/* /.card */}
                        </div>
                    </div>
                    {/* /.row */}
                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>
    )
}

export default AddTrans