import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
//import { Gallery, ThumbnailImageProps } from "react-grid-gallery"; --Package Removed
//import Lightbox from "react-image-lightbox";  --Package Removed
//import "react-image-lightbox/style.css";  --Package Removed

const UploadForm = ({handleImgUploads}) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileName, setFileName] = useState([]);

    const handleFileChange = (event) => {
        setSelectedFiles(event.target.files);
        setFileName([]);
        for (let index = 0; index < event.target.files.length; index++) { 
            setFileName(fileName => [...fileName, ' ' + event.target.files[index].name]);
        }

        console.log('fileName '+ fileName);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append("images", selectedFiles[i]);
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/api/upload`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });

            console.log(response.data);
            // handle success response here

            response.data.files.map((data) => {
                handleImgUploads(data.url);
            })

            toast.success("Upload Completed.");
            //handleImgUploads(response.data.files[0].url);
        } catch (error) {
            console.error(error);
            // handle error response here
        }
    };

    return (
        <form onSubmit={handleFormSubmit}>

            <div className="row">
                <div className='col-md-6' style={{ borderRight: '1 black'}}>
                    <div className="form-group">
                        <label htmlFor="imageupload"> Select Image(s):</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="file" className="custom-file-input" id="imageupload" name="images" multiple onChange={handleFileChange}/>
                                <label className="custom-file-label" htmlFor="exampleInputFile">{fileName ? fileName : 'Choose File'}</label>
                            </div>
                            <div class="input-group-append">
                                <button class="input-group-text" type="submit">Upload</button>
                            </div>
                        </div>
                    </div>
                    {/* <button type="submit" className="btn btn-primary">Upload</button> */}
                </div>
            </div>

            <ToastContainer 
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" 
            />
        </form>
    );
};

const ImageGrid = ({ images }) => {
    return (
        <div className="row">
            {images.map((image) => (
                <div key={image.id} className="col-md-3">
                    <img
                        src={image} //`${process.env.REACT_APP_API_END_POINT}/${image}`  //filename
                        alt={image}
                        width={"300"}
                        className="img-thumbnail"
                    />
                </div>
            ))}
        </div>
    );
};

const UploadImage = ({handleImgUploads, images_}) => {
    const [images, setImages] = useState([]);

    console.log(images_);
    const fetchImages = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_END_POINT}/api/images`);
            setImages(response.data.images);
        } catch (error) {
            console.error(error);
            // handle error response here
        }
    };

    return (
        //className="container"
        <div>
            <UploadForm handleImgUploads={handleImgUploads} />
            
            <p>*Click Upload to add images to the system.</p>
            {/* <h2>Uploaded Images</h2> */}
            <ImageGrid images={images_} />
        </div>
    );
};

export default UploadImage;
