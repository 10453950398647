import React, { useState, useEffect } from 'react'
//import axios from 'axios';
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import CrudService from '../../util/crudService'

function CurrencyList() {
    const navigate = useNavigate();
    const location = useLocation();
    const [makeList, setMakeList] = useState([]);
    
    const fetchData = () => {
        CrudService.GetData(navigate, location, 'currency')
        .then((res) => {
            console.log(res.data);
            setMakeList(res.data);
        }).catch((error) => {
            console.error(error);
        })
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleUpdate = () => {
        CrudService.GetData(navigate, location, 'currency/update')
        .catch((error) => {
            console.error(error);
        })

        fetchData();
    }

    return (

        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0"><NavLink to="/" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; Exchange Rates</h1>
                        </div>
                        {/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <NavLink exact to={`${process.env.PUBLIC_URL}/`} activeClassName="selected" >Configurations</NavLink></li>
                                <li className="breadcrumb-item active">Exchange Rates List</li>
                            </ol>
                        </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>{/* /.container-fluid */}
            </div>
            <section class="content">
                <div className="card">
                    {/* <div className="card-header">
                        <h3 className="card-title">Exchange Rates Table</h3>
                        <div className="card-tools">

                            <div className='btn btn-tool'>
                                <div className="input-group input-group-sm" style={{ width: 150 }}>
                                    <button type="button" class="btn btn-block btn-outline-success btn-sm" onClick={handleUpdate}><i class="fas fa-sync fa-sm"></i>&nbsp;Update</button>
                                </div>

                            </div>
                        </div>
                    </div> */}
                    {/* /.card-header */}
                    <div className="card-body table-responsive p-0">
                        <table className="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                    <th>Base Currency</th>
                                    <th>Currency</th>
                                    <th>Rate</th>
                                    <th>Date</th>
                                    <th><button type="button" class="btn btn-block btn-outline-success btn-sm" onClick={handleUpdate}><i class="fas fa-sync fa-sm"></i>&nbsp;Update</button></th>
                                </tr>
                            </thead>
                            <tbody>
                                { makeList?.map((value) => {
                                    return (
                                        <tr>
                                            <td>{ value.base_currency }</td>
                                            <td>{ value.currency }</td>
                                            <td>{ value.rate }</td>
                                            <td>{ value.date }</td>
                                            <td></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/* /.card-body */}
                </div>
            </section>
        </div>
    )
}

export default CurrencyList