import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import CrudService from '../../util/crudService'
import { Cost } from './cost';
import { useTranslation } from 'react-i18next';

function SalesOrderEdit() {
    const { t } = useTranslation();
    const [id, setId] = useState(0);
    const [price, setPrice] = useState(0);
    const [priceSale, setPriceSale] = useState(0);
    const [total, setTotal] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [amountPaid, setAmountPaid] = useState(0);
    const [remainingAmount, setRemainingAmount] = useState(0);
    const [remark, setRemark] = useState('');

    const [listingData, setListingData] = useState([]);
    //const [customerData, setCustomerData] = useState([]);
    const [vendorId, setVendorId] = useState(0);
    const [currency, setCurrency] = useState(0);
    const [type, setType] = useState(0);
    const [desc, setDesc] = useState('');
    const [itemType, setItemType] = useState(0);
    const [listingId, setListingId] = useState(0);
    const [qty, setQty] = useState(0);

    const [vendorList, setVendorList] = useState([]);
    const [costList, setCostList] = useState([]);
    const [detailList, setDetailList] = useState([]);
    const [exrate, setExrate] = useState(() => FetchExchangeRate());

    const [totalCost_, setTotalCost_] = useState(0);

    const [lockCurrency, setLockCurrency] = useState(false);
    
    const [orderData, setOrderData] = useState([]);
    //const [orderDeailData, setOrderDetailData] = useState([]);
    const [costData, setCostData] = useState([]);
    const [vendorData, setVendorData] = useState([]);

    const [refresh, setRefresh] = useState(0);

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
 

    function FetchExchangeRate() {
        //axios.get(`https://openexchangerates.org/api/latest.json?app_id=e49e070324e8444b855386876df2c682&symbols=JPY`)
        axios.get(`https://openexchangerates.org/api/latest.json?app_id=955708c18bae414d9f818c87d3364969&base=JPY&symbols=USD`)
        .then((res) => {
            console.log(res.data);
            console.log(res.data.rates);
            console.log(res.data.rates.USD);
            setExrate(res.data.rates.USD);
            return res.data.rates.USD;
        })
        .catch((err) => console.error(err))
    }

    //When mount
    useEffect(() => {
        //Vendor List
        //axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/vendor?page=${0}&pageSize=${20}`)
        CrudService.GetData(navigate, location, 'vendor/basic')
        .then((res) => {
            setVendorList(res.data);
        })
        .catch((err) => console.error(err))

        //Listing List
        CrudService.GetData(navigate, location, 'listing/basic-all') //, 0, 500
        .then((res) => {
            setListingData(res.data.data);
        })
        .catch((err) => console.error(err))

    }, [])

    //If Ref Id exists
    useEffect(() => {

        if (params.id) {
            setId(params.id);

            try {
                CrudService.GetDataById(navigate, location, 'sale/invoice', params.id)
                .then((res) => {
                    console.log(res.data);
                    setOrderData(res.data.order);
                    setDetailList(res.data.detail); //setOrderDetailData(res.data.detail);
                    setRemark(res.data.order.remark);
                    //setCostList(res.data.cost); 
                    //setCostData(res.data.cost);
                    //console.log('Set Cost Data');

                    setVendorData(res.data.vendor);
                    console.log('res.data.order.sub_total:' + res.data.order.sub_total);
                    setSubTotal(parseFloat(res.data.order.sub_total) - (parseFloat(res.data.total_cost) || 0)); //- (parseFloat(res.data.order.total_cost) || 0)
                    (res.data.order.discount == null) ? setTotal(res.data.order.sub_total) : setTotal(parseFloat(res.data.order.sub_total) - parseFloat(res.data.order.discount));
                    setAmountPaid(parseFloat(res.data.order.amount_paid));
                    setRemainingAmount(parseFloat(res.data.order.amount_remaining)); 
                });
                
            } catch (error) {
                console.error(error);
            }
        }

        
    }, [params.id])

    //When Ref changes
    useEffect(() => {

        if (listingId != 0) {
            CrudService.GetDataById(navigate, location, 'listing', listingId)
            .then((res) => {
                setDesc(res.data.title);

                if (orderData.currency == 2) {
                    //var exrate = '143.695'; // FetchExchangeRate();
                    console.log('exrate:' + exrate);
                    setPrice((parseFloat(res.data.price) * parseFloat(exrate)).toFixed(2));
                }else{
                    setPrice(res.data.price);
                }
                setPriceSale(res.data.price_sale);
            })
            .catch((err) => {
                alert(err)
            })
        }

        
    }, [listingId])


    const handleDetailAdd = () => {
        const data = {
            id: 0,
            type: itemType,
            ref_no: listingId,
            ref_desc: desc,
            qty: qty,
            sub_total: price,
            new: true,
        }
        
        console.log('handleDetailAdd : ' + parseFloat(subTotal) + ' - ' + parseFloat(data.sub_total))    
        
        if (!validateDetailData(data))
        {
            alert('Invalid Data');
            return;
        }

        // if (params.id){
        //     if (currency == 2) {
        //         console.log('exrate:' + exrate);
        //         setPrice((parseFloat(res.data.price) * parseFloat(exrate)).toFixed(2));
        //     }else{
        //         setPrice(res.data.price);
        //     }
        // }

        let data_ = [...detailList, data];
        setDetailList(data_);
        setTotal(parseFloat(total) + parseFloat(data.sub_total));
        setSubTotal(parseFloat(subTotal) + parseFloat(data.sub_total));
        calRemaining(parseFloat(subTotal) + parseFloat(data.sub_total), amountPaid);
        resetFields('ALL');
        setLockCurrency(true);
    }

    //Calculate Sub Total when cost list change
    useEffect(() => {
        let totoalCost = 0;
        costList.map((item) => {
            totoalCost += parseFloat(item.value); //item.value;
        })
        //setTotal(parseFloat(total) + parseFloat(totoalCost));
        //setSubTotal(parseFloat(subTotal) + parseFloat(totoalCost) );
        //calRemaining(parseFloat(subTotal) + parseFloat(totoalCost), amountPaid);

        setTotalCost_(totoalCost);
        calRemaining2(totoalCost);
    }, [costList])


    // useEffect(() => {
    //     console.log('Set CostList');
    //     costData.map((item) => {
    //         console.log('item.cost ' + item.cost);
    //         console.log('item.amount ' + item.amount);
    //         setCostList([...costList, { name: item.cost, value: item.amount, cost_type: item.type }])
    //     })
    // }, [costData])

    //Delete Detail line
    /*const handleDetailDelete = (index_) => {
        setDetailList((detailList) =>
            detailList.filter((item, index) => index !== index_)
        );

        console.log('Length:' + detailList.length)
        if (detailList.length <= 1){
            setLockCurrency(false);
        }
    }*/

    const handleDetailDelete = (index_) => {
        console.log('handleDetailDelete : ' + parseFloat(subTotal))    
        setDetailList((detailList) =>
            detailList.filter((item, index) => index !== index_)
        );

        console.log('Length:' + detailList.length)
        if (detailList.length <= 1){
            setLockCurrency(false);
        }

        console.log('index_:' + index_);
        console.log('ind val :' + parseFloat(detailList[index_]?.sub_total));

        setTotal(parseFloat(total) - (parseFloat(detailList[index_]?.sub_total) || 0));
        setSubTotal(parseFloat(subTotal) - (parseFloat(detailList[index_]?.sub_total) || 0));
        //setAmountPaid(0);
        setDiscount(0);

        console.log('parseFloat(remainingAmount) : ' + parseFloat(remainingAmount));
        setRemainingAmount((remainingAmount) => (parseFloat(remainingAmount) - parseFloat(detailList[index_]?.sub_total)) );
    }

    const handleDetailEdit = (index_, key_, value_) => {
        //let sub_total_ = 0;
        const edited_ = detailList.map((value, index) => {
            if (index == index_){
                if (key_ == 'sub_total')
                    return {...value, sub_total: value_};
                else if (key_ == 'ref_desc')
                    //return value.ref_desc = value_;
                    return {...value, ref_desc: value_};
                else if (key_ == 'qty')
                    //return value.qty = value_;
                    return {...value, qty: value_};
            } else {
                return value;
            }

            // if (key_ == 'sub_total') {
            //     sub_total_ += value_;
            // }
        })

        setDetailList(edited_);
        // if (key_ == 'sub_total') {
        //     setSubTotal(parseFloat(totalCost_) + sub_total_);
        //     calRemaining(parseFloat(totalCost_) + sub_total_, null)
        // }
    }

    const handleDetailEditUpdate = (index_, value_) => {
        console.log('handleDetailEditUpdate : ' + parseFloat(subTotal));    
        let sub_total_ = 0;
        
        const edited_ = detailList.map((value, index) => {
            console.log('index :' + index + ' index_ :' + index_);
            
            if (index != index_){
                sub_total_ += parseFloat(value.sub_total);
                console.log('value.sub_total :' + value.sub_total);
            } else {
                sub_total_ += parseFloat(value_);
                console.log('value_ :' + value_);
            }

            console.log('sub_total_ :' + sub_total_);

        })

        console.log('totalCost_ :' + totalCost_);

        setSubTotal(parseFloat(sub_total_));
        //setSubTotal(parseFloat(totalCost_) + parseFloat(sub_total_));
        //calRemaining(parseFloat(totalCost_) + parseFloat(sub_total_), null);
    }

    function validateDetailData(data) {
        if (data.type == 0)
            return false;
        else if (data.type == 1 && data.ref_no == 0)
            return false;
        else if (data.sub_total == 0 )
            return false;
        
        return true;
    }

    const resetFields = (option_) => {
        if (option_ == 'ALL')
            setItemType(0);
        
        setDesc('');
        setListingId(0);
        setQty(0);
        setPrice(0);
    }

    const validateData = () => {
        return (vendorId == 0 || amountPaid == 0) ? false : true;
    }

    //Submit Order
    const handleSubmit = (event) => {
        event.preventDefault();

        // if (!validateData()) {
        //     alert('Please fill the required field!');
        //     return;
        // }

        CrudService.PutData(navigate, location, 'sale', params.id,
        { 
            vendorId, subTotal, discount, amountPaid, remainingAmount, currency, type, remark, detailList, totalCost_, costList
        })
        .then((response) => {
            console.log(response.data);
            navigate(`${process.env.PUBLIC_URL}/sales-order-list`);
        })
        .catch((error) => {
            console.log(error);
        });

    };

    //Calculate Remaining Amount
    const calRemaining = (subTotal_, amountPaid_) => {
        console.log('Paid:' + amountPaid_);
        console.log('subTotal:' + subTotal_);
        //let val = parseFloat(subTotal_) - parseFloat(amountPaid_);
        //console.log('val : ' + val);
        //setRemainingAmount(val);

        if (amountPaid_ == null) {
            //let val = (parseFloat(subTotal) + parseFloat(totalCost_)) - parseFloat(amountPaid);
            let val = parseFloat(subTotal_) - parseFloat(amountPaid);
            console.log('val 1:' + val);
            setRemainingAmount(val);
        }else {
            let val = parseFloat(subTotal_) - parseFloat(amountPaid_);
            console.log('val 2:' + val);
            setRemainingAmount(val);
        }
    }

    const calRemaining2 = (totalCost_) => {
        let val = (parseFloat(subTotal) + parseFloat(totalCost_)) - parseFloat(amountPaid);
        setRemainingAmount(val);
    }

    const calDiscount = (value_) => {
        if (total > 0)
            setSubTotal(parseFloat(total) - parseFloat(value_));
        else
            setSubTotal(0);
    }

    const handleType = (value_) => {
        if (value_ == 1) {
            setCurrency(1);
            setLockCurrency(true);
        }else{
            setCurrency(0);
            setLockCurrency(false);
        }
    }

    useEffect(() => {
        let val = (parseFloat(subTotal) + parseFloat(totalCost_)) - parseFloat(amountPaid);
        console.log('Remain subTotal:' + parseFloat(subTotal));
        console.log('Remain amountPaid:' + parseFloat(amountPaid));
        console.log('Remain:' + val);
        setRemainingAmount(val.toFixed(2));
        console.log('--------------------------------------');
    }, [subTotal])

    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1><NavLink to="/sales-order-list" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; {t('order-pages.sell-vehicle')}</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
                                <li className="breadcrumb-item active">Sales Order</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className='col-md-12'>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <div className="row">
                                        <div className='col-md-12'>
                                            <h5>Sales Order</h5>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">{t('order-pages.vendor')}</label>
                                                <input type="text" className="form-control" id="vendor" placeholder="" value={vendorData?.name} readOnly />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="title">{t('order-pages.vendor')}</label>
                                                <input type="text" className="form-control" id="type" placeholder="" value={(orderData.type == 1) ? 'Domestic' : 'Export'} readOnly />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="slug">{t('order-pages.vendor')}</label>
                                                <input type="text" className="form-control" id="currency" placeholder="" value={(orderData.currency == 1) ? 'JPY' : 'USD'} readOnly />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="slug">{t('order-pages.vendor')}</label>
                                                <textarea type="text" className="form-control" id="desc" placeholder="" value={remark} onChange={e => setRemark(e.target.value)}/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-8'>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <div className='row'>
                                        <table className='col-12'>
                                            <tr>
                                                <th>{t('order-pages.type')}</th>
                                                <th>{t('order-pages.ref')}</th>
                                                <th>{t('order-pages.description')}</th>
                                                <th>{t('order-pages.qty')}</th>
                                                <th>{t('order-pages.price')}</th>
                                                <th></th>
                                            </tr>
                                            <tbody>
                                                <tr>
                                                    <td width={'15%'}>
                                                        <select class="form-control" id="modelCode" value={itemType} onChange={(e) => { setItemType(e.target.value); (e.target.value == 1) ? setQty(1) : resetFields(''); }}>
                                                            <option value='0'>-- Select {t('order-pages.type')} --</option>
                                                            <option value='1'>Inventory</option>
                                                            <option value='2'>Non-Inv</option>
                                                        </select>
                                                    </td>
                                                    <td width={'20%'}>
                                                        {/* { itemType != 2 && */}
                                                        <select class="form-control" id="modelCode" value={listingId} onChange={(e) => { setListingId(e.target.value); (e.target.value != 0 && itemType == 0) ? setItemType(1) : void(0); (e.target.value != 0 && qty != 1) ? setQty(1) : void(0) }} disabled={(itemType == 2 ? true : false)}>
                                                            <option value='0'>-- Select {t('order-pages.reference')} --</option>
                                                            {
                                                                listingData?.map((item, index) => {
                                                                    return(
                                                                        <option value={item.id} key={index}>{item.ref_no} | {item.title}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        {/* } */}
                                                    </td>
                                                    <td>
                                                        <input type="text" className="form-control" id="desc" placeholder="" value={desc} onChange={(e) => { setDesc(e.target.value) } } />
                                                    </td>
                                                    <td width={'10%'}>
                                                        <input type="number" className="form-control" id="qty" placeholder="" value={qty} onChange={(e) => { setQty(e.target.value) }} disabled={(itemType == 1 ? true : false)} />
                                                    </td>
                                                    <td>
                                                        <input type="text" className="form-control" id="price" placeholder="" value={price} onChange={(e) => { setPrice(e.target.value) } } />
                                                    </td>
                                                    <td>
                                                        <button className='btn btn-success' onClick={handleDetailAdd}> <i class="fas fa-plus"></i></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><hr></hr></td>
                                                    <td><hr></hr></td>
                                                    <td><hr></hr></td>
                                                    <td><hr></hr></td>
                                                    <td><hr></hr></td>
                                                    
                                                </tr>
                                                {
                                                    detailList?.map((item, index) => {
                                                        return(
                                                            <tr>
                                                                <td>
                                                                    {/* {item.type}; */}
                                                                    <select class="form-control" id="modelCode" value={item.type} onChange={(e) => { item.type = e.target.value }} disabled={true}>
                                                                        <option value='0'>-- Select Type --</option>
                                                                        <option value='1'>Inventory</option>
                                                                        <option value='2'>Non-Inv</option>
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    {/* {item.ref_no} */}
                                                                    <select class="form-control" id="modelCode" value={item.ref_no} onChange={(e) => { item.ref_no = e.target.value }} disabled={( !item.new ? true : (item.type == 2 ? true : false))}>
                                                                        <option value='0'>-- Select Ref --</option>
                                                                        {
                                                                            listingData?.map((item, index) => {
                                                                                return(
                                                                                    <option value={item.id} key={index}>{item.ref_no} | {item.title}</option>
                                                                                )
                                                                        })}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    {/* {item.ref_desc} */}
                                                                    <input type="text" className="form-control" id="title" placeholder="" value={item.ref_desc} onChange={(e) => { handleDetailEdit(index, 'desc', e.target.value); } } /> {/* disabled={( !item.new ? true : false)} */}
                                                                </td>
                                                                <td>
                                                                    {/* {item.qty} */}
                                                                    <input type="number" className="form-control" id="title" placeholder="" value={item.qty} onChange={(e) => { handleDetailEdit(index, 'qty', e.target.value); } }  disabled={( item.type == 1 ? true : false )} /> {/* disabled={( !item.new ? true : item.type == 1 ? true : false)} */}
                                                                </td>
                                                                <td>
                                                                    {/* {item.sub_total} */}
                                                                    <input type="text" className="form-control" id="subTotal" placeholder="" value={item.sub_total} onChange={(e) => { handleDetailEdit(index, 'sub_total', e.target.value); handleDetailEditUpdate(index, e.target.value) } } /> { /* disabled={( !item.new ? true : false)} */}
                                                                </td>
                                                                <td>
                                                                    <button className='btn btn-danger' onClick={() => handleDetailDelete(index)} disabled={( !item.new ? true : false)}><i class="fas fa-trash"></i></button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        
                                    </div>
                                    {/* --- */}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <Cost costList={setCostList} data={costList} refresh={refresh} orderId={params.id} t={t} />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    {/* /.row */}
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="card card-primary">
                                <div className="card-body">
                                    <div className='row'>
                                        {/* <div className='col-md-2'>
                                            <div className="form-group">
                                                <label htmlFor="title">Total</label>
                                                <input type="text" className="form-control" id="title" placeholder="" value={total} readOnly />
                                            </div>
                                        </div>
                                        <div className='col-md-2'>
                                            <div className="form-group">
                                                <label htmlFor="slug">Discount</label>
                                                <input type="number" className="form-control" id="discount" placeholder="" value={orderData.discount} readOnly />
                                            </div>
                                        </div> */}
                                        <div className='col-md-2'>
                                            <div className="form-group">
                                                <label htmlFor="title">{t('order-pages.sub-total')}</label>
                                                <input type="text" className="form-control" id="title" placeholder="" value={parseFloat(subTotal) + parseFloat(totalCost_) } readOnly /> {/* + parseFloat(totalCost_) */}
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="title">{t('order-pages.amount-paid')}</label>
                                                <input type="text" className="form-control" id="title" placeholder="" value={orderData.amount_paid} readOnly /> { /* onChange={(e) => {setAmountPaid(e.target.value); calRemaining(e.target.value)}} */}
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="slug">{t('order-pages.amount-remaining')}</label>
                                                <input type="text" className="form-control" id="slug" placeholder="" value={remainingAmount} readOnly />
                                            </div>
                                        </div>
                                        {/* <div class="form-check">
                                            <input class="form-check-input" type="checkbox" />
                                            <label class="form-check-label">Loream Espisum 1</label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" />
                                            <label class="form-check-label">Loream Espisum 2</label>
                                        </div> */}
                                    </div>
                                    
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <button type="submit" className="btn btn-success btn-block" onClick={handleSubmit}>{t('order-pages.proceed')}</button>
                        </div>
                    </div>

                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>

    )
}

export default SalesOrderEdit