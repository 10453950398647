import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import CrudService from '../../util/crudService'
import { ToastContainer, toast } from 'react-toastify';

const FilterDiv = (props) => {
    const [type_, setType_] = useState('');
    const [avail_, setAvail_] = useState('');
    const [vin_, setVin_] = useState('');
    const [title_, setTitle_] = useState('');
    const [make_, setMake_] = useState('');
    const [category_, setCategory_] = useState('');
    const [status_, setStatus_] = useState('');

    //let navigate = props.navigation;
    //let locationHook = props.locationHook;

    const handleFilter = () => {
        console.log('type :' + type_)
        let data = { type: type_ }
        props.searchQuery(data);
    }

    return (
        <section class="content">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className='col-md-2'>
                            <div className="form-group">
                                {/* <label htmlFor="refNo">Type</label> */}
                                <select class="form-control form-control-sm" id="modelCode" value={type_} onChange={(e) => { setType_(e.target.value);}}>
                                    <option value=''>-- Type --</option>
                                    <option value='1'>Monthly</option>
                                    <option value='2'>Quartaly</option>
                                    <option value='3'>Yearly</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <select class="form-control form-control-sm" id="modelCode" value={status_} onChange={(e) => { setStatus_(e.target.value);}}>
                                    <option value=''>-- Status --</option>
                                    <option value='1'>Published</option>
                                    <option value='2'>Un-published</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <input type="text" className="form-control form-control-sm" id="vin" placeholder="VIN" value={vin_} onChange={(e) => setVin_(e.target.value)}/>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <input type="text" className="form-control form-control-sm" id="title" placeholder="Title" value={title_} onChange={(e) => setTitle_(e.target.value)}/>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <button className='btn btn-info btn-sm btn-block' onClick={handleFilter}>Filter</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )      
}

function SalesReport() {
    const navigate = useNavigate();
    const location = useLocation();
    const [listingList, setListingList] = useState([]);
    //const [refresh, setRefresh] = useState(false);

    const [type_, setType_] = useState('');
    const [month_, setMonth_] = useState('');
    const [year_, setYear_] = useState('');
    const [quarter_, setQuarter_] = useState('');


    const [currPage, setCurrPage] = useState(1);
    const [page_, setPage_] = useState(1);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);

    const [showFilter, setShowFilter] = useState(false)
    const handleFilter = () => setShowFilter(!showFilter)

    const [searchQuery, setSearchQuery] = useState([]);

    const goToTop = () => {
        window.scrollTo({
            top: 0, //700
            behavior: 'smooth',
        });
    };

    //Next Page
    const nextPage = () => {
        if (hasNext)
            setPage_(currPage + parseInt('1'));
    }

    //Previous Page
    const prevPage = () => {
        if (hasPrev)
            setPage_(currPage - parseInt('1'));
    }

    const gotoPage = (page_) => {
        if (hasNext)
            setPage_(parseInt(page_));
    }

    const handleSearch = () => {
        setListingList([]); // Clear previous filtered data

        if (type_ == 1){
            CrudService.GetDataWithParam(navigate, location, 'report/sale/month', 
            { 
                page: page_, 
                type: type_,
                month: month_
            })
            .then((res) => {
                console.log(res.data);
                setListingList(res.data.data);
                //setCurrPage(parseInt(res.data.curr_page));
                //setHasNext(res.data.hasNext);
                //setHasPrev(res.data.hasPrev);
            })

        }
        else if (type_ == 3){
            CrudService.GetDataWithParam(navigate, location, 'report/sale/year', 
            { 
                page: page_, 
                type: type_,
                year: year_,
                quarter: quarter_,
            })
            .then((res) => {
                console.log(res.data);
                setListingList(res.data.data);
                //setCurrPage(parseInt(res.data.curr_page));
                //setHasNext(res.data.hasNext);
                //setHasPrev(res.data.hasPrev);
            })
        }
        else if (type_ == 2){
            CrudService.GetDataWithParam(navigate, location, 'report/sale/quarter', 
            { 
                page: page_, 
                type: type_,
                quarter: quarter_,
            })
            .then((res) => {
                console.log(res.data);
                setListingList(res.data.data);
                //setCurrPage(parseInt(res.data.curr_page));
                //setHasNext(res.data.hasNext);
                //setHasPrev(res.data.hasPrev);
            })
        }
    

    }

    const handleExcel = async (e) => {

        try {
            const response = await CrudService.GetFileData(navigate, location, 'report/sale/month', 
            {
                type: type_,
                year: year_,
                quarter: quarter_,
                export_: true
            });

            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'sales_report.xlsx');
            document.body.appendChild(link);
            link.click();

        } catch (error) {
            console.error('Error downloading the Excel file:', error);
        }
    }
    

    // useEffect(() => {
    //     handleSearch();
    // },
    // [page_, searchQuery])

    

    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0"><NavLink to="/" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; Sales Reports</h1>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <NavLink exact to="/" activeClassName="selected" >Dashboard</NavLink></li>
                                <li className="breadcrumb-item active">Sales Report</li>
                            </ol>
                        </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>{/* /.container-fluid */}
            </div>

            {/* <FilterDiv navigate={navigate} locationHook={location} searchQuery={setSearchQuery} /> */}

            <section class="content">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className='col-md-2'>
                                <div className="form-group">
                                    {/* <label htmlFor="refNo">Type</label> */}
                                    <select class="form-control form-control-sm" id="type" value={type_} onChange={(e) => { setType_(e.target.value);}}>
                                        <option value=''>-- Type --</option>
                                        <option value='1'>Monthly</option>
                                        <option value='2'>Quartaly</option>
                                        <option value='3'>Yearly</option>
                                    </select>
                                </div>
                            </div>
                            {
                                (type_ == 1) &&
                                    <div className='col-md-2'>
                                        <div className="form-group">
                                            {/* <label htmlFor="refNo">Type</label> */}
                                            <select class="form-control form-control-sm" id="month" value={month_} onChange={(e) => { setMonth_(e.target.value);}}>
                                                <option value=''>-- Month --</option>
                                                <option value='1'>January</option>
                                                <option value='2'>February</option>
                                                <option value='3'>March</option>
                                                <option value='4'>April</option>
                                                <option value='5'>May</option>
                                                <option value='6'>June</option>
                                                <option value='7'>July</option>
                                                <option value='8'>August</option>
                                                <option value='9'>September</option>
                                                <option value='10'>October</option>
                                                <option value='11'>November</option>
                                                <option value='12'>December</option>
                                            </select>
                                        </div>
                                    </div>
                            }
                            {
                                (type_ == 2) &&
                                    <div className='col-md-2'>
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-sm" id="quarter" placeholder="Quarter" value={quarter_} onChange={(e) => setQuarter_(e.target.value)}/>
                                        </div>
                                    </div>
                            }
                            {
                                (type_ == 3) &&
                                    <div className='col-md-2'>
                                        <div className="form-group">
                                            {/* <label htmlFor="refNo">Type</label> */}
                                            <input type="text" className="form-control form-control-sm" id="year" placeholder="Year" value={year_} onChange={(e) => setYear_(e.target.value)}/>
                                        </div>
                                    </div>
                            }
                            
                            <div className='col-md-2'>
                                <div className="form-group">
                                    <button className='btn btn-info btn-sm btn-block' onClick={handleSearch}>Filter</button>
                                </div>
                            </div>

                            <div className='col-md-2'>
                                <div className="form-group">
                                    <button className='btn btn-info btn-sm btn-block' onClick={handleExcel}>Export</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="content">
                <div className="card">
                    
                    <div className="card-body table-responsive p-0">
                        <table className="table table-hover text-nowrap table-bordered">
                            <thead>
                                <tr>
                                    <th>Financial Year</th>
                                    <th>Year</th>
                                    <th>Month / Quarter</th>
                                    <th align='center'>Sub Total</th>
                                    {/* <th>Discount</th> */}
                                    <th align='right'>Received</th>
                                    <th>Remaining</th>
                                </tr>
                            </thead>
                            <tbody>
                                { listingList?.map((value) => {
                                    return(
                                    <tr>
                                        <td width={'10%'}>{value.financial_year}</td>
                                        <td width={'5%'}>{value.year}</td>
                                        <td width={'20'} align='center'>{ (value.month) ? value.month : (value.quarter) ? value.quarter : '-'}</td>
                                        <td align='right'>{value.sub_total}</td>
                                        {/* <td>{value.discount}</td> */}
                                        <td align='right'>{value.amount_paid}</td>
                                        <td align='right'>{value.amount_remaining}</td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/* /.card-body */}
                    <div className='card-footer clearfix'>
                        {/* <div className="center-block text-center">
                            <ul className="pagination mb-3">
                                { (hasPrev == 'true') &&
                                <li className={"page-item page-prev "+ ( hasPrev ? " " : "disabled")}>
                                    <a className="page-link" href="javascript:void(0);" tabindex="-1" onClick={ () => prevPage()}>Prev</a>
                                </li>
                                }
                                { (hasNext == 'true') &&
                                    <li className={"page-item page-next "+ ( hasNext ? " " : "disabled")}>
                                        <a className="page-link" href="javascript:void(0);" onClick={ () => nextPage()}>Next</a>
                                    </li>
                                }
                            </ul>
                        </div> */}
                        {/* <ul class="pagination pagination-sm">
                            { (hasPrev == 'true') &&
                                <li class="page-item"><a class="page-link" href="javascript:void(0);" tabindex="-1" onClick={ () => prevPage()}>«</a></li>
                            }
                            <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(1)}>1</a></li>
                            <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(2)}>2</a></li>
                            <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(3)}>3</a></li>
                            { (hasNext == 'true') &&
                                <li class="page-item"><a class="page-link" href="javascript:void(0);" onClick={ () => nextPage()}>»</a></li>
                            }
                        </ul> */}
                    </div>
                </div>
            </section>
            <ToastContainer 
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" 
            />
        </div>
    )
}

export default SalesReport