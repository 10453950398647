import React from 'react'
import Placeholder from '../components/home/placeholder';
import SideNav from '../components/home/sideNav';
import Footer from '../components/home/footer';
import SellListing from '../components/listing/sellListing';

export const SellListingPage = () => (
    <div class="wrapper">
        <Placeholder />
        <SideNav />
        <SellListing />
        <Footer />
    </div>
);