import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import CrudService from '../../util/crudService'

import 'react-toastify/dist/ReactToastify.css';
import { NavLink, useLocation, useNavigate } from "react-router-dom";

const Uploader = () => {
    const locationHook = useLocation();
    const navigate = useNavigate();

    const [csvFile, setCsvFile] = useState();
    const [data, setData] = useState([]);
    //var fileName = '';
    const [fileName, setFileName] = useState('');

    const handleUpload = (event) => {
        setCsvFile(event.target.files[0]);
        //fileName = event.target.files[0].name;
        setFileName(event.target.files[0].name);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("csv", csvFile);

        toast.info("Please wait.. Uploading is in Progress.");

        CrudService.PostFileData(navigate, locationHook, 'csv/upload', formData) 
        .then((res) => {
            if (res.status === 200) {
                setData(res.data);
                toast.success("Upload Completed. Data processing in Progress.");
            }
        })
        .catch((err) => {
            toast.error('Error Occured');
            console.error(err);
        });

        // axios({
        //     method: "POST",
        //     url: `${process.env.REACT_APP_API_END_POINT}/api/admin/csv/upload`,
        //     headers: {
        //         "Content-Type": "multipart/form-data",
        //     },
        //     data: formData,
        // })
        //     .then((res) => {
        //         if (res.status === 200) {
        //             setData(res.data);
        //             //alert('Upload Completed');
        //             toast.success("Upload Completed. Data processing in Progress.");
        //         }
        //     })
        //     .catch((err) => {
        //         alert('Error Occured');
                
        //         console.error(err);
        //     });
    };

    return(
        <form>
            <div className="form-group">
                <label htmlFor="imageupload"> Select files: { fileName }</label>
                <div className="input-group">
                    <div className="custom-file">
                        <input type="file" className="custom-file-input" id="imageupload" name="csv" onChange={ handleUpload } accept=".csv,text/csv"/>
                        <label className="custom-file-label" htmlFor="exampleInputFile"> {fileName ? fileName : 'Choose File'}</label>
                    </div>
                </div>
            </div>
            <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Upload</button>
        </form>
    )
}

const UploadCSV = () => {

    return (
        // <div>
        //     <input type="file" name="csv" onChange={ handleUpload } />
        //     <button onClick= { handleSubmit }>Submit</button>
        //     <table>
        //         <thead>
        //             <tr>
        //                 {data.map((column) => (
        //                     <th key={column}>{column}</th>
        //                 ))}
        //             </tr>
        //         </thead>
        //         <tbody>
        //             {data.map((row) => (
        //                 <tr key={row.id}>
        //                     {row.map((cell) => (
        //                         <td key={cell}>{cell}</td>
        //                     ))}
        //                 </tr>
        //             ))}
        //         </tbody>
        //     </table>
        // </div>
 
        <div className="content-wrapper"> {/* style={{ minHeight: '1345.6px' }} */}
        {/* Content Header (Page header) */}
        <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1><NavLink to="/" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; Import CSV</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
                            <li className="breadcrumb-item active">Import CSV</li>
                        </ol>
                    </div>
                </div>
            </div>{/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    {/* left column */}
                    <div className="col-md-8">
                        <div className="card card-secondary">
                            {/* <div className="card-header">
                                <h3 className="card-title">CSV</h3>
                            </div> */}
                            {/* /.card-header */}

                            <div className="card-body">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        {/* form start */}
                                        <Uploader />
                                        <ToastContainer 
                                            position="top-right"
                                            autoClose={3000}
                                            hideProgressBar={false}
                                            newestOnTop={false}
                                            closeOnClick
                                            rtl={false}
                                            pauseOnFocusLoss
                                            draggable
                                            pauseOnHover
                                            theme="colored" 
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* /.row */}
            </div>{/* /.container-fluid */}
        </section>
        {/* /.content */}
        </div>
    );
};

export default UploadCSV;