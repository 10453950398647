import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import CrudService from '../../util/crudService'

function LocationList() {
    const navigate = useNavigate();
    const location = useLocation();
    const [makeList, setMakeList] = useState([]);
    
    const fetchData = () => {
        try {
            CrudService.GetData(navigate, location, 'transmission-type/extend')
            .then((res) => {
                console.log(res.data);
                setMakeList(res.data.data);
            });
            
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleEdit = (id) => {
        console.log('ID : '+ id);
        navigate(`${process.env.PUBLIC_URL}/add-trans/${id}`);
    }

    const handleDelete = (id) => {

        if (window.confirm('Are you sure want to DELETE ?')){
            CrudService.DeleteData(navigate, location, 'transmission-type', id)
            .then((res) => {
                window.alert(res.data);
                //toast.success("Stock Delete " + res.data);
                console.log('Deleted :' + res);
                fetchData();
            })
            .catch((error) => {
                console.error(error);
            })
        }

    }

    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            {/* <h1 className="m-0">Transmission Types</h1> */}
                            <h1><NavLink to="/menu" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; Transmission Types</h1>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <NavLink exact to="/menu" activeClassName="selected" >Configurations</NavLink></li>
                                <li className="breadcrumb-item active">Transmission Types</li>
                            </ol>
                        </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>{/* /.container-fluid */}
            </div>
            <section class="content">
                <div className="card col-lg-8">
                    <div className="card-header">
                        {/* <h3 className="card-title">Responsive Hover Table</h3> */}
                        <div className="card-tools">
                            <div className='btn btn-tool'>
                                <NavLink exact to={`${process.env.PUBLIC_URL}/add-trans`} activeClassName="selected" >
                                    <button type="button" class="btn btn-block btn-outline-success btn-sm"><i class="fas fa-plus fa-sm"></i>&nbsp;Add Transmission Type</button>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body table-responsive p-0">
                        <table className="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Slug</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                { makeList?.map((value) => {
                                    return (
                                            <tr>
                                                <td>
                                                    <button className='btn btn-warning btn-xs' onClick={() => {handleEdit(value.id)}}><i class="fas fa-pencil-alt fa-sm"></i></button> &nbsp;
                                                    <button className='btn btn-danger btn-xs' onClick={() => handleDelete(value.id)}> <i class="far fa-trash-alt fa-sm"></i></button>
                                                </td>
                                                <td>{ value.name }</td>
                                                <td>{ value.slug }</td>
                                                <td>{ value.status == 1 ? <span class="badge bg-success">Enable</span>  : <span class="badge bg-danger">Disable</span> }</td>
                                            </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/* /.card-body */}
                </div>
            </section>
        </div>
    )
}

export default LocationList;
