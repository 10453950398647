import React from 'react'
import Placeholder from '../components/home/placeholder';
import SideNav from '../components/home/sideNav';
import Footer from '../components/home/footer';
import AddListing from '../components/listing/addListing';

export const AddList = () => (
    <div class="wrapper">
        <Placeholder />
        <SideNav />
        <AddListing />
        <Footer />
    </div>
);