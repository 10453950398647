import React from 'react'
import Placeholder from '../components/home/placeholder';
import SideNav from '../components/home/sideNav';
import Footer from '../components/home/footer';
import AddFeatures from '../components/features/addFeatures';

export const AddFeaturesPage = () => (
    <div class="wrapper">
        <Placeholder />
        <SideNav />
        <AddFeatures />
        <Footer />
    </div>
);