import React , { useEffect, useState } from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const navEntries__ = {
    stock: [
        { url: 'add-list', name: 'New Stock' },
        { url: 'listing', name: 'Stock List' },
        { url: 'stock-history', name: 'Old Stock' },
        { url: 'stock-vendor', name: 'Old Stock by Vendor' },
    ],
    order: [
        { url: 'sales-order', name: 'New Order' },
        { url: 'sales-order-list', name: 'Order List' },
        { url: 'sales-order-history-list', name: 'Cancelled Order List' },
    ],
    container: [
        { url: 'container', name: 'New Container' },
        { url: 'container-list', name: '' },
    ],
    order: [
        { url: 'sales-order', name: 'New Order' },
        { url: 'sales-order-list', name: 'Order List' },
        { url: 'sales-order-history-list', name: 'Cancelled Order List' },
    ]
}

const getNavEntries = (t) => {
    const navEntries = {
        stock: [
            { url: 'add-list', name: t('nav-bar.new-stock') },
            { url: 'listing', name: t('nav-bar.stock') },
            { url: 'stock-history', name: t('nav-bar.old-stock') },
            { url: 'stock-vendor', name: t('nav-bar.old-stock-vendor') },
        ],
        order: [
            { url: 'sales-order', name: t('nav-bar.new-order') },
            { url: 'sales-order-list', name: t('nav-bar.order-list') },
            { url: 'sales-order-history-list', name: t('nav-bar.cancelled-order-list') },
        ],
        container: [
            { url: 'container', name: t('nav-bar.new-container') },
            { url: 'container-list', name: t('nav-bar.container-list') },
        ],
        vendor: [
            { url: 'manage-customer', name: 'New ' + t('nav-bar.vendors') },
            { url: 'customer', name: t('nav-bar.vendor-list') },
        ],
        report: [
            { url: 'report-stock', name: t('nav-bar.stock-report') },
            { url: 'report-sales', name: t('nav-bar.sales-report') },
            { url: 'report-profit', name: t('nav-bar.profit-report') },
        ],
        user: [
            { url: 'manage-user', name: t('nav-bar.new-user') },
            { url: 'user', name: t('nav-bar.user-list') },
        ],
    }

    return navEntries;
}

// const navEntryArr = () => {
//     navEntries['stock'].forEach(e => {
//         return (
//             <div className="col-md-3">
//                 <div className="form-group">
//                     <NavLink exact to={`${process.env.PUBLIC_URL}/add-list`} activeClassName="selected" className="nav-link btn btn-outline-info">
//                         <i class="far fa-circle nav-icon"></i>
//                         <p>{ e.name } - New Stock</p>
//                     </NavLink>

//                 </div>
//             </div>
//         )
//     });
// };

function SideNav() {
    const navigate = useNavigate();
    const location = useLocation();
    const userLocal = JSON.parse(window.localStorage.getItem('user'));
    const { t } = useTranslation();
    const navEntries = getNavEntries(t);

    const [viewModal, setViewModal] = useState(false);
    const [selectedNav, setSelectedNav] = useState('');
    

    const logOut = (e) => {
        window.localStorage.removeItem('user');
        navigate("/");
    }

    useEffect(() => {
        setViewModal(false);

        navEntries['stock'].forEach(e => {
            console.log('101- ' + e.name + '/' + e.url )
            // navEntryArr.push(
            //     <div className="col-md-3">
            //         <div className="form-group">
            //             <NavLink exact to={`${process.env.PUBLIC_URL}/add-list`} activeClassName="selected" className="nav-link btn btn-outline-info">
            //                 <i class="far fa-circle nav-icon"></i>
            //                 <p>{ e.name } - New Stock</p>
            //             </NavLink>

            //         </div>
            //     </div>
            // )

            // navEntryArr.map((e) => {
            //     console.log(e);
            // })
        });

    }, [location, viewModal])

    return (
        <>
        <aside className="main-sidebar sidebar-dark-primary elevation-4">

            <a href="" className="brand-link">
                <img src="/favicon.png" alt="KAGA Logo" className="brand-image elevation-3" style={{ opacity: '.8' }} />
                <span className="brand-text font-weight-light">KAGA MOTORS</span>
            </a>

            <div className="sidebar">

                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        {/* /dist/img/user2-160x160.jpg */}
                        <img src="/dist/img/user9.jpg" className="img-circle elevation-2" alt="User Image" />
                    </div>
                    <div className="info">
                        <a href="#" className="d-block bold">{ userLocal.user }</a>
                    </div>
                </div>

                <div className="form-inline">
                    {/* <div className="input-group" data-widget="sidebar-search">
                        <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                        <div className="input-group-append">
                            <button className="btn btn-sidebar">
                                <i className="fas fa-search fa-fw" />
                            </button>
                        </div>
                    </div> */}
                </div>

                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <li className="nav-item">
                            <NavLink exact to={`${process.env.PUBLIC_URL}/`} activeClassName="selected" className="nav-link">
                                <i className="nav-icon fas fa-house-user" />
                                <p>
                                    {/* Dashboard  */}
                                    {t('nav-bar.dashboard')}
                                </p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <a href="" className="nav-link"  data-toggle="modal" data-target="#modal-nav" onClick={(e) => { e.preventDefault(); setViewModal(true); setSelectedNav('stock'); }}>
                                <i className="nav-icon fas fa-list" />
                                <p>
                                    {t('nav-bar.stock')}
                                    <i className="right fas fa-angle-right"></i>
                                </p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="" className="nav-link"  data-toggle="modal" data-target="#modal-nav" onClick={(e) => { e.preventDefault(); setViewModal(true); setSelectedNav('order'); }}>
                                <i className="nav-icon fas fa-list" />
                                <p>
                                    {t('nav-bar.order')}
                                    <i className="right fas fa-angle-right"></i>
                                </p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="" className="nav-link"  data-toggle="modal" data-target="#modal-nav" onClick={(e) => { e.preventDefault(); setViewModal(true); setSelectedNav('container'); }}>
                                <i className="nav-icon fas fa-car"></i>
                                <p>
                                    {t('nav-bar.container')}
                                    <i className="right fas fa-angle-right"></i>
                                </p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="" className="nav-link"  data-toggle="modal" data-target="#modal-nav" onClick={(e) => { e.preventDefault(); setViewModal(true); setSelectedNav('vendor'); }}>
                                <i className="nav-icon fas fa-car"></i>
                                <p>
                                    {t('nav-bar.vendors')}
                                    <i className="right fas fa-angle-right"></i>
                                </p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="" className="nav-link"  data-toggle="modal" data-target="#modal-nav" onClick={(e) => { e.preventDefault(); setViewModal(true); setSelectedNav('report'); }}>
                                <i className="nav-icon fas fa-car"></i>
                                <p>
                                    {t('nav-bar.reports')}
                                    <i className="right fas fa-angle-right"></i>
                                </p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="" className="nav-link"  data-toggle="modal" data-target="#modal-nav" onClick={(e) => { e.preventDefault(); setViewModal(true); setSelectedNav('user'); }}>
                                <i className="nav-icon fas fa-car"></i>
                                <p>
                                    {t('nav-bar.users')}
                                    <i className="right fas fa-angle-right"></i>
                                </p>
                            </a>
                        </li>

                        {/* ------------- */}

                        <li className="nav-item" hidden>
                            <a href="" className="nav-link">
                                <i className="nav-icon fas fa-list"></i>
                                <p>
                                    {/* Stock */}
                                    {t('nav-bar.stock')}
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <NavLink exact to={`${process.env.PUBLIC_URL}/add-list`} activeClassName="selected" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>
                                            {/* New Stock */}
                                            {t('nav-bar.new-stock')}
                                        </p>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink exact to={`${process.env.PUBLIC_URL}/listing`} activeClassName="selected" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>
                                            {/* Stock List */}
                                            {t('nav-bar.stock')}
                                        </p>
                                        {/* <p>{t('stock.stock_list')}</p> */}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink exact to={`${process.env.PUBLIC_URL}/stock-history`} activeClassName="selected" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>
                                            {/* Old Stock */}
                                            {t('nav-bar.old-stock')}
                                        </p>
                    
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink exact to={`${process.env.PUBLIC_URL}/stock-vendor`} activeClassName="selected" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>
                                            {/* Old Stock by Vendor */}
                                            {t('nav-bar.old-stock-vendor')}
                                        </p>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item" hidden>
                            <a href="" className="nav-link">
                            {/* <NavLink exact to={`#`} activeClassName="selected" className="nav-link"> */}
                                <i className="nav-icon fas fa-car"></i>
                                <p>
                                    {t('nav-bar.order')}
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            {/* </NavLink> */}
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <NavLink exact to={`${process.env.PUBLIC_URL}/sales-order`} activeClassName="selected" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>{t('nav-bar.new-order')}</p>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink exact to={`${process.env.PUBLIC_URL}/sales-order-list`} activeClassName="selected" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>{t('nav-bar.order-list')}</p>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink exact to={`${process.env.PUBLIC_URL}/sales-order-history-list`} activeClassName="selected" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>{t('nav-bar.cancelled-order-list')}</p>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item" hidden>
                            <a href="" className="nav-link">
                            {/* <NavLink exact to={`#`} activeClassName="selected" className="nav-link"> */}
                                <i className="nav-icon fas fa-car"></i>
                                <p>
                                    {t('nav-bar.container')}
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            {/* </NavLink> */}
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <NavLink exact to={`${process.env.PUBLIC_URL}/container`} activeClassName="selected" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>{t('nav-bar.new-container')}</p>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink exact to={`${process.env.PUBLIC_URL}/container-list`} activeClassName="selected" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>{t('nav-bar.container-list')}</p>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item" hidden>
                            <a href="" className="nav-link">
                                <i className="nav-icon fas fa-car"></i>
                                <p>
                                    {t('nav-bar.vendors')}
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <NavLink exact to={`${process.env.PUBLIC_URL}/manage-customer`} activeClassName="selected" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>New {t('nav-bar.vendors')}</p>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink exact to={`${process.env.PUBLIC_URL}/customer`} activeClassName="selected" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>{t('nav-bar.vendor-list')}</p>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item" hidden>
                            <a href="" className="nav-link">
                                <i className="nav-icon fas fa-car"></i>
                                <p>
                                    {t('nav-bar.reports')}
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <NavLink exact to={`${process.env.PUBLIC_URL}/report-stock`} activeClassName="selected" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>{t('nav-bar.stock-report')}</p>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink exact to={`${process.env.PUBLIC_URL}/report-sales`} activeClassName="selected" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>{t('nav-bar.sales-report')}</p>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink exact to={`${process.env.PUBLIC_URL}/report-profit`} activeClassName="selected" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>{t('nav-bar.profit-report')}</p>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <NavLink exact to={`${process.env.PUBLIC_URL}/menu`} activeClassName="selected" className="nav-link">
                                <i className="nav-icon fas fa-th"></i>
                                <p>
                                    {t('nav-bar.configurations')}
                                </p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact to={`${process.env.PUBLIC_URL}/csv`} activeClassName="selected" className="nav-link">
                                <i className="nav-icon fas fa-list" />
                                <p>
                                    {t('nav-bar.import-csv')}
                                </p>
                            </NavLink>
                        </li>
                        <li className="nav-item" hidden>
                            <a href="" className="nav-link">
                                <i className="nav-icon fas fa-car"></i>
                                <p>
                                    {t('nav-bar.users')}
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <NavLink exact to={`${process.env.PUBLIC_URL}/manage-user`} activeClassName="selected" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>{t('nav-bar.new-user')}</p>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink exact to={`${process.env.PUBLIC_URL}/user`} activeClassName="selected" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>{t('nav-bar.user-list')}</p>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li className="nav-item">
                            <NavLink exact to={`${process.env.PUBLIC_URL}/currency`} activeClassName="selected" className="nav-link">
                                <i className="nav-icon fas fa-list" />
                                <p>
                                    {t('nav-bar.exchange-rates')}
                                </p>
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink exact to={`${process.env.PUBLIC_URL}/data-jobs`} activeClassName="selected" className="nav-link">
                                <i className="nav-icon fas fa-list" />
                                <p>
                                    {t('nav-bar.data-jobs')}
                                </p>
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link" href="" role="button" onClick={(e) => logOut(e)}>
                                <i className="nav-icon fas fa-sign-out-alt fa-sm"></i>
                                <p>
                                    {t('nav-bar.logout')}
                                </p>
                            </a>    
                            {/* </NavLink> */}
                        </li>
                        <hr></hr>
                    </ul>
                </nav>
            </div>
        </aside>

        {/* //modal fade */}
        { viewModal && 
        <div class="modal fade" id="modal-nav"> 
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Sub Menu</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        {/* Tile row */}
                        <div className="row">
                            {
                                navEntries[selectedNav]?.map(e => (
                                    <div className='col-md-3'>
                                        <div className='form-group'>
                                            <NavLink exact to={`${process.env.PUBLIC_URL}/${e.url}`} activeClassName="selected" className="nav-link btn btn-outline-info">
                                                <i class="far fa-circle nav-icon"></i>
                                                <p>{ e.name }</p>
                                            </NavLink>

                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div class="modal-footer justify-content-between" hidden>
                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                    </div>
                </div>
                {/* <!-- /.modal-content --> */}
            </div>
            {/* <!-- /.modal-dialog --> */}
        </div>
        }
        {/* <!-- /.modal --> */}
        </>

    )
}

SideNav.propTypes = {

}

export default SideNav


