import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import CrudService from '../../util/crudService'
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import Select from 'react-select'
import { useTranslation } from 'react-i18next';

function ManageCustomer() {
    const { t } = useTranslation();

    const [id, setId] = useState(0);
    const [name, setName] = useState('');
    const [nameJp, setNameJp] = useState('');
    const [email, setEmail] = useState('');
    const [contactNo1, setContactNo1] = useState('');
    const [contactNo2, setContactNo2] = useState('');
    const [type, setType] = useState('Personal');
    const [locationType, setLocationType] = useState('Local');
    const [category, setCategory] = useState('Customer');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [status, setStatus] = useState(1);

    const [userId, setUserId] = useState(0);
    const [userList, setUserList] = useState([]);
    const [userData, setUserData] = useState([]); 
    
    const [createUser, setCreateUser] = useState(false);
    const [editUser, setEditUser] = useState(false);

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (params.id) {
            setId(params.id);

            try {

                CrudService.GetData(navigate, location, 'user')
                .then((res) => {
                    setUserList(res.data);
                })

                CrudService.GetDataById(navigate, location, 'vendor', params.id)
                .then((res) => {
                    console.log(res.data);
                    setId(res.data.id);
                    setName(res.data.name);
                    setNameJp(res.data.name_jp);
                    setEmail(res.data.email);
                    setContactNo1(res.data.contact_no1);
                    setContactNo2(res.data.contact_no2);
                    setType(res.data.type);
                    setLocationType(res.data.location);
                    setCategory(res.data.category);
                    setAddress1(res.data.address1);
                    setAddress2(res.data.address2);
                    setCity(res.data.city);
                    setState(res.data.state);
                    setCountry(res.data.country);
                    setPostalCode(res.data.postal_code);
                    setStatus(res.data.status);
                    setUserId(res.data.user_id);
                    setUserData(res.data.User);
                });
                
            } catch (error) {
                console.error(error);
            }
        }
    }, [params.id])

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('id :'+id);
        if (id == null || id == 0){
            CrudService.PostData(navigate, location, 'vendor', 
            {name, nameJp, email, contactNo1, contactNo2, type, locationType, category, address1, address2, city, state, country, postalCode, status, createUser, userId})
            .then((response) => {
                toast.success("Successfully Added");
                navigate(`${process.env.PUBLIC_URL}/customer`);
            })
            .catch((error) => {
                toast.error('Error:' + error);
                console.error(error);
            });

        } else {
            CrudService.PutData(navigate, location, 'vendor', id, 
            {name, nameJp, email, contactNo1, contactNo2, type, locationType, category, address1, address2, city, state, country, postalCode, status, createUser, userId})
            .then((response) => {
                console.log(response.data);
                navigate(`${process.env.PUBLIC_URL}/customer`);
            })
            .catch((error) => {
                toast.error('Error:' + error);
                console.error(error);
            });
        }

    };

    const handleCreateUser = (e) => {
        console.log('checked:' + e.checked);
        setCreateUser(e.checked);
        setUserId(0);
    }

    return (
        <div className="content-wrapper" style={{ minHeight: '1345.6px' }}>
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1><NavLink to="/customer" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; {t('vendor-page.manage-vendor')}</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><NavLink exact to="/customer" activeClassName="selected" >Customer List</NavLink></li>
                                <li className="breadcrumb-item active">Manage Customer</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        {/* left column */}
                        <div className="col-md-8">
                            {/* general form elements */}
                            <div className="card card-primary">
                                {/* <div className="card-header">
                                    <h3 className="card-title">Customer Info</h3>
                                </div> */}
                                {/* /.card-header */}
                                {/* form start */}
                                <form>
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="title">{t('vendor-page.name')}</label>
                                                    <input type="text" className="form-control" id="title" placeholder="" value={name} onChange={(e) => setName(e.target.value)}/>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="title">{t('vendor-page.name') + '-JP'}</label>
                                                    <input type="text" className="form-control" id="title" placeholder="" value={nameJp} onChange={(e) => setNameJp(e.target.value)}/>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="slug">{t('vendor-page.email')}</label>
                                                    <input type="text" className="form-control" id="slug" placeholder="" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="title">{t('vendor-page.mobile-no')}</label>
                                                    <input type="text" className="form-control" id="title" placeholder="" value={contactNo1} onChange={(e) => setContactNo1(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="title">{t('vendor-page.tel-no')}</label>
                                                    <input type="text" className="form-control" id="title" placeholder="" value={contactNo2} onChange={(e) => setContactNo2(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="slug">{t('vendor-page.type')}</label>
                                                    <select class="form-control" id="type" value={type} onChange={(e) => { console.log(e.target.value); setType(e.target.value);}}>
                                                        <option value="Personal">Personal</option>
                                                        <option value="Business">Business</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="slug">{t('vendor-page.location-type')}</label>
                                                    <select class="form-control" id="type" value={locationType} onChange={(e) => { console.log(e.target.value); setLocationType(e.target.value);}}>
                                                        <option value="Local">Local</option>
                                                        <option value="Export">Export</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {/* <div className='col-md-4'>
                                                <div className="form-group">
                                                    <label htmlFor="slug">Category</label>
                                                    <select class="form-control" id="type" value={category} onChange={(e) => { console.log(e.target.value); setCategory(e.target.value);}}>
                                                        <option value="Customer">Customer</option>
                                                        <option value="Supplier">Supplier</option>
                                                    </select>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="title">{t('vendor-page.address-1')}</label>
                                                    <input type="text" className="form-control" id="title" placeholder="" value={address1} onChange={(e) => setAddress1(e.target.value)}/>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="slug">{t('vendor-page.address-2')}</label>
                                                    <input type="text" className="form-control" id="slug" placeholder="" value={address2} onChange={(e) => setAddress2(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="title">{t('vendor-page.city')}</label>
                                                    <input type="text" className="form-control" id="title" placeholder="" value={city} onChange={(e) => setCity(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="slug">{t('vendor-page.state')}</label>
                                                    <input type="text" className="form-control" id="slug" placeholder="" value={state} onChange={(e) => setState(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="slug">{t('vendor-page.country')}</label>
                                                    <input type="text" className="form-control" id="slug" placeholder="" value={country} onChange={(e) => setCountry(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="slug">{t('vendor-page.postal-code')}</label>
                                                    <input type="text" className="form-control" id="slug" placeholder="" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                        {/* <div className="form-group">
                                            <label htmlFor="exampleInputFile"> Choose Image</label>
                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" id="exampleInputFile" />
                                                    <label className="custom-file-label" htmlFor="exampleInputFile">Choose file</label>
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text">Upload</span>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label htmlFor="exampleInputEmail1">{t('vendor-page.status')}</label>
                                                    <select class="form-control" id="status" value={status} onChange={(e) => { console.log(e.target.value); setStatus(e.target.value);}}>
                                                        <option value="1">Enable</option>
                                                        <option value="2">Disable</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                { !createUser &&
                                                    <div class="form-group">
                                                        <label htmlFor="exampleInputEmail1">{t('vendor-page.user')}</label>
                                                        <div className="row">
                                                            { !editUser && 
                                                                <>
                                                                    <div className='col-md-6'>
                                                                        <input type="text" className="form-control" id="userEmail" placeholder="" value={userData?.email} readOnly />
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                        <button className="btn btn-warning" onClick={ e => setEditUser(true) }>{t('vendor-page.edit')}</button>
                                                                    </div>
                                                                </>
                                                            }
                                                            { editUser && 
                                                                <div className='col-md-6'>
                                                                    <Select options={userList?.map((item, index) => ({ value: item.id, label: item.id + ' | ' + item.first_name + ' | ' + item.email }))} onChange={(e) => { console.log('e :' + e.value); setUserId(e.value);}} placeholder="-- No User --" defaultValue={(userId) ? userId : 0} />
                                                                </div>
                                                            }
                                                        </div>
                                                        {/* <select class="form-control" id="user" placeholder="" value={userId} onChange={(e) => setUserId(e.target.value)}>
                                                            <option value='0'>-- No User --</option>
                                                            { userList?.map((item) => {
                                                                return ( <option value={item.id}>{item.first_name + ' ' + item.Last_name + ' | ' + item.email}</option> )
                                                            })}
                                                        </select> */}
                                                        {/* <Select options={userList?.map((item, index) => ({ value: item.id, label: item.id + ' | ' + item.first_name + ' | ' + item.email }))} onChange={(e) => { console.log('e :' + e.value); setUserId(e.value);}} placeholder="-- No User --" defaultValue={(userId) ? userId : 0} /> */}
                                                    </div> 
                                                }
                                            </div>
                                        </div>

                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" onChange={(e) => {handleCreateUser(e.target)}} />
                                            <label class="form-check-label">{t('vendor-page.create-user')}</label>
                                        </div>

                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>{t('vendor-page.submit')}</button>
                                    </div>
                                </form>
                            </div>
                            {/* /.card */}
                            {/* general form elements */}
                            <div className="card card-secondary" hidden>
                                <div className="card-header">
                                    <h3 className="card-title">Misc Info</h3>
                                </div>
                                {/* /.card-header */}
                                {/* form start */}
                                <form>
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Created By</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Created At</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Updated By</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Updated At</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" />
                                                </div>
                                            </div>
                                        </div>  
                                    </div>
                                    {/* /.card-body */}
                                </form>
                            </div>
                            {/* /.card */}
                        </div>
                    </div>
                    {/* /.row */}
                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>
    )
}

export default ManageCustomer