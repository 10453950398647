import React from 'react'
import Placeholder from '../components/home/placeholder';
import SideNav from '../components/home/sideNav';
import Footer from '../components/home/footer';
import Login from '../components/auth/login';

// wrapper
export const LoginPage = () => (
    <div class="hold-transition login-page">
        <Login />
        {/* <Footer /> */}
    </div>
);