import React from 'react'
import Placeholder from '../components/home/placeholder';
import SideNav from '../components/home/sideNav';
import Footer from '../components/home/footer';
import StockVendor from '../components/listing/stockVendor';

export const StockVendorPage = () => (
    <div class="wrapper">
        <Placeholder />
        <SideNav />
        <StockVendor />
        <Footer />
    </div>
);