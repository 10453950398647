import React from 'react'
import Placeholder from '../components/home/placeholder';
import SideNav from '../components/home/sideNav';
import Footer from '../components/home/footer';

export const UnauthorizedPage = () => (
    <div class="wrapper">
        <Placeholder />
        <SideNav />
        

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div class="content-wrapper">
            {/* <!-- Content Header (Page header) --> */}
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>403 Access Denied</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active">403 Error Page</li>
                            </ol>
                        </div>
                    </div>
                </div>
                {/* <!-- /.container-fluid --> */}
            </section>

            {/* <!-- Main content --> */}
            <section class="content">
                <div class="error-page">
                    <h2 class="headline text-danger">403</h2>

                    <div class="error-content">
                        <h3><i class="fas fa-exclamation-triangle text-danger"></i> Oops! You're Not Allowed Here.</h3>

                        <p>
                            Please contact the IT Administration for any inquiries.
                            Meanwhile, you may <a href="/">return to dashboard</a>.
                        </p>

                        
                    </div>
                </div>
                {/* <!-- /.error-page --> */}

            </section>
            {/* <!-- /.content --> */}
        </div>
        {/* <!-- /.content-wrapper --> */}
        <Footer />
    </div>
);