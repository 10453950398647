import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import CrudService from '../../util/crudService'
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';

const FilterDiv = (props) => {
    const t = props.t;
    const [name_, setName_] = useState('');
    const [nameJp_, setNameJp_] = useState('');
    const [type_, setType_] = useState('');
    const [location_, setLocation_] = useState('');
    const [category_, setCategory_] = useState('');
    const [country_, setCountry_] = useState('');
    const [status_, setStatus_] = useState('');

    const handleFilter = () => {
        let data = { name: name_, nameJp: nameJp_, type: type_, location: location_, category: category_, country: country_, status: status_ }
        props.searchQuery(data);
    }

    return (
        <section class="content">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className='col-md-2'>
                            <div className="form-group">
                                <input type="text" className="form-control form-control-sm" id="name" placeholder={t('vendor-page.name')+'-EN'} value={name_} onChange={(e) => setName_(e.target.value)}/>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <input type="text" className="form-control form-control-sm" id="nameJp" placeholder={t('vendor-page.name')+'-JP'} value={nameJp_} onChange={(e) => setNameJp_(e.target.value)}/>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <select class="form-control form-control-sm" id="type" value={type_} onChange={(e) => { setType_(e.target.value);}}>
                                    <option value=''>-- {t('vendor-page.type')} --</option>
                                    <option value='Personal'>Personal</option>
                                    <option value='Business'>Business</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <select class="form-control form-control-sm" id="location" value={location_} onChange={(e) => { setLocation_(e.target.value);}}>
                                    <option value=''>-- {t('vendor-page.location-type')} --</option>
                                    <option value='Local'>Local</option>
                                    <option value='Export'>Export</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <input type="text" className="form-control form-control-sm" id="country" placeholder={t('vendor-page.country')} value={country_} onChange={(e) => setCountry_(e.target.value)}/>
                            </div>
                        </div>
                        {/* <div className='col-md-2'>
                            <div className="form-group">
                                <select class="form-control form-control-sm" id="category" value={category_} onChange={(e) => { setCategory_(e.target.value);}}>
                                    <option value=''>-- Category --</option>
                                    <option value='Supplier'>Supplier</option>
                                    <option value='Customer'>Customer</option>
                                </select>
                            </div>
                        </div> */}
                        <div className='col-md-2'>
                            <div className="form-group">
                                <select class="form-control form-control-sm" id="status" value={status_} onChange={(e) => { setStatus_(e.target.value);}}>
                                    <option value=''>-- Status --</option>
                                    <option value='1'>Enabled</option>
                                    <option value='2'>Disabled</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-md-1'>
                            <div className="form-group">
                                <button className='btn btn-info btn-sm btn-block' onClick={handleFilter}>Filter</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )      
}

function CustomerList() {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const [makeList, setMakeList] = useState([]);
    
    const [currPage, setCurrPage] = useState(1);
    const [page_, setPage_] = useState(1);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    const [showFilter, setShowFilter] = useState(true);
    const handleFilter = () => setShowFilter(!showFilter);
    const [searchQuery, setSearchQuery] = useState([]);
    
    //Next Page
    const nextPage = () => {
        if (hasNext)
            setPage_(currPage + parseInt('1'));
    }

    //Previous Page
    const prevPage = () => {
        if (hasPrev)
            setPage_(currPage - parseInt('1'));
    }

    const gotoPage = (page_) => {
        if (hasNext)
            setPage_(parseInt(page_));
    }

    const fetchData = () => {
        CrudService.GetDataWithParam(navigate, location, 'vendor/extend', {
            page: page_,
            name: searchQuery.name,
            nameJp: searchQuery.nameJp,
            email: searchQuery.email,
            contact: searchQuery.contact,
            type: searchQuery.type,
            location: searchQuery.location,
            category: searchQuery.category,
            country: searchQuery.country,
            status: searchQuery.status
        })
        .then((res) => {
            console.log(res.data);
            setMakeList(res.data.data);
            setCurrPage(parseInt(res.data.curr_page));
            setHasNext(res.data.hasNext);
            setHasPrev(res.data.hasPrev);
            setRowCount(res.data.count);
        }).catch((error) => {
            console.error(error);
        })
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [page_]);

    useEffect(() => {
        fetchData();
        setPage_(parseInt(1));
    }, [searchQuery]);

    const handleEdit = (id) => {
        console.log('ID : '+ id);
        navigate(`${process.env.PUBLIC_URL}/manage-customer/${id}`);
    }

    const handleDelete = (id) => {

        if (window.confirm('Are you sure want to DELETE ?')){
            CrudService.DeleteData(navigate, location, 'vendor', id)
            .then((res) => {
                window.alert(res.data);
                //toast.success("Stock Delete " + res.data);
                console.log('Deleted :' + res);
                fetchData();
            })
            .catch((error) => {
                console.error(error);
                toast.error('' + error);
            })
        }

    }

    //Pagination
    const pages = [];

    for (let i = 1; i <= (rowCount/10) + 1; i++) {
		pages.push(
			<li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(i)}>{i}</a></li>
		);
	}

    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0"><NavLink to="/menu" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; Vendors List</h1>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <NavLink exact to="/menu" activeClassName="selected" >Configurations</NavLink></li>
                                <li className="breadcrumb-item active">Vendors List</li>
                            </ol>
                        </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>{/* /.container-fluid */}
            </div>
            <section class="content">
                { showFilter && <FilterDiv searchQuery={setSearchQuery} t={t} /> }
                <div className="card">
                    <div className="card-header">
                        {/* <h3 className="card-title">Responsive Hover Table</h3> */}
                        <div className="card-tools">
                            <div className='btn btn-tool'>
                                <button type="button" class="btn btn-block btn-outline-warning btn-sm" onClick={handleFilter}><i class="fas fa-search fa-sm"></i></button>
                            </div>
                            <div className='btn btn-tool'>
                                <NavLink exact to={`${process.env.PUBLIC_URL}/manage-customer`} activeClassName="selected" >
                                    <button type="button" class="btn btn-block btn-outline-success btn-sm"><i class="fas fa-plus fa-sm"></i>&nbsp;{t('vendor-page.add-customer')}</button>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body table-responsive p-0">
                        <table className="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>{t('vendor-page.name')}-EN</th>
                                    <th>{t('vendor-page.name')}-JP</th>
                                    <th>{t('vendor-page.email')}</th>
                                    <th>{t('vendor-page.mobile-no')}</th>
                                    <th>{t('vendor-page.city')}</th>
                                    <th>{t('vendor-page.country')}</th>
                                    <th>{t('vendor-page.type')}</th>
                                    <th>{t('vendor-page.location-type')}</th>
                                    {/* <th>Category</th> */}
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                { makeList?.map((value) => {
                                    return (
                                            <tr>
                                                <td>
                                                    <button className='btn btn-warning btn-xs' onClick={() => {handleEdit(value.id)}}><i class="fas fa-pencil-alt fa-sm"></i></button> &nbsp;
                                                    <button className='btn btn-danger btn-xs' onClick={() => handleDelete(value.id)}> <i class="far fa-trash-alt fa-sm"></i></button>
                                                </td>
                                                <td>{ value.name }</td>
                                                <td>{ value.name_jp }</td>
                                                <td>{ value.email }</td>
                                                <td>{ value.contact_no1 }</td>
                                                <td>{ value.city }</td>
                                                <td>{ value.country }</td>
                                                <td>{ value.type }</td>
                                                <td>{ value.location }</td>
                                                {/* <td>{ value.category }</td> */}
                                                <td>{ value.status == 1 ? <span class="badge bg-success">Enable</span>  : <span class="badge bg-danger">Disable</span> }</td>
                                            </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/* /.card-body */}
                    <div className='card-footer clearfix'>
                        <ul class="pagination pagination-sm">
                            { (hasPrev == 'true') &&
                                <li class="page-item"><a class="page-link" href="javascript:void(0);" tabindex="-1" onClick={ () => prevPage()}>«</a></li>
                            }
                            {pages}
                            {/* <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(1)}>1</a></li>
                            <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(2)}>2</a></li>
                            <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(3)}>3</a></li> */}
                            { (hasNext == 'true') &&
                                <li class="page-item"><a class="page-link" href="javascript:void(0);" onClick={ () => nextPage()}>»</a></li>
                            }
                        </ul>
                    </div>
                </div>
            </section>
            <ToastContainer 
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" 
            />
        </div>
    )
}

export default CustomerList
