import React from 'react'
import Placeholder from '../components/home/placeholder';
import SideNav from '../components/home/sideNav';
import Footer from '../components/home/footer';
import MakeList from '../components/makes/makeList';

export const MakesPage = () => (
    <div class="wrapper">
        <Placeholder />
        <SideNav />
        <MakeList />
        <Footer />
    </div>
);