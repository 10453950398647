import React from 'react'
import Placeholder from '../components/home/placeholder';
import SideNav from '../components/home/sideNav';
import Footer from '../components/home/footer';
import LocationList from '../components/location/locationList';

export const LocationPage = () => (
    <div class="wrapper">
        <Placeholder />
        <SideNav />
        <LocationList />
        <Footer />
    </div>
);