import React from 'react'
import Placeholder from '../components/home/placeholder';
import SideNav from '../components/home/sideNav';
import Footer from '../components/home/footer';
import ModelList from '../components/models/modelList';

export const ModelPage = () => (
    <div class="wrapper">
        <Placeholder />
        <SideNav />
        <ModelList />
        <Footer />
    </div>
);