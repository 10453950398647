import React, { useEffect, useState } from 'react'
import CrudService from '../../../util/crudService';
import { useLocation, useNavigate } from 'react-router-dom';
import Fp from '../../../util/formatPrice';
//import { CostModal } from './costModal';

const PaymentModal = (props) => {
    const locationHook = useLocation();
    const navigate = useNavigate();

    const [amount, setAmount] = useState(0.00);
    const [date, setDate] = useState(new Date());
    const [remark, setRemark] = useState('');
    const [costList, setCostList] = useState([]);

    const handleAdd = (e) => {
        e.preventDefault();

        CrudService.PostData(navigate, locationHook, 'payment', 
        { 
            order_id: props.orderNo,
            payment_date: date, 
            amount: amount,
            remark: remark,
            type: 1,
        })
        .then((res) => {
            fetchData();
            props.refresh(true);
        })
        .catch((err) => {
            console.error(err);
        })
    }

    const fetchData = () => {
        CrudService.GetDataWithParam(navigate, locationHook, 'payment/order', { order_no: props.orderNo })
        .then((res) => {
            setCostList(res.data);
        })
        .catch((err) => {
            console.error(err);
        })
    } 

    const handleDelete = (id) => {
        if (window.confirm('Are you sure want to delete this Cost ?')){
            CrudService.DeleteData(navigate, locationHook, 'payment', id, { order_no: props.orderNo })
            .then((res) => {
                props.refresh(true);
            })
            .catch((err) => {
                console.error(err);
            })
        }
    }

    useEffect(() => {
        fetchData();
    }, [])
        
    // if (!props.show)
    //     return null;

    return (
        // //modal fade
        <div class="modal fade" id="modal-pay"> 
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Payment Manager</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="amount" placeholder="Value" value={amount} onChange={(e) => setAmount(e.target.value)} />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="form-group">
                                    <input type="date" className="form-control" id="date" placeholder="Date" value={date} onChange={(e) => setDate(e.target.value)} />
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="remark" placeholder="Bank / Cash" value={remark} onChange={(e) => setRemark(e.target.value)} />
                                </div>
                            </div>
                            <div className='col-md-2'>
                                <div className="form-group">
                                    <button className='btn btn-success' onClick={handleAdd}>Add</button>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-10">
                                <table className='table table-hover text-nowrap'>
                                    <tr>
                                        <th>Amount</th>
                                        <th>Date</th>
                                        <th>Remark</th>
                                        <th></th>
                                    </tr>
                                    <tbody>
                                        { costList.map((item) => {
                                            return(
                                                <tr>
                                                    <td>{Fp.formatPrice(item.amount)}</td>
                                                    <td>{item.payment_date}</td>
                                                    <td>{item.remark}</td>
                                                    <td><button className='btn btn-xs btn-outline-danger' onClick={() => handleDelete(item.id)}><i class="far fa-trash-alt"></i></button></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                        {/* <button type="button" class="btn btn-primary" onClick={handleDocSubmit} >Submit</button> */}
                    </div>
                </div>
                {/* <!-- /.modal-content --> */}
            </div>
            {/* <!-- /.modal-dialog --> */}
        </div>
        //    <!-- /.modal -->
    )
}

export const Payment = (props) => {
    const locationHook = useLocation();
    const navigate = useNavigate();
    const t = props.t;

    const [name, setName] = useState('');
    const [value, setValue] = useState('');
    const [costList, setCostList] = useState([]);

    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        fetchData();
        setRefresh(false);
    }, [refresh])

    const fetchData = () => {
        CrudService.GetDataWithParam(navigate, locationHook, 'payment/order', { order_no: props.orderNo })
        .then((res) => {
            setCostList(res.data);
        })
        .catch((err) => {
            console.error(err);
        })
    } 

    const handleDelete = (id) => {
        if (window.confirm('Are you sure want to delete this Cost ?')){
            CrudService.DeleteData(navigate, locationHook, 'payment', id, { order_no: props.orderNo })
            .then((res) => {
                setRefresh(!refresh);
            })
            .catch((err) => {
                console.error(err);
            })
        }
    }

    // const handleDelete = (id) => {

    //     if (window.confirm('Are you sure want to delete this Cost ?')){
    //         CrudService.DeleteData(navigate, locationHook, 'payment', id)
    //         .then((res) => {
    //             setRefresh(!refresh);
    //         })
    //         .catch((err) => {
    //             console.error(err);
    //         })
    //     }
    // }

    return (
        <>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <button className='btn btn-info btn-sm' data-toggle="modal" data-target="#modal-pay">Make Payment</button>
                    </div>
                </div>
            </div>
            <PaymentModal refresh={setRefresh} orderNo={props.orderNo}/>

            <div className='row'>
                <div className="col-md-12">
                    <table className='table table-hover text-nowrap'>
                        <tr>
                            <th>{t('order-pages.amount')}</th>
                            <th>Date</th>
                            <th>Remark</th>
                        </tr>
                        <tbody>
                            { costList.map((item) => {
                                return(
                                    <tr>
                                        <td>{Fp.formatPrice(item.amount)}</td>
                                        <td>{item.payment_date}</td>
                                        <td>{item.remark}</td>
                                        <td><button className='btn btn-xs btn-outline-danger' onClick={() => handleDelete(item.id)}><i class="far fa-trash-alt"></i></button></td>
                                        {/* <td><button className='btn btn-xs btn-outline-danger' onClick={() => handleDelete(item.id)}><i class="far fa-trash-alt"></i></button></td> */}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

