import React, { useState, useEffect } from 'react'
//import axios from 'axios';
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import CrudService from '../../util/crudService'

function DataJobs() {
    const navigate = useNavigate();
    const location = useLocation();
    const [makeList, setMakeList] = useState([]);
    const [status, setStatus] = useState('');
    const [jobDate, setJobDate] = useState('');
    const [status2, setStatus2] = useState('');
    const [jobDate2, setJobDate2] = useState('');
    
    const fetchData = () => {
        CrudService.GetData(navigate, location, 'data-job')
        .then((res) => {
            console.log(res.data);
            setMakeList(res.data.jobs);
            setStatus(res.data.job1.status);
            setJobDate(res.data.job1.date);
            setStatus2(res.data.job2.status);
            setJobDate2(res.data.job2.date)
        }).catch((error) => {
            console.error(error);
        })


    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleUpdate = () => {
        CrudService.GetData(navigate, location, 'currency/update1')
        .catch((error) => {
            console.error(error);
        })

        fetchData();
    }

    const runJob1 = (e) => {
        e.preventDefault();

        CrudService.PostData(navigate, location, 'data-job', {
            jobId: 1
        })
        .then((res) => {
            console.log(res.data);
        }).catch((error) => {
            console.error(error);
        })
    }

    const runJob2 = (e) => {
        e.preventDefault();

        CrudService.PostData(navigate, location, 'data-job', {
            jobId: 2
        })
        .then((res) => {
            console.log(res.data);
        }).catch((error) => {
            console.error(error);
        })
    }

    return (

        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0"><NavLink to="/" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; Manage Data Jobs</h1>
                        </div>
                        {/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <NavLink exact to={`${process.env.PUBLIC_URL}/`} activeClassName="selected" >Configurations</NavLink></li>
                                <li className="breadcrumb-item active">Manage Data Jobs</li>
                            </ol>
                        </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>{/* /.container-fluid */}
            </div>
            <section class="content">

                                {/* --- */}
                                <div class="card card-solid">
                    <div class="card-body pb-0">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                                <div class="card bg-light d-flex flex-fill">
                                    <div class="card-header text-muted border-bottom-0">
                                        Data Job #1
                                    </div>
                                    <div class="card-body pt-0">
                                        <div class="row">
                                            <div class="col-12">
                                                <h2 class="lead"><b>Delete Misc Data</b></h2>
                                                <p class="text-muted text-sm"><b>Status: </b> { (status == 1 ? <span class="badge bg-warning">Pending</span> : (status == 2) ? <span class="badge bg-success">Completed</span> : <span class="badge bg-danger">Error</span>) } </p>
                                                <p class="text-muted text-sm"><b>Last Execution: </b> { (new Date(jobDate)).toLocaleString() } </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div class="text-right">
                                            
                                            <a href="#" class="btn btn-sm btn-warning" onClick={ e => runJob1(e) }>
                                                <i class="fas fa-sync fa-sm"></i>&nbsp; Run
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                                <div class="card bg-light d-flex flex-fill">
                                    <div class="card-header text-muted border-bottom-0">
                                        Data Job #2
                                    </div>
                                    <div class="card-body pt-0">
                                        <div class="row">
                                            <div class="col-12">
                                                <h2 class="lead"><b>Update Sold Vehicle Data</b></h2>
                                                <p class="text-muted text-sm"><b>Status: </b> { (status2 == 1 ? <span class="badge bg-warning">Pending</span> : (status2 == 2) ? <span class="badge bg-success">Completed</span> : <span class="badge bg-danger">Error</span>) } </p>
                                                <p class="text-muted text-sm"><b>Last Execution: </b> { (new Date(jobDate2)).toLocaleString() } </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div class="text-right">
                                            
                                            <a href="#" class="btn btn-sm btn-warning" onClick={ e => runJob2(e) }>
                                                <i class="fas fa-sync fa-sm"></i>&nbsp; Run
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* --- */}

                <div className="card">
                    {/* <div className="card-header">
                        <h3 className="card-title">Exchange Rates Table</h3>
                        <div className="card-tools">

                            <div className='btn btn-tool'>
                                <div className="input-group input-group-sm" style={{ width: 150 }}>
                                    <button type="button" class="btn btn-block btn-outline-success btn-sm" onClick={handleUpdate}><i class="fas fa-sync fa-sm"></i>&nbsp;Update</button>
                                </div>

                            </div>
                        </div>
                    </div> */}
                    {/* /.card-header */}
                    <div className="card-body table-responsive p-0">
                        <table className="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                    <th>Job</th>
                                    <th>Status</th>
                                    <th>Date</th>
                                    {/* <th><button type="button" class="btn btn-block btn-outline-success btn-sm" onClick={handleUpdate}><i class="fas fa-sync fa-sm"></i>&nbsp;Update</button></th> */}
                                </tr>
                            </thead>
                            <tbody>
                                { makeList?.map((value) => {
                                    return (
                                        <tr>
                                            <td>{ value.job_name }</td>
                                            <td>{ (value.status == 1 ? <span class="badge bg-warning">Pending</span> : (value.status == 2) ? <span class="badge bg-success">Completed</span> : <span class="badge bg-danger">Error</span>) }</td>
                                            <td>{ (new Date(value.date)).toLocaleString() }</td>
                                            {/* <td></td> */}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/* /.card-body */}
                </div>
            </section>
        </div>
    )
}

export default DataJobs;