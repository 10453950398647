import { useEffect } from 'react';
import axios from 'axios';
import { useLocalStorage } from "./useLocalStorage";

const useCustomHeaders = (headerKey, headerValue) => {
  const [userLocal, setUserLocal] = useLocalStorage('user');
  
  headerKey = 'x-access-token';
  headerValue = userLocal.accessToken;
  
  useEffect(() => {
    const axiosInterceptor = axios.interceptors.request.use((config) => {
      config.headers[headerKey] = headerValue;
      return config;
    });

    return () => {
      axios.interceptors.request.eject(axiosInterceptor);
    };
  }, [headerKey, headerValue]);
};

export default useCustomHeaders;