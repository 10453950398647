import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import CrudService from '../../util/crudService'

const Invoice2 = () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [id, setId] = useState(0);

    const [vendorData, setVendorData] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [detailList, setDetailList] = useState([]);
    const [costList, setCostList] = useState([]);

    useEffect(() => {
        console.log(`${params.id}`);
        if (params.id) {
            setId(params.id);

            CrudService.GetDataById(navigate, location, 'sale/invoice', params.id)
            .then((res) => {
                console.log(res.data);
                setOrderData(res.data.order);
                setDetailList(res.data.detail);
                setVendorData(res.data.vendor);
                setCostList(res.data.cost);

                //window.print();
            })
        }
    }, [params.id])

    useEffect(() => {
        window.print();
    }, [])

    return (
        <section className="invoice">
            {/* <!-- title row --> */}
            {/* <div className="row">
                <div className="col-12">
                    <h2 className="page-header">
                        <i className="fas fa-globe"></i> KAGA MOTORS COMPANY LIMITED
                        <small className="float-right">Date: {orderData.order_date}</small>
                    </h2>
                </div>
            </div> */}
            <div className="row invoice-info">
                <div className="col-sm-4 invoice-col">
                    <address>
                        Hirawa 50-8, Oyama City<br></br>
                        Tochigi Prefecture, Japan<br></br>
                        Phone: 81-(0)280-33-6090<br></br>
                        Email: export@kagamotors.com
                    </address>
                </div>
                {/* <!-- /.col --> */}
                <div className="col-sm-4 invoice-col" >
                    <img src='../../dist/img/invoice/logo.png' className='float-center' width={100} style={{ margin: '5px 0px 10px 50px' }}/>
                </div>
                {/* <!-- /.col --> */}

                <div className="col-sm-4 invoice-col">
                    Date: {orderData.order_date}
                </div>
                {/* <!-- /.col --> */}
            </div>
            {/* <!-- info row --> */}
            <div className="row invoice-info">
                <div className="col-sm-4 invoice-col">
                    Buyer:
                    <address>
                        <strong>{vendorData.name}</strong><br></br>
                        {vendorData.address1}, {vendorData.address2}<br></br>
                        {vendorData.city}, {vendorData.country} {vendorData.postal_code}<br></br>
                        Phone: {vendorData.contact_no1}<br></br>
                        Email: {vendorData.email}
                    </address>
                </div>
                {/* <!-- /.col --> */}
                <div className="col-sm-4 invoice-col">
                    Terms & Conditions
                </div>

                <div className="col-sm-4 invoice-col">
                    <b>Order ID:</b> {orderData.id}<br></br>
                    <b>Currency:</b> {(orderData.currency == 2) ? 'USD' : 'JPY'}
                </div>
                {/* <!-- /.col --> */}
            </div>
            {/* <!-- /.row --> */}

            {/* <!-- Table row --> */}
            <div className="row">
                <div className="col-12 table-responsive">
                    {/* <p className="lead">Items</p> */}
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Ref. No</th>
                                <th>Description</th>
                                <th>Subtotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            { 
                                detailList?.map((item, index) => {
                                    return(
                                            <tr>
                                                <td>{ (item.ref_no == '0') ? '-' : item.Listing.ref_no }</td>
                                                <td>{item.ref_desc}</td>
                                                <td>{item.sub_total}</td>
                                            </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                {/* <!-- /.col --> */}
            </div>
            {/* <!-- /.row --> */}

            {/* <!-- Table row --> */}
            <div className="row">
                <div className="col-6 table-responsive">
                    <p className="lead">Additional Costs</p>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Cost</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            { 
                                costList?.map((item) => {
                                    return(
                                            <tr>
                                                <td>{item.cost}</td>
                                                <td>{item.amount}</td>
                                            </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                {/* <!-- /.col --> */}
            </div>
            {/* <!-- /.row --> */}
            
            <hr></hr>
            <div className="row">
                {/* <!-- accepted payments column --> */}
                <div className="col-6">
                    {/* <p className="lead">Payment Methods:</p>
                    <img src="../../dist/img/credit/visa.png" alt="Visa" /> &nbsp;
                    <img src="../../dist/img/credit/mastercard.png" alt="Mastercard"/> &nbsp;
                    <img src="../../dist/img/credit/american-express.png" alt="American Express" /> */}

                    <p className="text-muted well well-sm shadow-none" style={{"marginTop":"10px"}}>
                        Please be kind enough to settle your Payment according to the due Dates.
                    </p>
                </div>
                {/* <!-- /.col --> */}
                <div className="col-6">
                    <p className="lead">Summary</p>

                    <div className="table-responsive">
                        <table className="table">
                            <tr>
                                <th style={{'width':'50%'}}>Discount:</th>
                                <td>{(orderData.currency == 2) ? '$' : '¥'} {orderData.discount}</td>
                            </tr>
                            <tr>
                                <th style={{'width':'50%'}}>Subtotal:</th>
                                <td>{(orderData.currency == 2) ? '$' : '¥'} {orderData.sub_total}</td>
                            </tr>
                            <tr>
                                <th>Amount Paid:</th>
                                <td>{(orderData.currency == 2) ? '$' : '¥'} {orderData.amount_paid}</td>
                            </tr>
                            <tr>
                                <th>Amount Remaining:</th>
                                <td>{(orderData.currency == 2) ? '$' : '¥'} {orderData.amount_remaining}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                {/* <!-- /.col --> */}
            </div>
            {/* <!-- /.row --> */}
        </section>

    )
}

export default Invoice2;