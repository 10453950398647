import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import CrudService from '../../util/crudService'

const FilterDiv = (props) => {
    const [name_, setName_] = useState('');
    const [status_, setStatus_] = useState('');

    const handleFilter = () => {
        let data = { name: name_, status: status_ }
        props.searchQuery(data);
    }

    return (
        <section class="content">
            <div className="card col-lg-8">
                <div className="card-body">
                    <div className="row">
                        <div className='col-md-3'>
                            <div className="form-group">
                                <input type="text" className="form-control form-control-sm" id="name" placeholder="Category Name" value={name_} onChange={(e) => setName_(e.target.value)}/>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="form-group">
                                <select class="form-control form-control-sm" id="status" value={status_} onChange={(e) => { setStatus_(e.target.value);}}>
                                    <option value=''>-- Status --</option>
                                    <option value='1'>Enabled</option>
                                    <option value='2'>Disabled</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <button className='btn btn-info btn-sm btn-block' onClick={handleFilter}>Filter</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )      
}

function MakeList() {
    const navigate = useNavigate();
    const location = useLocation();
    const [makeList, setMakeList] = useState([]);
    
    const [currPage, setCurrPage] = useState(1);
    const [page_, setPage_] = useState(1);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    const [showFilter, setShowFilter] = useState(false);
    const handleFilter = () => setShowFilter(!showFilter);
    const [searchQuery, setSearchQuery] = useState([]);
    
    //Next Page
    const nextPage = () => {
        if (hasNext)
            setPage_(currPage + parseInt('1'));
    }

    //Previous Page
    const prevPage = () => {
        if (hasPrev)
            setPage_(currPage - parseInt('1'));
    }

    const gotoPage = (page_) => {
        if (hasNext)
            setPage_(parseInt(page_));
    }

    const fetchData = () => {
        CrudService.GetDataWithParam(navigate, location, 'make/extend', {
            page: page_,
            name: searchQuery.name,
            status: searchQuery.status
        })
        .then((res) => {
            console.log(res.data);
            setMakeList(res.data.data);
            setCurrPage(parseInt(res.data.curr_page));
            setHasNext(res.data.hasNext);
            setHasPrev(res.data.hasPrev);
            setRowCount(res.data.count);
        }).catch((error) => {
            console.error(error);
        })
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [page_, searchQuery]);

    const handleEdit = (id) => {
        console.log('ID : '+ id);
        navigate(`${process.env.PUBLIC_URL}/add-makes/${id}`);
    }

    const handleDelete = (id) => {

        if (window.confirm('Are you sure want to DELETE ?')){
            CrudService.DeleteData(navigate, location, 'make', id)
            .then((res) => {
                window.alert(res.data);
                //toast.success("Stock Delete " + res.data);
                console.log('Deleted :' + res);
                fetchData();
            })
            .catch((error) => {
                console.error(error);
            })
        }
    }

    const pages = [];

    for (let i = 1; i <= (rowCount/10) + 1; i++) {
		pages.push(
			<li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(i)}>{i}</a></li>
		);
	}

    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0"><NavLink to="/menu" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; Car Makes</h1>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <NavLink exact to="/menu" activeClassName="selected" >Configurations</NavLink>
                                </li>
                                <li className="breadcrumb-item active">Make List</li>
                            </ol>
                        </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>{/* /.container-fluid */}
            </div>
            { showFilter && <FilterDiv searchQuery={setSearchQuery} /> }
            <section class="content">
                <div className="card col-lg-8">
                    <div className="card-header">
                        {/* <h3 className="card-title">Responsive Hover Table</h3> */}
                        <div className="card-tools">
                            <div className='btn btn-tool'>
                                <button type="button" class="btn btn-block btn-outline-warning btn-sm" onClick={handleFilter}><i class="fas fa-search fa-sm"></i></button>
                            </div>
                            <div className='btn btn-tool'>
                                <NavLink exact to={`${process.env.PUBLIC_URL}/add-makes`} activeClassName="selected" >
                                    <button type="button" class="btn btn-block btn-outline-success btn-sm"><i class="fas fa-plus fa-sm"></i>&nbsp;Add Make</button>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body table-responsive p-0">
                        <table className="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Slug</th>
                                    <th>Image</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                { makeList?.map((value) => {
                                    return (
                                            <tr>
                                                <td>
                                                    <button className='btn btn-warning btn-xs' onClick={() => {handleEdit(value.id)}}><i class="fas fa-pencil-alt fa-sm"></i></button> &nbsp;
                                                    <button className='btn btn-danger btn-xs' onClick={() => handleDelete(value.id)}> <i class="far fa-trash-alt fa-sm"></i></button>
                                                </td>
                                                <td>{ value.name }</td>
                                                <td>{ value.slug }</td>
                                                <td>{ value.image }</td>
                                                <td>{ value.status == 1 ? <span class="badge bg-success">Enable</span>  : <span class="badge bg-danger">Disable</span> }</td>
                                            </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/* /.card-body */}
                    <div className='card-footer clearfix'>
                        <ul class="pagination pagination-sm">
                            { (hasPrev == 'true') &&
                                <li class="page-item"><a class="page-link" href="javascript:void(0);" tabindex="-1" onClick={ () => prevPage()}>«</a></li>
                            }
                            {pages}
                            {/* <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(1)}>1</a></li>
                            <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(2)}>2</a></li>
                            <li class="page-item"><a href="javascript:void(0);" class="page-link" onClick={ () => gotoPage(3)}>3</a></li> */}
                            { (hasNext == 'true') &&
                                <li class="page-item"><a class="page-link" href="javascript:void(0);" onClick={ () => nextPage()}>»</a></li>
                            }
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default MakeList
