import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import useCustomHeaders from '../../hooks/useCustomHeaders';

function AddFeatures() {
    const [id, setId] = useState(0);
    const [name, setName] = useState('');
    const [sorting, setSorting] = useState(0);
    const [status, setStatus] = useState(1);
    
    const params = useParams();
    const navigate = useNavigate();
    useCustomHeaders();

    useEffect(() => {
        if (params.id) {
            setId(params.id);
            axios.get(`${process.env.REACT_APP_API_END_POINT}/api/admin/feature/${params.id}`)
                .then((res) => {
                    console.log(res.data);
                    setId(res.data.id);
                    setName(res.data.name);
                    setSorting(res.data.sorting);
                    setStatus(res.data.status);
                })
                .catch((error) => {
                    if (error.response && error.response.status == 401) {
                        navigate(`${process.env.PUBLIC_URL}/login`, { replace: true });
                    }
                });
        }
    }, [params.id])

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('id :'+id);
        if (id == null || id == 0){
            axios.post(`${process.env.REACT_APP_API_END_POINT}/api/admin/feature`, {
                name,
                sorting,
                status
            }).then((response) => {
                console.log(response.data);
                alert('Sucessful');
                navigate(`${process.env.PUBLIC_URL}/features`);
            })
            .catch((error) => {
                console.log(error);
                if (error.response && error.response.status == 401) {
                    navigate(`${process.env.PUBLIC_URL}/login`, { replace: true });
                }
            });
        } else {
            axios.put(`${process.env.REACT_APP_API_END_POINT}/api/admin/feature/${id}`, {
                id,
                name,
                sorting,
                status
            })
            .then((response) => {
                console.log(response.data);
                navigate(`${process.env.PUBLIC_URL}/features`);
            })
            .catch((error) => {
                console.log(error);
                if (error.response && error.response.status == 401) {
                    navigate(`${process.env.PUBLIC_URL}/login`, { replace: true });
                }
            });
        }

    };

    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1><NavLink to="/features" replace={true} role='button'><i class="fas fa-arrow-circle-left fa-sm"></i></NavLink> &nbsp; Add Features</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><NavLink exact to="/features" activeClassName="selected" >Features</NavLink></li>
                                <li className="breadcrumb-item active">Add Features</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        {/* left column */}
                        <div className="col-md-8">
                            {/* general form elements */}
                            <div className="card card-primary">
                                {/* <div className="card-header">
                                    <h3 className="card-title">Add Category</h3>
                                </div> */}
                                {/* /.card-header */}
                                {/* form start */}
                                <form>
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <div className="form-group">
                                                    <label htmlFor="name">Name</label>
                                                    <input type="text" className="form-control" id="name" placeholder="Enter name" value={name} onChange={(e) => setName(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className="form-group">
                                                    <label htmlFor="sorting">Sorting</label>
                                                    <input type="number" className="form-control" id="sorting" placeholder="0" value={sorting} onChange={(e) => setSorting(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <div class="form-group">
                                                    <label htmlFor="status">Status</label>
                                                    <select class="form-control" id="status" value={status} onChange={(e) => { console.log(e.target.value); setStatus(e.target.value);}}>
                                                        <option value="1">Enable</option>
                                                        <option value="2">Disable</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                                    </div>
                                </form>
                            </div>
                            {/* /.card */}
                            <div className="card card-secondary" hidden>
                                <div className="card-header">
                                    <h3 className="card-title">Misc Info</h3>
                                </div>
                                {/* /.card-header */}
                                {/* form start */}
                                <form>
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Created By</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Created At</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Updated By</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Updated At</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" />
                                                </div>
                                            </div>
                                        </div>  
                                    </div>
                                    {/* /.card-body */}
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* /.row */}
                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>

    )
}

export default AddFeatures